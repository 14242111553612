import React from 'react'

import Checkbox from './Checkbox'

import {
  ExternalLinkSvg,
} from '../../constants/svgs'

const ScientificSourceSection = ({
  externalSourceUrl,
  titleText,
  marginTop,
  marginBottom,
  color
}) => {
  return (
    <button onClick={() => window.open(externalSourceUrl, "_blank", "noreferrer noopener")} style={{marginTop, marginBottom, border: "none", width: "100%", backgroundColor: "#EDEEF2", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)"}}>
      <div style={{display: "flex", alignItems: "center"}}>
        <div style={{display: "inline-block", height: "min(4.5vw, 18px)", width: "min(4.5vw, 18px)"}}>
          <ExternalLinkSvg size={"min(4.5vw, 18px)"} color={"#000000"} />
        </div>
        <span style={{color: color || "#000000", textDecoration: "underline", fontSize: "min(5.5vw, 22px)", marginLeft: 15, textAlign: "left", fontFamily: "Futura Book"}}>
          {titleText}
        </span>
      </div>
    </button>
  );
}

export default ScientificSourceSection;