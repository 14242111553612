import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  resetLoginPage,
} from '../actions/loginPage';

import {
  toggleBodyScroll,
  i18n,
  hideLoadingScreen,
  initializeGA,
  pageViewGA,
} from '../utils'

import LinksPage from '../components/LinksPage';

import {
  REQ_FETCH_APP_CONFIG,
} from '../constants/requestTypes'

import {
  COMMON_INNER_HEIGHT,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_NATIVE,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
} from '../constants/commonState'

import {
  FORM_FIELD_HIDE_INTRO_VIDEO,
} from '../constants/formFields'

class LinksPageContainer extends Component {
  componentWillMount() {
    initializeGA(true/*disableCookies*/);
    pageViewGA();
  }

  componentDidMount() {
    hideLoadingScreen()
  }
  componentWillUnmount() {
    this.props.dispatch(resetLoginPage());
  }

  render() {
    return (
      <LinksPage
        // State
        innerHeight={this.props.innerHeight}
        safeAreaInsets={this.props.safeAreaInsets}
        isNative={this.props.isNative}
        isIOSDevice={this.props.isIOSDevice}
        isAndroidDevice={this.props.isAndroidDevice}
        iOSAppIsAvailable={this.props.iOSAppIsAvailable}
        androidAppIsAvailable={this.props.androidAppIsAvailable}
        countryCode={this.props.countryCode}
        i18n={i18n}
        // Actions
      />
    );
  }
}

LinksPageContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  innerHeight: PropTypes.number.isRequired,
  isNative: PropTypes.bool.isRequired,
  isIOSDevice: PropTypes.bool.isRequired,
  isAndroidDevice: PropTypes.bool.isRequired,
  safeAreaInsets: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  ...state.loginPage,
  innerHeight: state.common[COMMON_INNER_HEIGHT],
  isNative: state.common[COMMON_IS_NATIVE],
  isIOSDevice: state.common[COMMON_IS_IOS_DEVICE],
  isAndroidDevice: state.common[COMMON_IS_ANDROID_DEVICE],
  safeAreaInsets: state.common[COMMON_SAFE_AREA_INSETS],
  countryCode: state.common[REQ_FETCH_APP_CONFIG].result ? (state.common[REQ_FETCH_APP_CONFIG].result.country_code !== "-" ? state.common[REQ_FETCH_APP_CONFIG].result.country_code : "US") : "US",
});

export default connect(mapStateToProps)(LinksPageContainer);
