import {
  COMMON_APP_NAME,
  COMMON_APP_PLATFORM,
  COMMON_LOGIN_TOKEN,
  COMMON_RESET_PASSWORD_TOKEN,
  COMMON_INNER_HEIGHT,
  COMMON_INNER_WIDTH,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_IOS_SAFARI,
  COMMON_IS_IOS_PWA,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
  COMMON_ANDROID_DEFERRED_PROMPT,
  COMMON_IS_NATIVE,
  COMMON_NATIVE_APP_OPEN_COUNT,
  COMMON_PUSH_NOTIFICATION_TOKEN,
  COMMON_IN_APP_PURCHASE_PRODUCTS_LOADED,
  COMMON_IN_APP_PURCHASE_PRODUCTS,
  COMMON_CAMERA_ACCESS_PERMISSION_STATUS,
  COMMON_DEEPLINKED_ARTICLE_KEY,
  COMMON_DEEPLINKED_AFFILIATE_ID,
  COMMON_APP_STATE_IS_ACTIVE,
  COMMON_CURRENT_APP_VERSION,
  COMMON_NATIVE_COMPLETION_SOUND_PRELOADED,
  COMMON_APP_ACTION_TYPE,
  COMMON_IS_DEEPLINKED_PAYWALL_ACTION,
  COMMON_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION,
  COMMON_APP_LOADED_AT,
  COMMON_DEPLOYMENT_CONFIG,
  COMMON_QUIZ_RESULT_KEY,
} from '../constants/commonState'

import {
  REQ_FETCH_APP_CONFIG,
  REQ_FETCH_DEPLOYMENT_CONFIG,
  REQ_SAVE_NOTIFICATION_REGISTRATION_RESULT,
  REQ_VALIDATE_RESET_PASSWORD_TOKEN,
  REQ_FETCH_QUIZ_RESULTS,
  REQ_DASHBOARD_FETCH_APP_CONFIG,
  REQ_DASHBOARD_VALIDATE_RESET_PASSWORD_TOKEN,
} from '../constants/requestTypes'

import {
  REQUEST_UNSTARTED,
  REQUEST_SUCCESS,
} from '../constants/requestStates'

import {
  COMMON_UPDATE_RESET_PASSWORD_TOKEN,
  COMMON_UPDATE_REQUEST_STATE,
  COMMON_UPDATE_INNER_HEIGHT,
  COMMON_UPDATE_INNER_WIDTH,
  COMMON_UPDATE_SAFE_AREA_INSETS,
  COMMON_UPDATE_ANDROID_DEFERRED_PROMPT,
  COMMON_UPDATE_IS_IOS_PWA,
  COMMON_UPDATE_NATIVE_APP_OPEN_COUNT,
  COMMON_UPDATE_PUSH_NOTIFICATION_TOKEN,
  COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS,
  COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_LOADED,
  COMMON_UPDATE_CAMERA_PERMISSIONS,
  COMMON_UPDATE_DEEPLINKED_ARTICLE_KEY,
  COMMON_UPDATE_DEEPLINKED_AFFILIATE_ID,
  COMMON_UPDATE_APP_STATE_IS_ACTIVE,
  COMMON_UPDATE_CURRENT_APP_VERSION,
  COMMON_UPDATE_NATIVE_COMPLETION_SOUND_PRELOADED,
  COMMON_UPDATE_APP_ACTION_TYPE,
  COMMON_UPDATE_IS_DEEPLINKED_PAYWALL_ACTION,
  COMMON_UPDATE_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION,
  COMMON_UPDATE_QUIZ_RESULT_KEY,
  COMMON_UPDATE_DEPLOYMENT_CONFIG,
  COMMON_RESET_STATE,
} from '../constants/actionTypes'

import {
  SetGlobalI18N,
  isIOSSafari,
  isIOSStandalone,
  isIOSDevice,
  isAndroidDevice,
  resizeInnerHeight,
  resizeInnerWidth,
  currentTime,
  FreeTrialInAppPurchaseProducts,
} from '../utils'

import {
  Capacitor,
} from '@capacitor/core'

const safeAreaInsets = require('safe-area-insets')

const defaultCommonState = {
  [COMMON_APP_NAME]: 'NeuroFit',
  [COMMON_RESET_PASSWORD_TOKEN]: "",
  [COMMON_APP_STATE_IS_ACTIVE]: true,
  [COMMON_CURRENT_APP_VERSION]: "",
  [COMMON_INNER_HEIGHT]: resizeInnerHeight(),
  [COMMON_INNER_WIDTH]: resizeInnerWidth(),
  [COMMON_SAFE_AREA_INSETS]: safeAreaInsets,
  [COMMON_ANDROID_DEFERRED_PROMPT]: undefined,
  [COMMON_IS_IOS_SAFARI]: isIOSSafari(),
  [COMMON_IS_IOS_DEVICE]: isIOSDevice(),
  [COMMON_IS_ANDROID_DEVICE]: isAndroidDevice(),
  [COMMON_IS_IOS_PWA]: isIOSStandalone(),
  [COMMON_IS_NATIVE]: Capacitor.isNativePlatform(),
  [COMMON_APP_PLATFORM]: Capacitor.getPlatform(),
  [COMMON_NATIVE_APP_OPEN_COUNT]: 0,
  [COMMON_PUSH_NOTIFICATION_TOKEN]: "",
  [COMMON_IN_APP_PURCHASE_PRODUCTS_LOADED]: false,
  [COMMON_IN_APP_PURCHASE_PRODUCTS]: FreeTrialInAppPurchaseProducts,
  [COMMON_CAMERA_ACCESS_PERMISSION_STATUS]: "",
  [COMMON_APP_LOADED_AT]: currentTime(),
  [COMMON_DEEPLINKED_ARTICLE_KEY]: "",
  [COMMON_DEEPLINKED_AFFILIATE_ID]: "",
  [COMMON_APP_ACTION_TYPE]: "",
  [COMMON_IS_DEEPLINKED_PAYWALL_ACTION]: false,
  [COMMON_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION]: false,
  [COMMON_QUIZ_RESULT_KEY]: "",
  [COMMON_DEPLOYMENT_CONFIG]: undefined,
  [REQ_FETCH_APP_CONFIG]: {state: REQUEST_UNSTARTED},
  [REQ_SAVE_NOTIFICATION_REGISTRATION_RESULT]: {state: REQUEST_UNSTARTED},
  [REQ_FETCH_DEPLOYMENT_CONFIG]: {state: REQUEST_UNSTARTED},
  [REQ_VALIDATE_RESET_PASSWORD_TOKEN]: {state: REQUEST_UNSTARTED},
  [REQ_FETCH_QUIZ_RESULTS]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_FETCH_APP_CONFIG]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_VALIDATE_RESET_PASSWORD_TOKEN]: {state: REQUEST_UNSTARTED},
};

export default (state = defaultCommonState, action) => {
  switch (action.type) {
    case COMMON_UPDATE_RESET_PASSWORD_TOKEN:
      return {...state, [COMMON_RESET_PASSWORD_TOKEN]: action.payload}
    case COMMON_UPDATE_INNER_HEIGHT:
      return {...state, [COMMON_INNER_HEIGHT]: action.payload}
    case COMMON_UPDATE_INNER_WIDTH:
      return {...state, [COMMON_INNER_WIDTH]: action.payload}
    case COMMON_UPDATE_SAFE_AREA_INSETS:
      return {...state, [COMMON_SAFE_AREA_INSETS]: action.payload}
    case COMMON_UPDATE_ANDROID_DEFERRED_PROMPT:
      return {...state, [COMMON_ANDROID_DEFERRED_PROMPT]: action.payload}
    case COMMON_UPDATE_IS_IOS_PWA:
      return {...state, [COMMON_IS_IOS_PWA]: action.payload}
    case COMMON_UPDATE_NATIVE_APP_OPEN_COUNT:
      return {...state, [COMMON_NATIVE_APP_OPEN_COUNT]: action.payload}
    case COMMON_UPDATE_PUSH_NOTIFICATION_TOKEN:
      return {...state, [COMMON_PUSH_NOTIFICATION_TOKEN]: action.payload}
    case COMMON_UPDATE_REQUEST_STATE: {
      if ((((action.key === REQ_FETCH_APP_CONFIG)) || action.key === REQ_DASHBOARD_FETCH_APP_CONFIG) && (action.payload.state === REQUEST_SUCCESS)) {
        SetGlobalI18N(action.payload.result.localization, action.payload.result.language)
      }
      return {...state, [action.key]: action.payload}
    }
    case COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS:
      return {...state, [COMMON_IN_APP_PURCHASE_PRODUCTS]: action.payload}
    case COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_LOADED:
      return {...state, [COMMON_IN_APP_PURCHASE_PRODUCTS_LOADED]: action.payload}
    case COMMON_UPDATE_CAMERA_PERMISSIONS:
      return {...state, [COMMON_CAMERA_ACCESS_PERMISSION_STATUS]: action.payload}
    case COMMON_UPDATE_DEEPLINKED_ARTICLE_KEY:
      return {...state, [COMMON_DEEPLINKED_ARTICLE_KEY]: action.payload}
    case COMMON_UPDATE_DEEPLINKED_AFFILIATE_ID:
      return {...state, [COMMON_DEEPLINKED_AFFILIATE_ID]: action.payload}
    case COMMON_UPDATE_APP_STATE_IS_ACTIVE:
      return {...state, [COMMON_APP_STATE_IS_ACTIVE]: action.payload}
    case COMMON_UPDATE_CURRENT_APP_VERSION:
      return {...state, [COMMON_CURRENT_APP_VERSION]: action.payload}
    case COMMON_UPDATE_NATIVE_COMPLETION_SOUND_PRELOADED:
      return {...state, [COMMON_NATIVE_COMPLETION_SOUND_PRELOADED]: action.payload}
    case COMMON_UPDATE_APP_ACTION_TYPE:
      return {...state, [COMMON_APP_ACTION_TYPE]: action.payload}
    case COMMON_UPDATE_IS_DEEPLINKED_PAYWALL_ACTION:
      return {...state, [COMMON_IS_DEEPLINKED_PAYWALL_ACTION]: action.payload}
    case COMMON_UPDATE_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION:
      return {...state, [COMMON_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION]: action.payload}
    case COMMON_UPDATE_QUIZ_RESULT_KEY:
      return {...state, [COMMON_QUIZ_RESULT_KEY]: action.payload}
    case COMMON_UPDATE_DEPLOYMENT_CONFIG:
      return {...state, [COMMON_DEPLOYMENT_CONFIG]: action.payload}
    case COMMON_RESET_STATE:
      return defaultCommonState
    default:
      return state;
  }
};
