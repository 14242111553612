import React from 'react'


import { CSSTransition, SwitchTransition} from 'react-transition-group';

import SettingsButton from '../common/SettingsButton'

import {
  BackArrowSvg
} from '../../constants/svgs'

const exitButtonStyle = {pointerEvents: "all", margin: "15px 25px", height: 50, width: 50, backgroundColor: "#353F6A", borderRadius: "50%", boxShadow: "0px 0px 10px #353F6A", border: "none"}


const containerStyle = {textAlign: "left", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const headerTextStyle = {fontSize: "min(7vw, 32px)", margin: "min(9vh, 45px) 0px min(6vh, 30px) 0px", color: "#000000", textAlign: "left", fontFamily: "Futura Book"}


class LandingPageLinksModal extends React.Component {

  constructor(props){
     super(props)
     this.state = {

     }
   }

  render() {

    const {
      // State
      show,
      innerHeight,
      onHide,
      onClickBookButton,
      onClickDownloadAppButton,
      onClickTrainerCertificationButton,
      onClickQuizButton,
      safeAreaInsets
    } = this.props

    return (
      <CSSTransition
        in={show}
        timeout={500}
        classNames="slide-from-right"
        unmountOnExit
      >
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000000,
            backdropFilter: show ? "blur(10px)" : "blur(10px)",
            WebkitBackdropFilter: show ? "blur(10px)" : "blur(10px)",
          }}
          onClick={() => {
            onHide()
          }}
        >
          <div 
            id={"neurofit-intro-modal"}
            style={{
              position: 'absolute',
              backgroundColor: "rgba(237, 238, 242, 0.85)",
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
            onClick={e => e.stopPropagation()}
          >
            <div style={{color: "#000000", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", height: innerHeight}}>
              <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
              <div style={{display: "flex", alignItems: "top", height: 40, padding: 10, justifyContent: "space-between", width: "calc(100% - 20px)", position: "fixed", top: safeAreaInsets.top, left: 0, zIndex: 1000, boxShadow: "0px 2px 0px 0px #000000", backgroundColor: "#ffffff", zIndex: 999}}>
                <span
                  className="close-black"
                  style={{opacity: 0.0, transition: "opacity 200ms linear"}}
                  disabled
                >
                  <BackArrowSvg />
                </span>
                <div style={{color: "#000000", textAlign: "center", fontSize: "min(6vw, 30px)", height: 40, lineHeight: "40px", fontFamily: "Futura Book"}}>
                  {"LINKS"}
                </div>
                <span
                  className="close-black"
                  onClick={() => {
                    onHide()
                  }}
                >
                  &times;
                </span>
              </div>
              <div 
                className={"disable-scrollbars"}
                style={{...containerStyle, height: innerHeight}}
              >
                <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "top", height: innerHeight}}>
                  <div style={{maxWidth: 500, width: "80%", color: "#ffffff", marginTop: 60 + safeAreaInsets.top, marginBottom: 20 + safeAreaInsets.bottom}}>
                    <SettingsButton
                      title={"Get The Book"}
                      isDisabled={false}
                      onClick={() => {
                        onClickBookButton()
                      }}
                    />
                    <SettingsButton
                      title={"Download The App"}
                      isDisabled={false}
                      onClick={() => {
                        onClickDownloadAppButton()
                      }}
                    />
                    <SettingsButton
                      title={"Trainer Certification"}
                      isDisabled={false}
                      onClick={() => {
                        onClickTrainerCertificationButton()
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }
}

export default LandingPageLinksModal