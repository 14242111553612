export const LOGIN_PAGE_MODAL_CREATE_ACCOUNT_ERROR = 'createAnonymousAccountError'
export const LOGIN_PAGE_MODAL_SIGN_IN = 'signIn'

export const MODAL_REDEEM_ACCESS_CODE = 'redeemAccessCode'
export const MODAL_PURCHASED_SUBSCRIPTION = 'successfullyPurchasedSubscription'
export const MODAL_PROFILE_SETTINGS = 'profileSettings'
export const MODAL_NEUROFIT_INTRO = 'neurofitIntro'
export const MODAL_NEUROFIT_DISCOVERY_QUIZ = 'discoveryQuiz'
export const MODAL_NEUROFIT_SELF_CHECK_IN = 'neurofitSelfCheckIn'
export const MODAL_NEUROFIT_HRV_MEASUREMENT = 'neurofitHrvMeasurement'
export const MODAL_NEUROFIT_TRAINING = 'neurofitTraining'
export const MODAL_NEUROFIT_TRAINING_SCIENCE_EXPLANATION = 'exerciseScienceExplanation'
export const MODAL_HRV_SCIENCE_EXPLANATION = 'hrvScienceExplanation'
export const MODAL_HEART_RATE_SCIENCE_EXPLANATION = 'heartRateScienceExplanation'
export const MODAL_RESPIRATION_RATE_SCIENCE_EXPLANATION = 'respiratoryRateScienceExplanation'
export const MODAL_NEUROFIT_RECOVERY_SCIENCE_EXPLANATION = 'neuroFitRecoveryScienceExplanation'
export const MODAL_LEARN_ARTICLE = 'learnArticle'
export const MODAL_LEARN_ARTICLE_GLOSSARY = 'learnArticleGlossary'
export const MODAL_APP_FEEDBACK = 'appFeedback'
export const MODAL_FORCE_APP_UPDATE = 'forceAppUpdate'
export const MODAL_MEMBERSHIP_PAYWALL = 'membershipPaywall'
export const MODAL_DELETE_ACCOUNT = 'deleteAccount'
export const MODAL_COMPLETED_SELF_CHECK_IN = 'completedSelfCheckIn'
export const MODAL_APP_MAINTENANCE_WALL = 'appMaintenanceWall'
export const MODAL_CONFIGURE_NOTIFICATIONS = 'configureNotifications'
export const MODAL_RESET_PROGRAM = 'resetProgram'
export const MODAL_FORCE_REFRESH_APP = 'forceRefreshApp'
export const MODAL_DYSREGULATION_INFO = 'dysregulationInfo'
export const MODAL_NEUROFIT_SIGNAL_QUALITY_EXPLANATION = 'signalQualityExplanation'
export const MODAL_NEUROFIT_READING_RESULTS_EXPLANATION = 'readingResultsExplanation'
export const MODAL_HRV_ONBOARDING = 'hrvOnboarding'
export const MODAL_ANDROID_OS_CAMERA_NOT_SUPPORTED = 'androidOSCameraNotSupported'
export const MODAL_TRAINER_CERTIFICATION = 'trainerCertification'
export const MODAL_PURCHASE_NERVOUS_SYSTEM_COURSE = 'purchaseNervousSystemCourse'
export const MODAL_PURCHASE_CERTIFICATION = 'purchaseCertification'
export const MODAL_BOOK = 'book'
export const MODAL_CONTACT_SUPPORT = 'contactSupport'
export const MODAL_FAQ = 'faq'
export const MODAL_SCIENTIFIC_SOURCES = 'scientificSources'
export const MODAL_TERMS_PRIVACY = 'termsPrivacy'
export const MODAL_PAID_CREATOR_APPLICATION = 'paidCreatorApplication'
export const MODAL_UPDATE_ACCOUNT_DETAILS = 'updateAccountDetails'
export const MODAL_MANAGE_BILLING = 'manageBilling'
export const MODAL_PERSONALIZATION_ONBOARDING = 'personalizationOnboarding'
export const MODAL_ADMIN_GRANT_APP_ACCESS = 'adminGrantAppAccess'
export const MODAL_ADMIN_CREATE_TRAINER_DASHBOARD_INVITE = 'adminCreateTrainerDashboardInvite'