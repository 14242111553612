import React from 'react'

import {
  ProductionBaseUrl,
  DefaultAspectRatio,
  recordFunnelEventResultGA,
  initializePixelIfAvailable,
  recordPixelEventIfAvailable,
  NeuroFitUniversalAppStoreLink,
  getAffiliateIdFromQueryString,
  MinDesktopWidthPixels,
  isIOSDevice,
  NeuroFitCourseCheckoutPage,
  NeuroFitCertificationCheckoutPage,
  recordLinkClickAndRedirect,
  CertificationThreePaymentCheckoutLink,
  getNextEnrollmentCloseUnixTimestamp,
} from '../../utils'

import { InView } from 'react-intersection-observer';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './LoadingLogo'
import AutoPlayInlineVideoContainer from './AutoPlayInlineVideoContainer'
import AppReviewPanel from './AppReviewPanel'
import VideoTestimonialPanel from './VideoTestimonialPanel'
import LineGraph from './LineGraph'
import StripeCheckoutForm from './StripeCheckoutForm'
import UniversitySourcesSection from './UniversitySourcesSection'
import FaqSection from './FaqSection'
import CertificationPageProductTile from './CertificationPageProductTile'
import MembershipFeatureDescription from './MembershipFeatureDescription'

import Countdown from 'react-countdown';


import {
  GA_LABEL_CLICKED_CERTIFICATION_CHECKOUT_BUTTON,
  GA_LABEL_CLICKED_REDIRECT_TO_COURSE_CHECKOUT_PAGE,
  GA_LABEL_CLICKED_REDIRECT_TO_CERTIFICATION_CHECKOUT_PAGE,
} from '../../constants/gaEventLabels'

import {
  ExternalLinkSvg,
} from '../../constants/svgs'


const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const TrainerTestimonials = [
  {
    type: "video",
    dateString: "Feb 2",
    username: "Kristine",
    occupation: "Holistic Wellness Coach",
    countryFlag: "🇦🇺",
    videoSourcePath: "/assets/videos/testimonials/kristine_crouch.mp4",
    videoPosterImagePath: "/images/kristine_crouch_poster.webp",
    videoWidthPixels: 1124,
    videoHeightPixels: 1998
  },
  {
    type: "video",
    dateString: "Feb 2",
    username: "Arci",
    countryFlag: "🇺🇸",
    occupation: "Survivor Success Coach",
    videoSourcePath: "/assets/videos/testimonials/arci_grey.mp4",
    videoPosterImagePath: "/images/arci_grey_poster.webp",
    videoWidthPixels: 1124,
    videoHeightPixels: 1998
  },
  {
    title: "Concise, direct, & incredibly powerful",
    type: "text",
    dateString: "Feb 3",
    username: "Jenny",
    occupation: "Pilates Studio Owner",
    countryFlag: "🇺🇸",
    platform: "apple",
    description: "I've been so impressed by how concise and direct this training has been while being incredibly powerful. Making the simple shift of incorporating daily play has created a significant shift in my body and life! I'm no longer trying to constantly calm myself down, instead I have real tools to respond to life and balance my nervous system. I have more energy and am less reactive in just a few weeks."
  },
  {
    title: "Well organised, succinct and engaging",
    type: "text",
    dateString: "Jan 17",
    username: "Dr. Catherine G.",
    occupation: "Embodiment Specialist",
    countryFlag: "🇦🇺",
    platform: "apple",
    description: "I found the course delivery to be very well organised, succinct and engaging. The techniques are very practical and applicable. All the key terms are clearly defined and explained to the depth required without going into extraneous detail. Whether it's for your own self-development or to adopt tools and techniques you can use with clients, NeuroFit is awesome!"
  },
  {
    title: "NeuroFit helps the body thrive",
    type: "text",
    dateString: "Jan 25",
    username: "Brianna",
    occupation: "Trauma-Informed Coach",
    countryFlag: "🇺🇸",
    platform: "apple",
    description: "In going through this training I feel clear and excited about the opportunity to better serve my clients and bring nervous system regulation to people who really need it. As a practitioner I knew there was a reason why people were still struggling even with all of the knowledge they had and action they were taking. Neurofit helps put the body into a state where you can transform and thrive."
  },
  {
    title: "A wonderful ah-hah",
    type: "text",
    dateString: "Feb 7",
    username: "Candice",
    occupation: "Military Nurse",
    countryFlag: "🇺🇸",
    platform: "apple",
    description: "It was a wonderful ah-hah to have this awareness; to really see the effects of people, places, and certain situations. Look forward to continuing incorporating this into my daily routine!"
  },
  {
    title: "Getting amazing results with my clients",
    type: "text",
    dateString: "Dec 28",
    username: "Stacey",
    occupation: "Neuromuscular Therapist",
    countryFlag: "🇦🇺",
    platform: "apple",
    description: "I’m able to do way more in my business instead of being injured, tired and burnt out all the time, I can actually get myself motivated to get my tasks done and take on more new clients which is bringing in way more income! I had such phenomenal results with NeuroFit as one of the first trainers in Australia and I am getting amazing results with my clients."
  },
  {
    title: "Could not be more grateful!",
    type: "text",
    dateString: "Feb 7",
    username: "Amanda",
    occupation: "Life Coach",
    countryFlag: "🇺🇸",
    platform: "apple",
    description: "For the first time, I trust that I can slow down, keep things SIMPLE, and equally be fulfilled and live a life of service. Just 30 days ago, I was feeling lost, depressed, and about ready to give up entirely and now I feel more empowered, impassioned, and FIRED UP than I have in at least a decade. Thank you Loren and Andrew for the incredible training and service you have created for the world."
  },
  {
    title: "I feel like a brand new person",
    type: "text",
    dateString: "Feb 18",
    username: "Ronny",
    occupation: "Breathwork Coach",
    countryFlag: "🇮🇱",
    platform: "apple",
    description: "1 week into the program I felt like a heavy load was lifted off my shoulders. 4 weeks into the program I feel like a brand new person. I can breathe easily, I can think clearly and I have gained a sense of calm."
  },
  {
    title: "Several paradigm shifts",
    type: "text",
    dateString: "Feb 18",
    username: "Aileen",
    occupation: "Holistic Pharmacist",
    countryFlag: "🇺🇸",
    platform: "apple",
    description: "As a holistic pharmacist practicing energy healing, adding the NeuroFit App daily to my life AND my clients will assist in a greater awareness to the mind, body & soul connection, thus allowing for deeper somatic healing to unfold overtime. I am excited to share that I have experienced several paradigm shifts mentally as well as physically, emotionally and spiritually. I also noticed I am more relaxed, centered and at ease."
  },
  {
    title: "Like a good book you can't put down",
    type: "text",
    dateString: "Feb 7",
    username: "Nicole",
    occupation: "Manifestation Coach",
    countryFlag: "🇺🇸",
    platform: "apple",
    description: "These exercises and this program can and will change many lives. The possibilities truly are endless in the ways in which these simple and effective exercises can be incorporated. EVERYONE has 5 minutes a day."
  },
  {
    title: "Absolutely loved the program!",
    type: "text",
    dateString: "Dec 14",
    username: "Regine",
    occupation: "Life Coach",
    countryFlag: "🇩🇪",
    platform: "apple",
    description: "The program truly transformed my daily life profoundly as I was able to rewire the automatic response of my nervous system. The short and effective exercises of the NeuroFit app paired with the learning material were exactly what I needed to support myself on the next level of my business."
  },
  {
    title: "I’m so glad I stumbled upon NeuroFit!",
    type: "text",
    dateString: "Feb 4",
    username: "Lauren",
    occupation: "Certified Hypnotherapist",
    countryFlag: "🇺🇸",
    platform: "apple",
    description: "I’m a hypnotherapist and recently [became] interested in bottom-up, somatic approaches to emotional release and nervous system balance. I’m so glad I stumbled upon NeuroFit! Not only do these exercises help me to maintain control of my own nervous system, but training how to teach these tools to my clients will allow me to offer an even more powerful and holistic approach to wellness."
  },
  {
    title: "A very useful nervous system training",
    type: "text",
    dateString: "Dec 24",
    username: "Paula",
    occupation: "Coach, Author & Speaker",
    countryFlag: "🇨🇴",
    platform: "apple",
    description: "This is a very useful training for people who are starting to learn about the nervous system and how it works when we are stressed. Eg, identifying key aspects that make it easier to see what state our clients are in (eg, Dorsal Vagal, etc). I also like how the exercises given correspond to each Energy Type to move them to their desired state."
  }
]

const CourseOutlineBulletPoint = ({text}) => (
  <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0px 2vh"}}>
    <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(6vw, 26px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
      {"•"}
    </div>
    <div style={{fontFamily: "Futura Light", fontSize: "min(5vw, 20px)", lineHeight: "min(6vw, 26px)", textAlign: "left"}}>
      {text}
    </div>
  </div>
)

const CourseOutlineBulletPoints = ({textList}) => (
  <div>
    {textList.map(t => (
      <CourseOutlineBulletPoint key={t} text={t} />
    ))}
  </div>
);

const CourseOutlineItems = [
  {
    key: "module_1",
    question: "Module 1: Setting Yourself Up for Success",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "The 3-Part Transformation Framework that creates accelerated results",
          "The #1 avoidable mistake that most nervous system experts make",
          "The 4 Truths that will uplevel your standards and dramatically improve your results"
        ]}
      />
    )
  },
  {
    key: "module_2",
    question: "Module 2: Introduction to the NeuroFit Method",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "The fundamentals of the NeuroFit Method (and why it's so effective)",
          "How to practically apply Polyvagal Theory with clients",
          "4 Key Factors that secretly influence the nervous system",
          "Using Bottom-Up Processing to your advantage",
          "The 6 nervous system states, and how to quickly identify them",
          "How to measure nervous system balance in less than 2 minutes"
        ]}
      />
    )
  },
  {
    key: "module_3",
    question: "Module 3: Taking a Client from Dysregulated to Balanced in Minutes (A Step-by-Step Process)",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "Understanding the 3 main energy types to get faster client breakthroughs",
          "The 3 best exercises when working with anxious clients",
          "The 3 best exercises when working with overwhelmed clients",
          "The 3 best exercises when working with clients in shutdown"
        ]}
      />
    )
  },
  {
    key: "module_4",
    question: "Module 4: Advanced Techniques for Personal & Client Breakthroughs",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "What to do (on a nervous system level) when a client experiences resistance to change",
          "Facilitating client safety during sessions via the social nervous system",
          "Advanced somatic and balancing techniques for facilitating powerful client breakthroughs",
          "The 3 types of accountability habits that are proven to accelerate client results"
        ]}
      />
    )
  },
  {
    key: "module_5",
    question: "Module 5: Facilitating Powerful Transformations with Neurological Fitness",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "A step-by-step process for hosting transformational workshops that your clients will rave about",
          "A walkthrough guide for facilitating life-changing 1:1 client sessions"
        ]}
      />
    )
  },
  {
    key: "module_6",
    question: "Module 6: A Fitness Trainer Approach to the NeuroFit Method",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "How to incorporate the NeuroFit Method during client sessions, to optimize productivity and outcomes",
          "Done-for-you client intake form, that helps your clients understand the ways their nervous system is dysregulated",
          "Done-for-you dynamic warm-up sequence you can implement with clients immediately"
        ]}
      />
    )
  },
  {
    key: "module_7",
    question: "Module 7: Embodied Marketing & Distribution",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "How to grow your client base in 2023 by leveraging widespread burnout and chronic stress",
          "Developing your personalized Blue Ocean Strategy to integrate nervous system with your practice",
          "How to use the NeuroFit Method to bring on more clients",
          "Simple social media templates and strategies that you can implement immediately"
        ]}
      />
    )
  }
]

const FAQItems = [  
  {
    key: "cert_header",
    is_header: true,
    text: "Getting Certified"
  },
  {
    key: "live_or_self_paced",
    question: "Is the Certification live or self-paced?",
    answer: "There are two components to the Certification: a self-paced course to ensure that you learn the fundamentals, followed by live monthly support calls hosted by our Head Coaches."
  },
  {
    key: "time_commitment",
    question: "What is the weekly time commitment?",
    answer: "We recommend allocating 2 hours a week to completing the Certification course (it takes around 6 hours to complete all course materials). Some trainers get certified in just a few days, and others take their time - you decide what works best for you."
  },
  {
    key: "immediate_access",
    question: "Do I get immediate access to the program after purchasing?",
    answer: "Yes. You'll receive immediate access to the program, and everything needed to become certified."
  },
  {
    key: "methodology",
    is_header: true,
    text: "Methodology"
  },
  {
    key: "science",
    question: "What is the science behind the NeuroFit Method?",
    answer: (
      <span>
        <span>{"NeuroFit stands on the shoulders of giants. The core components included in our nervous system approach have been validated by neuroscience research from top universities, and the data from tens of thousands of sessions on our app. Our list of sources is updated regularly and can be viewed "}</span>
        <span onClick={() => {window.open("/research", "_blank", "noopener noreferrer")}} style={{fontFamily: "Futura Book", textDecoration: "underline", textUnderlineOffset: 2, cursor: "pointer"}}>{"here"}</span>
        <span>{"."}</span>
      </span>
    )
  },  
  {
    key: "somatic_techniques",
    question: "Do you teach somatic techniques?",
    answer: "Yes, we cover some somatic techniques in the Certification component, and we also cover them on the live monthly calls."
  },
  {
    key: "background_experience",
    question: "How much background or experience do I need for this certification?",
    answer: "Our approach to nervous system is designed to be very simple, practical, and concise. Most of our trainers come in with experience in other fields (eg, coaching, healing, fitness training), but no prior knowledge of the nervous system is needed for our approach."
  },
  {
    key: "practice",
    question: "Is there an opportunity to practice facilitating with other trainers?",
    answer: "Yes, inside the community you will have the opportunity to work with other Certified Trainers and practice these techniques before you work with clients."
  },
  {
    key: "cert_vs_app_header",
    is_header: true,
    text: "Certification Vs. App"
  },
  {
    key: "teach_methodology",
    question: "Will I learn to facilitate NeuroFit's methodology separate from the app?",
    answer: "Yes. We equip you with all of the knowledge needed for your client sessions to be entirely self-contained."
  },
  {
    key: "program_vs_app",
    question: "How does this program differ from simply using the NeuroFit App?",
    answer: "This program is far more than just a certification - beyond our nervous system course, you'll also receive 12-months access to our live coaching mastermind, Trainer Dashboard, and affiliate opportunities. In contrast, the app helps new members embody the basic fundamentals of nervous system fitness."
  },
  {
    key: "app_vs_cert",
    question: "How does the NeuroFit App fit into the certification?",
    answer: "We recommend that you suggest the app to your clients to optimize their progress and keep them on track between sessions. The app is quick to hone in on key habits and life aspects, which helps maximize the effectiveness of client sessions."
  },
  {
    key: "purchases",
    is_header: true,
    text: "Program Purchases"
  },
  {
    key: "payment_plan",
    question: "Do you offer a payment plan?",
    answer: (
      <span>
        <span>{"We do offer a payment plan - 3 monthly payments of $200 USD. You can purchase this payment plan via Kajabi "}</span>
        <span onClick={() => {window.open(CertificationThreePaymentCheckoutLink, "_blank", "noopener noreferrer")}} style={{fontFamily: "Futura Book", textDecoration: "underline", textUnderlineOffset: 2, cursor: "pointer"}}>{"here"}</span>
        <span>{"."}</span>
      </span>
    )
  },
  {
    key: "paypal",
    question: "Do you offer PayPal as a payment option?",
    answer: "No - in order to provide streamlined services and ensure fast affiliate payouts, we currently don't offer PayPal."
  },
  {
    key: "membership",
    is_header: true,
    text: "Membership"
  },
  {
    key: "affiliate_requirements",
    question: "Do you offer an affiliate program?",
    answer: "Yes - once certified, our trainers can refer new clients to the app and trainers to the program with a unique affiliate code. Our automated payouts most often land in the account of your choice within 2-3 business days of purchase."
  },
  {
    key: "personal_trainer",
    question: "I'm a personal trainer. Will I receive CEUs for completing this program?",
    answer: "This program is both NASM and AFAA approved - personal trainers will receive 1 NASM and 10 AFAA CEUs upon completion."
  },
  {
    key: "app_membership",
    question: "Does the Certification include membership to the app?",
    answer: "No - you can purchase your app membership separately. Many of our trainers have purchased the app already, so we do not offer it with the Certification."
  }
]

let defaultShowAnswer = {}
FAQItems.map(key => defaultShowAnswer[key] = false)

let defaultCourseItemsShowDetail = {}
CourseOutlineItems.map(key => defaultCourseItemsShowDetail[key] = false)

class TrainerCertificationDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isIOSDevice: isIOSDevice(),
      showAnswer: defaultShowAnswer,
      showCourseDetail: defaultCourseItemsShowDetail,
      showSettingsModal: false,
      learnMoreRef: React.createRef(),
      superiorResultsRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      bottomPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      coachBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isHeroImageLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef()
    }
  }

  handleOnClickLearnMore = (event) => {
    //.current is verification that your element has rendered
    if (this.learnMoreRef.current){
        this.learnMoreRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  handleOnScrollToCheckout = () => {
    if (this.checkoutRef.current) {
        this.checkoutRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  adjustBgPositionById = (id, scrollTop, innerHeight, xvalue="center", bgImage=undefined) => {
    let target = document.getElementById(id)

    // let factor = this.props.isIOSDevice ? 0.0 : 0.0 //0.2
    // let yvalue = (target.offsetTop - scrollTop) * factor
    // let yvalueSign = `${(yvalue > 0 ? "-" : "")}`

    if (!!(bgImage) && !!(target)) {
      target.style.backgroundImage = bgImage
    }

    // target.style.backgroundPosition = `${xvalue} ${yvalue}px`
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const hasScrolled = true

    const scrollTop = e.target.scrollTop

    this.adjustBgPositionById("lp-bg-image-container-loren", scrollTop, innerHeight, "left", "url(/images/loren-coach.webp), linear-gradient(0deg, #616161 0%, #919191 33%, #C1C1C1 67%, #F1F1F1 100%)")

    const coachDiv = document.getElementById("meet-your-trainer")
    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const bottomPageScrollProgress = coachDiv && Math.min(Math.max(1.5 * ((e.target.scrollTop - (e.target.scrollHeight - coachDiv.clientHeight)) / innerHeight), 0.0), 0.6)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(${237 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${238 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${242 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, 1.0)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const coachBackdropFilter = `blur(${Math.min(bottomPageScrollProgress * 20, 20)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`


      if (!this.state.hasScrolled) {
        initializePixelIfAvailable()
      }
      const updatedState = {headerBackgroundColor, heroOverlayColor, hasScrolled, dropShadowColor, heroBackdropFilter, coachBackdropFilter, topPageScrollProgress, bottomPageScrollProgress, headerBackgroundBlur, logoOutlineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    } else {
      const updatedState = {headerBackgroundColor: "transparent", heroOverlayColor: "transparent", heroBackdropFilter: "none", coachBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#EDEEF2", headerBackgroundBlur: "none", topPageScrollProgress: 0, bottomPageScrollProgress: 0, headerBorderColor: "transparent", videoOpacity: 1}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    }
  }

  async componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isNative,
      isNasmPage,
      onClickNativeCourseCheckout,
      onClickNativeCertificationCheckout
    } = this.props


    const {show, showAnswer, showCourseDetail, learnMoreRef, hasScrolled, showSettingsModal, scrollingContainerRef, superiorResultsRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, coachBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, bottomPageScrollProgress, showIframe, isHeroImageLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")
    const coachTextShadow = range(4).map(() => (`0px 0px 5px rgba(0, 0, 0, 0.16)`)).join(",")

    const enrollmentCloseDate = getNextEnrollmentCloseUnixTimestamp()

    return (
      <div 
        id={"certification-page-container"}
        className={"disable-scrollbars"}
        ref={elem => this.scrollingContainerRef = elem}
        style={containerStyle}
      >
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, right: 0, backgroundColor: "transparent", pointerEvents: "none", transition: "all 300ms ease-out", transitionProperty: "background-color", zIndex: 2000}}>
        </div>
        <div style={{textAlign: "left", width: "100vw", height: innerHeight, pointerEvents: "none"}}>
          <CSSTransition
            in={!isHeroImageLoaded}
            timeout={400}
            classNames={"fade-in"}
            onExited={() => {}}
            unmountOnExit
          >
            <div className={"video-loading-background-animation"} style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", alignItems: "center", justifyContent: "center", zIndex: -2}}>
              <LoadingLogo
                show={true}
                hideTitle={true}
                hideAnimation={true}
                responsiveSize={"100px"}
                marginTop={0}
                marginRight={"0px"}
                color={"#CFD2DD"}
                lineColor={"#EDEEF2"}
                fontFamily={"Futura Book"}
                showProgressionBar={false}
              />
            </div>
          </CSSTransition>
          <div style={{position: "fixed", top: 0, width: innerWidth, backgroundColor: "#EDEEF2", height: "100%", display: "flex", justifyContent: "center", zIndex: -2, opacity: isHeroImageLoaded ? 1.0 : 0.0, transition: "opacity 250ms linear"}}>
            <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <img src={isMobile ? "/images/certification-hero-dash-mobile.webp" : "/images/certification-hero-dash.webp"} fetchpriority="high" onLoad={() => this.setState({isHeroImageLoaded: true})} style={{maxWidth: 700, width: "85%"}} alt="A 3-Week Nervous System Certification for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth." />
            </div>
          </div>
          <div style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", justifyContent: "center", zIndex: (topPageScrollProgress > 0) ? -1 : 0}}>
            <div style={{display: "flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "space-between"}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: 60, pointerEvents: "none"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textShadow, textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", marginTop: `calc(3vh + ${safeAreaInsets.top}px)`, textTransform: "uppercase"}}>
                    {`BECOME AN EXPERT NERVOUS SYSTEM FITNESS TRAINER`}
                  </div>
                </div>
              </div>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginBottom: "5vh", pointerEvents: "none"}}>
                <div style={{width: "85%", maxWidth: 700, pointerEvents: "none"}}>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura Book", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "3vh", color: "#FFFFFF", backgroundColor: "#000000", padding: "12px 15px", border: "none"}}
                      onClick={() => {
                        recordPixelEventIfAvailable("Lead")
                        recordFunnelEventResultGA(GA_LABEL_CLICKED_CERTIFICATION_CHECKOUT_BUTTON, {location: "hero"})
                        this.superiorResultsRef.scrollIntoView({behavior: "smooth"})
                      }}
                    >
                      <LoadingLogo
                        show={true}
                        hideAnimation={true}
                        hideLogo
                        responsiveSize={"min(6vw, 25px)"}
                        marginTop={"0px"}
                        padding={"0px"}
                        marginRight={"0.4em"}
                        color={"#FFFFFF"}
                        lineColor={"#000000"}
                        fontFamily={"Futura Book"}
                        text={"EXPLORE PROGRAM"}
                        showProgressionBar={false}
                      />
                    </button>
                  </div>
                  <div style={{display: "flex", justifyContent: "center", marginTop: "3vh", pointerEvents: "none"}}>
                    <svg onClick={() => this.superiorResultsRef.scrollIntoView({behavior: "smooth"})} style={{pointerEvents: "all", cursor: "pointer", height: "min(12vw, 70px)", width: "min(12vw, 70px)"}} className="loading-pulse-animation-down-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" version="1.1" x="0px" y="0px"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g fill="#000000"><path d="M20,27.4142136 L32.7071068,14.7071068 L31.2928932,13.2928932 L20,24.5799999 L8.70710678,13.2928932 L7.29289322,14.7071068 L20,27.4142136 Z"></path></g></g></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{position: "fixed", top: 0, width: innerWidth, backdropFilter: heroBackdropFilter, WebkitBackdropFilter: heroBackdropFilter, height: "100%", display: "flex", backgroundColor: heroOverlayColor, justifyContent: "center", zIndex: -1, pointerEvents: "none"}}>
          </div>
          {(topPageScrollProgress === 1) && (
            <div id="lp-bg-image-container-loren" style={{position: "fixed", top: 0, width: innerWidth, height: "100%", zIndex: -1}}>
              <div style={{width: "100%", height: "100%", backgroundColor: `rgba(0, 0, 0, ${bottomPageScrollProgress})`}}>
              </div>
            </div>
          )}
        </div>
        <div style={{padding: "calc(60px + 5vh) 0px", textAlign: "left", borderTop: "2px #000000 solid", width: "100vw", backgroundColor: "#FFFFFF"}} ref={elem => this.superiorResultsRef = elem}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div>
                <div style={{fontFamily: "Futura Book", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                  {"ADD NERVOUS SYSTEM FITNESS TO YOUR BUSINESS"}
                </div>
                <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura Book", fontStyle: "italic", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                  {`Master nervous system regulation, somatic release exercises, and trauma-informed witnessing, and become a Certified Trainer with our:`}
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura Book", fontStyle: "italic", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"Nervous System Certification Program,"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura Book", fontStyle: "italic", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"Nervous System Trainer Tech + Toolkit, and"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura Book", fontStyle: "italic", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"12 months of ongoing support, Live Coaching and Trainer Community"}
                  </div>
                </div>
                <div style={{borderTop: "3px #000000 solid", borderBottom: "3px #000000 solid", margin: "8vh 0px", display: "flex", justifyContent: "center", padding: "2vh 0px"}}>
                  <div style={{display: "inline-block"}}>
                    <div style={{color: "#000000", fontFamily: "Futura Medium", fontSize: "min(6.5vw, 30px)"}}>
                      {enrollmentCloseDate.is_closed ? `ENROLLMENTS CLOSE` : `ENROLLMENTS CLOSE IN`}
                    </div>
                    <div style={{color: "#000000", fontFamily: "Futura Medium", fontSize: "min(6.5vw, 30px)"}}>
                      <Countdown
                        date={enrollmentCloseDate.date}
                        renderer={({days, hours, minutes, seconds, completed }) => {
                          // Render a countdown
                          return (
                            <span style={{color: "#000000", fontFamily: "Futura Medium", fontSize: "min(8vw, 40px)", textAlign: "center"}}>{completed ? "MARCH 14TH" : `${days}D ${hours}H ${minutes}M ${seconds}S`}</span>
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{display: "flex", justifyContent: "center", marginTop: "2vh"}}>
                <button
                  style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura Book", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#ffffff", backgroundColor: "#000000", padding: "12px 15px", border: "none"}}
                  onClick={() => {
                    recordPixelEventIfAvailable("Lead")
                    recordFunnelEventResultGA(GA_LABEL_CLICKED_CERTIFICATION_CHECKOUT_BUTTON, {location: "accelerate_growth"})
                    this.checkoutRef.scrollIntoView({behavior: "smooth"})
                  }}
                >
                  <LoadingLogo
                    show={true}
                    hideAnimation={true}
                    hideLogo
                    responsiveSize={"min(6vw, 25px)"}
                    marginTop={"0px"}
                    padding={"0px"}
                    marginRight={"0.4em"}
                    color={"#FFFFFF"}
                    lineColor={"#000000"}
                    fontFamily={"Futura Book"}
                    text={"ENROLL NOW"}
                    showProgressionBar={false}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{backgroundColor: "#000000", display: "flex", justifyContent: "center"}}>
          <img style={{width: "70%", maxWidth: 400, margin: "5vh 0px"}} src="/images/well-and-good-laurel-white.webp" />
        </div>
        <div style={{padding: "calc(60px + 5vh) 0px", textAlign: "left", borderTop: "2px #000000 solid", width: "100vw", backgroundColor: "#EDEEF2"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura Book", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                {"BACKED BY DATA. DRIVEN BY EMBODIED HEALTH PROFESSIONALS."}
              </div>
              {isNative ? (
                <div style={{margin: "max(3vh, 20px) 0px", fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                  <span>{"The NeuroFit Method is grounded in neuroscience, and validated by tens of thousands of sessions on our nervous system app."}</span>
                </div>
              ) : (
                <div style={{margin: "max(3vh, 20px) 0px", fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                  <span>
                    {"The NeuroFit Method is grounded in neuroscience, and validated by tens of thousands of sessions on our "}
                  </span>
                  <a href={NeuroFitUniversalAppStoreLink} style={{color: "#000000", fontFamily: "Futura Book"}} target="_blank" rel="noopener noreferrer nofollow">
                    {"nervous system app"}
                  </a>
                  <span>
                    <span>{"."}</span>
                  </span>
                </div>
              )}
              <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                <span>{"In just 5 minutes of NeuroFit somatic training, 95% of our members report relief from real-time stress and dysregulation:"}</span>
              </div>
              <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                <div style={{width: "100%", maxWidth: 450}}>
                  <LineGraph
                    dataIsAvailable
                    title={`REAL-TIME STRESS RELIEF`}
                    data={{
                      labels: ["0", "1", "2", "3"],
                      series: [[0, 88.8, 95, 98.6]],
                    }}
                    labelInterpolationFnc={(val) => `${val}%`}
                    highYValue={100}
                    hideLogo
                    xAxisTitle={"Exercises Completed (2-3 mins. each)"}
                  />
                </div>
              </div>
              <div style={{margin: "max(3vh, 20px) 0px", fontFamily: "Futura Book", fontStyle: "italic", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                <span>{"With our data-driven approach, you'll know the most effective somatic exercises and nervous system practices for any client-- ensuring superior results at NeuroFit speed."}</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{textAlign: "left", width: "100vw", padding: "calc(60px + 5vh) 0px", borderTop: "2px #000000 solid", backgroundColor: "#FFFFFF"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura Book", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                {"WHAT YOU GET"}
              </div>
              <div style={{fontFamily: "Futura Book", fontSize: "min(8.5vw, 40px)", marginTop: "4vh", textAlign: "left"}}>
                {"I. NERVOUS SYSTEM FITNESS COURSE"}
              </div>
              <div style={{fontFamily: "Futura Book", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "left"}}>
                {`VALUED AT $1,000 USD`}
              </div>
              <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`Detailed Nervous System Course. Lays out our practical + data-driven methodology which includes:`}
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"A deep-dive into the NeuroFit Method - a new and novel approach to nervous system regulation"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"How to facilitate client breakthroughs with somatic exercises, in a trauma-informed manner"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"How to become fully embodied and regulated as a burnout + nervous system expert"}
                </div>
              </div>
              <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`A detailed course outline is provided below in the FAQs.`}
              </div>
              <div style={{fontFamily: "Futura Book", fontSize: "min(8.5vw, 40px)", marginTop: "4vh", textAlign: "left"}}>
                {"II. TRAINER TECH + TOOLKIT"}
              </div>
              <div style={{fontFamily: "Futura Book", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "left"}}>
                {`VALUED AT $1,000 USD`}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura Book", marginTop: "4vh", fontSize: "min(7.5vw, 32px)", textAlign: "left"}}>
                {"TRAINER DASHBOARD"}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`Optimize your clients' results in real-time with data-driven insights.`}
              </div>
              <div style={{fontFamily: "Futura Book", marginTop: "4vh", fontSize: "min(7.5vw, 32px)", textAlign: "left"}}>
                {"SOMATIC EXERCISE MANUAL"}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`Pinpoint the best somatic exercise for any client, in any nervous system state.`}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura Book", fontSize: "min(8.5vw, 40px)", marginTop: "4vh", textAlign: "left"}}>
                {"III. PROFITABLE CHALLENGE FORMULA"}
              </div>
              <div style={{fontFamily: "Futura Book", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "left"}}>
                {`VALUED AT $1,000 USD`}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`A validated 7-figure template for running online challenges as a health professional.`}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura Book", fontSize: "min(8.5vw, 40px)", marginTop: "4vh", textAlign: "left"}}>
                {"IV. ONGOING TRAINER SUPPORT"}
              </div>
              <div style={{fontFamily: "Futura Book", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "left"}}>
                {`VALUED AT $2,000 USD`}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura Book", marginTop: "4vh", fontSize: "min(7.5vw, 32px)", textAlign: "left"}}>
                {"LIVE TRAININGS WITH OUR FOUNDER"}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`Get hands-on support with monthly live trainings covering:`}
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"Advanced support for facilitating somatic exercises"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"Embodied business training in sales & marketing"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"Real-time Q&A, to ensure a seamless experience for your clients"}
                </div>
              </div>
              <div style={{fontFamily: "Futura Book", marginTop: "4vh", fontSize: "min(7.5vw, 32px)", textAlign: "left"}}>
                {"TRAINER COMMUNITY"}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`Connect with NeuroFit trainers and polish your skills inside our Certified Trainer Community.`}
              </div>
            </div>
          </div>
        </div>
        <div style={{textAlign: "left", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#EDEEF2", borderTop: "2px #000000 solid"}} ref={elem => this.checkoutRef = elem}>
          <div style={{width: "100%"}}>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 500}}>
                <div style={{fontFamily: "Futura Book", fontSize: "min(8.5vw, 40px)", marginBottom: "2vh", textAlign: "left"}}>
                  {"ENROLL NOW"}
                </div>
              </div>
            </div>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{width: "85%", maxWidth: 500}}>
                {hasScrolled && (
                  <StripeCheckoutForm
                    isNative={isNative}
                    onShowSuccessMessage={(successMessage) => {}}
                    onShowErrorMessage={(errorMessage) => {}}
                    onApplyPromotionCode={promotionCode => {}}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{textAlign: "left", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", textTransform: "uppercase"}}>
                {"Join A Dedicated Community Of Certified Trainers"}
              </div>
              <div style={{margin: "max(3vh, 20px) 0px", fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {"The Certified Trainer Community brings together embodied health professionals from many different backgrounds. Testimonials are below:"}
              </div>
            </div>
          </div>
          <div className="disable-scrollbars" style={{scrollSnapType: "x mandatory", alignItems: "center", marginTop: `3vh`, padding: "5px 0px", overflowX: "scroll", overflowY: "hidden", display: "flex", WebkitOverflowScrolling: "touch"}}>
            {TrainerTestimonials.map(review => (
              (review.type === "text") ? (
                <AppReviewPanel
                  key={review.username}
                  title={review.title}
                  reviewStars={review.stars}
                  dateString={review.dateString}
                  username={review.username}
                  occupation={review.occupation}
                  countryFlag={review.countryFlag}
                  description={review.description}
                />
              ) : (
                <VideoTestimonialPanel
                  key={review.username}
                  reviewerName={review.username}
                  occupation={review.occupation}
                  countryFlag={review.countryFlag}
                  videoAspectRatio={review.videoHeightPixels / review.videoWidthPixels}
                  videoSourcePath={review.videoSourcePath}
                  videoPosterSourcePath={review.videoPosterImagePath}
                  innerWidth={innerWidth}
                />
              ))
            )}
          </div>
        </div>
        <div style={{backgroundColor: "#000000", display: "flex", justifyContent: "center", borderTop: "2px #000000 solid"}}>
          <div style={{width: "85%", maxWidth: 450, margin: "5vh 0px", color: "#FFFFFF", fontSize: "min(6vw, 26px)", textAlign: "center"}}>
            <div style={{fontFamily: "Futura Light", fontStyle: "italic"}}>{"Learn more about how you can get fast + superior client results:"}</div>
            <div onClick={() => window.open("/training", "_blank", "noopener noreferrer")} style={{fontFamily: "Futura Medium", display: "flex", width: "100%", alignItems: "center", justifyContent: "center", marginTop: "1vh", cursor: "pointer", textDecoration: "underline", textUnderlineOffset: 2}}>
              <ExternalLinkSvg size={"min(4.5vw, 18px)"} color={"#FFFFFF"} />
              <span style={{marginLeft: "0.25em"}}>{"WATCH THE FREE TRAINING"}</span>
            </div>
          </div>
        </div>
        <div style={{textAlign: "left", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#EDEEF2", borderTop: "2px #000000 solid", borderBottom: "2px #000000 solid"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", textTransform: "uppercase"}}>
                {"Nervous System Course Outline"}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              {CourseOutlineItems.map(f => (
                <FaqSection
                  key={f.key}
                  question={f.question}
                  answer={f.answer}
                  showAnswer={showCourseDetail[f.key]}
                  onToggleShowAnswer={() => this.setState({showCourseDetail: {...showCourseDetail, [f.key]: (!showCourseDetail[f.key])}})}
                  marginTop={"2vh"}
                />
              ))}
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", paddingTop: "calc(60px + 5vh)", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", textTransform: "uppercase"}}>
                {"Frequently Asked Questions"}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              {FAQItems.map(f => (
                  (f.is_header ? (
                    <div key={f.key} style={{fontSize: "min(7vw, 32px)", textTransform: "uppercase", margin: "min(9vh, 45px) 0px 0px 0px", color: "#000000", textAlign: "left", fontFamily: "Futura Book"}}>
                      {f.text}
                    </div>
                  ) : (
                    <FaqSection
                      key={f.key}
                      question={f.question}
                      answer={f.answer}
                      showAnswer={showAnswer[f.key]}
                      onToggleShowAnswer={() => this.setState({showAnswer: {...showAnswer, [f.key]: (!showAnswer[f.key])}})}
                      marginTop={"2vh"}
                    />
                  ))
                )
              )}
            </div>
          </div>
        </div>
        <div id="meet-your-trainer" style={{textAlign: "left", width: "100vw"}}>
          <div>
            <div style={{color: "white", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: "calc(60px + 5vh)"}}>
              <div style={{width: "85%", maxWidth: 700}}>
                <div style={{textShadow: coachTextShadow}}>
                  <div style={{fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(8.5vw, 40px)", textAlign: "left", marginTop: innerHeight}}>  
                    {"MEET YOUR COACH: LOREN HOGUE"}
                  </div>
                  <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                    {"Loren Hogue is the Co-CEO of NeuroFit with her husband Andrew."}
                  </div>
                  <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                    {"She’s a Master Coach and Trainer with 3000+ hours of experience, and has helped thousands of students transform their lives and businesses with her challenges, courses and coaching."}
                  </div>
                  <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                    {"With a background as an attorney, life coach and business coach, Loren takes a very practical and hands-on approach to Nervous System Fitness that translates effortlessly in your life, your business and your work with clients."}
                  </div>
                  <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                    {"Loren & Andrew co-create NeuroFit -  the world’s leading nervous system regulation app, and a highly effective, data-driven, and affordable approach to well-being. They now share this knowledge with health professionals inside the NeuroFit Trainer Certification."}
                  </div>
                  <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                    {"If this simple, practical, and effective approach speaks to you, then take the leap today:"}
                  </div>
                  <div style={{display: "flex", justifyContent: "center", marginTop: "min(20px, 5vh)"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura Book", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#000000", backgroundColor: "#FFFFFF", padding: "12px 15px", border: "2px #000000 solid"}}
                      onClick={() => {
                        recordPixelEventIfAvailable("Lead")
                        recordFunnelEventResultGA(GA_LABEL_CLICKED_CERTIFICATION_CHECKOUT_BUTTON, {location: "about_us"})
                        this.checkoutRef.scrollIntoView({behavior: "smooth"})
                      }}
                    >
                      <LoadingLogo
                        show={true}
                        hideAnimation={true}
                        hideLogo
                        responsiveSize={"min(6vw, 25px)"}
                        marginTop={"0px"}
                        padding={"0px"}
                        marginRight={"0.4em"}
                        color={"#000000"}
                        lineColor={"#FFFFFF"}
                        fontFamily={"Futura Book"}
                        text={"ENROLL NOW"}
                        showProgressionBar={false}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "left", width: "100vw", paddingTop: "calc(60px + 5vh)", paddingBottom: safeAreaInsets.bottom}}>
              <div style={{padding: 15, paddingBottom: 15 + safeAreaInsets.bottom, backgroundColor: "transparent", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura Book", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                <div style={{display: "inline-block"}}>
                  <span style={{color: "#c1c4d2"}}>
                    {"© NEUROFIT 2023"}
                  </span>
                  <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                    {"•"}
                  </span>
                  <span style={{color: "#c1c4d2"}}>
                    {"ALL RIGHTS RESERVED"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TrainerCertificationDetails