import React from 'react'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../constants/requestStates'

import {
  i18n,
} from '../../utils'

import {
  OvalSpinnerSvg,
} from '../../constants/svgs'

import CompletionCheckIcon from './CompletionCheckIcon'

import {
  ErrorSvg,
} from '../../constants/svgs'

class PromotionCodeInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      promotionCode: props.existingPromotionCode || "",
      isInputFocused: false,
      hasInputFocused: false
    };
  }

  toggleInputFocus(isInputFocused) {
    this.setState({isInputFocused, hasInputFocused: this.state.hasInputFocused || isInputFocused})
  }

  handleUpdatePromotionCode(updatedPromotionCodeValue) {
    const {
      onUpdatePromotionCode,
    } = this.props
    const sanitizedPromotionCode = updatedPromotionCodeValue.replace(/[^0-9A-Za-z]/g, '').toUpperCase()
    this.setState({promotionCode: sanitizedPromotionCode})
    onUpdatePromotionCode(sanitizedPromotionCode)
  }

  render() {
    const {
      disableInput,
      hideSubmitButton,
      promotionCodeRequestState,
      onSubmitPromotionCode,
      submitButtonText,
      successMessage,
      inputFontFamily,
      inputPlaceholderText
    } = this.props

    const {
      promotionCode,
      isInputFocused,
      hasInputFocused
    } = this.state

    const showSubmitButton = !hideSubmitButton && !!(promotionCode) && hasInputFocused

    return (
      <div style={{marginBottom: "10px", border: (promotionCodeRequestState === REQUEST_SUCCESS) ? "2px #000000 solid" : (promotionCodeRequestState === REQUEST_ERROR) ? "2px red solid" : "none", backgroundColor: (promotionCodeRequestState === REQUEST_SUCCESS) ? "#FFFFFF" : "", transition: "border 100ms linear, background-color 100ms linear"}} className={(isInputFocused || !!promotionCode) ? "promotion-code-checkout-container-filled" : "promotion-code-checkout-container"}>
        <input
          type="text"
          ref={elem => this.promoCodeInputRef = elem}
          disabled={(promotionCodeRequestState === REQUEST_FETCHING) || disableInput}
          style={{display: "inline-block !important", fontFamily: (inputFontFamily || ((promotionCodeRequestState === REQUEST_SUCCESS) ? "Futura Medium" : "Futura Book"))}}
          className={`promotion-code-checkout${(isInputFocused || !!promotionCode) ? " promotion-code-checkout-filled" : ""}`}
          onFocus={() => this.toggleInputFocus(true)}
          onBlur={() => this.toggleInputFocus(false)}
          placeholder={inputPlaceholderText || (isInputFocused ? "Enter Referral Code..." :"Have a Referral Code?")}
          autoCapitalize="words"
          autoCorrect="false"
          autoComplete="none"
          spellCheck={false}
          value={(promotionCodeRequestState === REQUEST_SUCCESS) ? (successMessage || promotionCode) : promotionCode}
          onChange={(e) => this.handleUpdatePromotionCode(e.target.value)}
          onPaste={(e) => this.handleUpdatePromotionCode(e.target.value)}
        />
        <span style={{opacity: showSubmitButton ? 1.0 : 0.0, display: "flex", alignItems: "center", transform: showSubmitButton ? "none" : "scale(0.9)", transition: "opacity 100ms linear, transform 100ms linear", paddingRight: 12}}>
          {(promotionCodeRequestState === REQUEST_UNSTARTED) && (
            <button
              style={{fontFamily: "Futura Medium", cursor: "pointer", fontSize: 18, color: "#000000", backgroundColor: "transparent", padding: "0px", border: "none"}}
              disabled={(promotionCodeRequestState !== REQUEST_UNSTARTED) || disableInput}
              onClick={() => {
                onSubmitPromotionCode(promotionCode)
              }}
            > 
              {submitButtonText || "APPLY"}
            </button>
          )}
          {(promotionCodeRequestState === REQUEST_FETCHING) && (
            <OvalSpinnerSvg
              size={20}
              color={"#777777"}
            />
          )}
          {(promotionCodeRequestState === REQUEST_SUCCESS) && (
            <CompletionCheckIcon
              isComplete
              checkboxBaseClassName={"membership-plan-button"}
            />
          )}
          {(promotionCodeRequestState === REQUEST_ERROR) && (
            <ErrorSvg
              size={25}
              color={"red"}
              onClick={() => {
                this.handleUpdatePromotionCode("")
                this.promoCodeInputRef.focus()
              }}
            />
          )}
        </span>
      </div>
    )
  }
}

export default PromotionCodeInput