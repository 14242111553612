import React from 'react';
import {PaymentElement, CardElement, PaymentRequestButtonElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import ProgramResultsPanel from './ProgramResultsPanel'
import ConfirmSection from './ConfirmSection'
import LoadingLogo from './LoadingLogo'
import PromotionCodeInput from './PromotionCodeInput'

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import {
  StripePublishableKey,
  NeuroFitUniversalAppStoreLink,
  getFormattedDateDaysFromNow,
  getCsrfTokenFromCookies,
  i18n,
  INVITE_CODE_LOCAL_STORAGE_KEY,
  subdomainUrls,
  recordFunnelEventResultGA,
  recordPixelEventIfAvailable,
  recordLinkClickAndRedirect,
  AmazonNeuroFitBookUrl,
  sanitizeEmailAddress,
} from '../../utils'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../constants/requestStates'

import {
  LockSvg,
  StripeSvg,
  ErrorSvg,
} from '../../constants/svgs'

import {
  GA_LABEL_CERTIFICATION_PURCHASE_TRIGGERED,
  GA_LABEL_CERTIFICATION_PURCHASE_COMPLETED,
  GA_LABEL_BOOK_PURCHASE_TRIGGERED,
  GA_LABEL_BOOK_PURCHASE_COMPLETED,
  GA_LABEL_BOOK_PURCHASE_AMAZON_CLICK,
} from '../../constants/gaEventLabels'

import agent from '../../agent'

const NEUROFIT_FONTS = [
  {
    src: 'url(https://neurofit.app/fonts/FuturaLT-Book.woff2)',
    family: 'Futura Book',
    style: 'normal'
  }
]

class CheckoutForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      isComplete: false,
      isProcessingPayment: false,
      promotionCodeRequestState: REQUEST_UNSTARTED,
      nameOnCard: "",
      emailAddress: "",
      clientSecretResult: {},
      clientSecret: "",
      selectedProductName: "",
      selectedProductPrice: 0,
      selectedBundle: false,
      paymentStatus: REQUEST_UNSTARTED,
      paymentFailureMessage: "",
      paymentRequest: undefined,
      paymentRequestLoaded: false,
    };
  }

  async componentDidMount() {
    await this.handleRetrieveClientSecret()
  }

  async setUpPaymentRequestButtonElement() {
    const {stripe, isBookPage, selectedBundle} = this.props
    const {selectedProductPrice, selectedProductName, paymentRequest} = this.state

    if (!stripe) {
       return
    }

    let defaultProductName, defaultProductPrice;
    if (isBookPage) {
      defaultProductName = selectedBundle ? "NeuroFit: Nervous System Book+App Bundle" : "NeuroFit: Nervous System Book"
      defaultProductPrice = selectedBundle ? 3500 : 500
    } else {
      defaultProductName = "NeuroFit: Nervous System Certification"
      defaultProductPrice = 54700
    }

    // Validate whether we can show PaymentRequestButtonElement.
    if (!!(paymentRequest)) {
      paymentRequest.update({
        currency: 'usd',
        total: {
          label: selectedProductName || defaultProductName,
          amount: selectedProductPrice || defaultProductPrice,
        }
      })
    } else {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: selectedProductName || defaultProductName,
          amount: selectedProductPrice || defaultProductPrice,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      const result = await pr.canMakePayment()
      this.setState({paymentRequestLoaded: true})
      if (result) {
        pr.on("paymentmethod", this.handlePaymentRequestButtonCheckout)
        this.setState({paymentRequest: pr})
      }
    }
  }

  handleSetBookProductId = (selectedBundle, clientSecretResult) => {
    this.setState({
      selectedBundle,
      clientSecret: selectedBundle ? clientSecretResult.bundle_client_secret : clientSecretResult.book_client_secret,
      selectedProductName: selectedBundle ? "NeuroFit: Nervous System Book+App Bundle" : "NeuroFit: Nervous System Book",
      selectedProductPrice: selectedBundle ? clientSecretResult.book_bundle_price_usd_cents : clientSecretResult.book_price_usd_cents
    })
  }

  handleRetrieveClientSecret = async (promotionCode) => {
    const {
      isBookPage
    } = this.props
    const {
      selectedBundle,
    } = this.state

    const affiliate_code = promotionCode || ""

    !!(affiliate_code) && this.setState({promotionCodeRequestState: REQUEST_FETCHING})
    
    try {
      // Fetch Payment Intent By Product.
      let clientSecretResult;
      if (isBookPage) {
        clientSecretResult = await agent.NeuroFitBackend.createBookPaymentIntents({affiliate_code})
      } else {
        clientSecretResult = await agent.NeuroFitBackend.createCertificationPaymentIntent({affiliate_code})
      }

      if (clientSecretResult.success) {
        !!(affiliate_code) && this.setState({promotionCodeRequestState: REQUEST_SUCCESS})
        this.setState({clientSecretResult})
        if (isBookPage) {
          this.handleSetBookProductId(selectedBundle, clientSecretResult)
        } else {
          this.setState({clientSecret: clientSecretResult.client_secret, selectedProductName: "NeuroFit: Nervous System Certification", selectedProductPrice: clientSecretResult.price_usd_cents})
        }
      } else {
        !!(affiliate_code) && this.setState({promotionCodeRequestState: REQUEST_ERROR})
      }
    } catch (err) {
      !!(affiliate_code) && this.setState({promotionCodeRequestState: REQUEST_ERROR})
    }
  }

  handlePaymentRequestButtonCheckout = async (ev) => {
    const {
      stripe,
      elements,
      onShowSuccessMessage,
      onShowErrorMessage,
      isCourse,
      isBookPage,
    } = this.props;

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const {clientSecret, selectedBundle} = this.state

    const productId = isBookPage ? (selectedBundle ? "book_bundle" : "book") : "certification"

    recordFunnelEventResultGA(isBookPage ? GA_LABEL_BOOK_PURCHASE_TRIGGERED : GA_LABEL_CERTIFICATION_PURCHASE_TRIGGERED)
    recordPixelEventIfAvailable("AddPaymentInfo", productId)

    // Confirm the PaymentIntent without handling potential next actions (yet).
    const {paymentIntent, error} = await stripe.confirmCardPayment(
      clientSecret,
      {payment_method: ev.paymentMethod.id},
      {handleActions: false}
    );

    if (error) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      ev.complete('fail');
      console.log(error.message);
      onShowErrorMessage("Something went wrong using this payment method. Please try a different card.")
      this.setState({isProcessingPayment: false, paymentStatus: REQUEST_ERROR, paymentFailureMessage: `Something went wrong using this card: ${error.message} Please try a different card.`})

    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete('success');
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const {error} = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          console.log(error.message);
          onShowErrorMessage("Something went wrong using this payment method. Please try a different card.")
          this.setState({isProcessingPayment: false, paymentStatus: REQUEST_ERROR, paymentFailureMessage: `Something went wrong using this card: ${error.message} Please try a different card.`})
          // The payment failed -- ask your customer for a new payment method.
        } else {
          // The payment has succeeded.
          console.log(`Purchase Succeeded!`);
          recordFunnelEventResultGA(isBookPage ? GA_LABEL_BOOK_PURCHASE_COMPLETED : GA_LABEL_CERTIFICATION_PURCHASE_COMPLETED)
          recordPixelEventIfAvailable("Purchase", productId)
          this.setState({isProcessingPayment: false, paymentStatus: REQUEST_SUCCESS})
          onShowSuccessMessage(`Thank you for purchasing the NeuroFit ${isBookPage ? (selectedBundle ? "Book + App Bundle" : "Book") : (isCourse ? "Nervous System Course" : "Certification")}. In a few moments, you'll receive an email confirmation with details to access your content.`)
        }
      } else {
        // The payment has succeeded.
        console.log(`Purchase Succeeded!`);
        recordFunnelEventResultGA(isBookPage ? GA_LABEL_BOOK_PURCHASE_COMPLETED : GA_LABEL_CERTIFICATION_PURCHASE_COMPLETED)
        recordPixelEventIfAvailable("Purchase", productId)
        this.setState({isProcessingPayment: false, paymentStatus: REQUEST_SUCCESS})
        onShowSuccessMessage(`Thank you for purchasing the NeuroFit ${isBookPage ? (selectedBundle ? "Book + App Bundle" : "Book") : (isCourse ? "Nervous System Course" : "Certification")}. In a few moments, you'll receive an email confirmation with details to access your content.`)
      }
    }
  }
  
  handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    const {
      stripe,
      elements,
      onShowSuccessMessage,
      onShowErrorMessage,
      isCourse,
      isBookPage,
    } = this.props;

    const {
      nameOnCard,
      emailAddress,
      clientSecret,
      selectedBundle,
    } = this.state

    const productId = isBookPage ? (selectedBundle ? "book_bundle" : "book") : "certification"

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    this.setState({isProcessingPayment: true, paymentStatus: REQUEST_FETCHING})

    recordFunnelEventResultGA(isBookPage ? GA_LABEL_BOOK_PURCHASE_TRIGGERED : GA_LABEL_CERTIFICATION_PURCHASE_TRIGGERED)
    recordPixelEventIfAvailable("AddPaymentInfo", productId)

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use card Element to tokenize payment details
    let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        type: 'card',
        card: cardElement,
        billing_details: {
          name: nameOnCard,
          email: emailAddress,
        }
      },
      receipt_email: emailAddress
    });

    if (error) {
      console.log(error.message);
      onShowErrorMessage("Something went wrong using this payment method. Please try a different card.")
      this.setState({isProcessingPayment: false, paymentStatus: REQUEST_ERROR, paymentFailureMessage: `Something went wrong using this card: ${error.message} Please try a different card.`})
      return;
    } else {
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const {error} = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          console.log(error.message);
          onShowErrorMessage("Something went wrong using this payment method. Please try a different card.")
          this.setState({isProcessingPayment: false, paymentStatus: REQUEST_ERROR, paymentFailureMessage: `Something went wrong using this card: ${error.message} Please try a different card.`})
          // The payment failed -- ask your customer for a new payment method.
        } else {
          // The payment has succeeded.
          console.log(`Purchase Succeeded!`);
          recordFunnelEventResultGA(isBookPage ? GA_LABEL_BOOK_PURCHASE_COMPLETED : GA_LABEL_CERTIFICATION_PURCHASE_COMPLETED)
          recordPixelEventIfAvailable("Purchase", productId)
          this.setState({isProcessingPayment: false, paymentStatus: REQUEST_SUCCESS})
          onShowSuccessMessage(`Thank you for purchasing the NeuroFit ${isBookPage ? (selectedBundle ? "Book + App Bundle" : "Book") : (isCourse ? "Nervous System Course" : "Certification")}. In a few moments, you'll receive an email confirmation with details to access your content.`)
        }
      } else {
        // The payment has succeeded.
        console.log(`Purchase Succeeded!`);
        recordFunnelEventResultGA(isBookPage ? GA_LABEL_BOOK_PURCHASE_COMPLETED : GA_LABEL_CERTIFICATION_PURCHASE_COMPLETED)
        recordPixelEventIfAvailable("Purchase", productId)
        this.setState({isProcessingPayment: false, paymentStatus: REQUEST_SUCCESS})
        onShowSuccessMessage(`Thank you for purchasing the NeuroFit ${isBookPage ? (selectedBundle ? "Book + App Bundle" : "Book") : (isCourse ? "Nervous System Course" : "Certification")}. In a few moments, you'll receive an email confirmation with details to access your content.`)
      }
    }
  }

  handleUpdateNameOnCard = (nameOnCard) => {
    this.setState({nameOnCard})
  }

  resetPromotionCodeRequestState = (promotionCode) => {
    this.setState({promotionCodeRequestState: REQUEST_UNSTARTED})
  }

  togglePromotionCodeFocus = isPromotionCodeFocused => {
    this.setState({isPromotionCodeFocused})
  }

  handleUpdateEmailAddress = (emailAddress) => {
    this.setState({emailAddress: sanitizeEmailAddress(emailAddress)})
  }

  handleOnReady = async () => {
    this.setState({ready: true})

    await this.setUpPaymentRequestButtonElement()
  }

  handleUpdatedCompletionStatus = isComplete => {
    this.setState({isComplete})
  }

  render() {
    const {
      stripe,
      onClickSubmitAccessCode,
      onApplyPromotionCode,
      showFreeAccessCode,
      numberOfSeats,
      isNative,
      billingInterval,
      intervalPrice,
      isBookPage,
      isCourse
    } = this.props;
    const {ready, clientSecret, clientSecretResult, isComplete, promotionCodeRequestState, selectedProductPrice, selectedBundle, isProcessingPayment, paymentRequest, paymentRequestLoaded, paymentStatus, paymentFailureMessage, nameOnCard, emailAddress, promotionCode, isPromotionCodeFocused} = this.state;

    const readyForCheckout = isComplete && !!(nameOnCard) && !!(emailAddress)

    const checkoutButtonText = isProcessingPayment ? "PURCHASING..." :
          ((paymentStatus === REQUEST_SUCCESS) ? "PURCHASE COMPLETED" : "BUY NOW")

    const successMessage = `Thank you for purchasing the NeuroFit ${isBookPage ? (selectedBundle ? "Book + App Bundle" : "Book") : (isCourse ? "Nervous System Course" : "Nervous System Certification")}. In a few moments, you'll receive an email confirmation with details to access ${isBookPage ? "your content" : "the program"}. If you don't see the email, check your spam folder.`

    const fullCheckoutFormLoaded = ready && !!(clientSecret) && paymentRequestLoaded

    return (
      <div style={{display: "flex", justifyContent: "center"}}>
        <div style={{maxWidth: 500, color: "#000"}}>
          <div
            hidden={fullCheckoutFormLoaded}
            className={"loading-pulse-animation"}
            style={{opacity: (fullCheckoutFormLoaded) ? 0.0: 1.0, fontFamily: "Futura Book", textAlign: "center", marginTop: "5vh", fontSize: "min(4vh, 30px)", color: "#000000"}}
          >
            <LoadingLogo
              show={true}
              hideAnimation={true}
              responsiveSize={"min(7vw, 35px)"}
              marginTop={0}
              color={"black"}
              fontFamily={"Futura Book"}
              text={"Loading Checkout..."}
              showProgressionBar={false}
            />
          </div>
          <div style={{opacity: (fullCheckoutFormLoaded) ? 1.0 : 0.0, backgroundColor: "transparent", transition: "opacity 300ms linear"}}>
            {isBookPage ? (
              <div style={{display: "flex", alignItems: "center"}}>
                <SwitchTransition mode="out-in">
                  <CSSTransition
                    timeout={500}
                    classNames={"fade-in"}
                    unmountOnExit
                    addEndListener={(node, done) => {
                      node.addEventListener("transitionend", done, false);
                    }}
                    key={`${selectedProductPrice}`}
                  >
                    <div style={{fontFamily: "Futura Book", fontSize: "min(6vw, 25px)", color: "#777"}}>
                      {`$${(selectedProductPrice / 100.0).toFixed(2)} USD`}
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            ) : (
              <div>
                <div style={{display: "flex", justifyContent: "left"}}>
                  <div style={{fontSize: "min(6vw, 25px)", paddingRight: "0.5em", textAlign: "left", fontFamily: "Futura Book", color: "red", textDecoration: "line-through"}}>
                    {"$5,000 USD VALUE"}
                  </div>
                </div>
                <div style={{display: "flex", justifyContent: "left"}}>
                  <SwitchTransition mode="out-in">
                    <CSSTransition
                      timeout={500}
                      classNames={"fade-in"}
                      unmountOnExit
                      addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                      }}
                      key={`${selectedProductPrice}`}
                    >
                      <div style={{fontFamily: "Futura Book", fontSize: "min(6vw, 25px)", color: "#777", marginTop: 10}}>
                        {`JUST $${(selectedProductPrice / 100.0).toFixed((promotionCodeRequestState === REQUEST_SUCCESS) ? 2 : 0)} USD`}
                      </div>
                    </CSSTransition>
                  </SwitchTransition>
                </div>
              </div>
            )}
            <PromotionCodeInput
              isDisabled={isProcessingPayment || (promotionCodeRequestState === REQUEST_SUCCESS)}
              promotionCodeRequestState={promotionCodeRequestState}
              onUpdatePromotionCode={() => this.resetPromotionCodeRequestState()}
              onSubmitPromotionCode={async (promotionCode) => {
                await this.handleRetrieveClientSecret(promotionCode)
              }}
              successMessage={"5% OFF APPLIED"}
            />
            {!isNative && isBookPage && (
              <ConfirmSection
                marginBottom={"min(24px, max(3vw, 16px))"}
                label={"Add 3 month NeuroFit app membership"}
                isConfirmed={selectedBundle}
                onToggleConfirm={() => {
                  this.handleSetBookProductId(!selectedBundle, clientSecretResult)
                }}
              />
            )}
            <div style={{marginRight: "0.2em", marginBottom: "min(24px, max(3vw, 16px))", fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Light"}}>
              <span>
                {isBookPage ? `After payment, you'll receive a confirmation email with a download link (PDF + EPUB).${selectedBundle ? " To activate your app membership, sign up with your provided email address." : ""}` : "After enrollment, you'll receive an email receipt with details for immediate program access."}
              </span>
              {isBookPage && (!selectedBundle) && (
                <span>
                  <span>{" Paperback is available on "}</span>
                  <span style={{textDecoration: "underline", fontFamily: "Futura Book", textUnderlineOffset: 2}} onClick={() => recordLinkClickAndRedirect(GA_LABEL_BOOK_PURCHASE_AMAZON_CLICK, AmazonNeuroFitBookUrl)}>{"Amazon"}</span>
                  <span>{"."}</span>
                </span>
              )}
            </div>
            <form onSubmit={this.handleSubmit}>
              <SwitchTransition mode="out-in">
                <CSSTransition
                  timeout={500}
                  classNames={"fade-in"}
                  unmountOnExit
                  addEndListener={async (node, done) => {
                    node.addEventListener("transitionend", done, false);
                    await this.setUpPaymentRequestButtonElement()
                  }}
                  key={`${clientSecret}`}
                >
                  <div>
                    {!!(paymentRequest) && (
                      <div style={{marginTop: "10px"}}>
                        <PaymentRequestButtonElement options={{paymentRequest}} />
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Futura Book", fontSize: "min(4.5vw, 18px)", margin: "15px 0px", lineHeight: "min(6vw, 26px)", color: "#a5a8b0"}}>
                          <div style={{height: "1px", width: "50%", flex: "2 1 auto", backgroundColor: "#a5a8b0"}}></div>
                          <span style={{flex: "0 0 auto", padding: "0px 0.4em"}}>{"OR"}</span>
                          <div style={{height: "1px", width: "50%", flex: "2 1 auto", backgroundColor: "#a5a8b0"}}></div>
                        </div>
                      </div>
                    )}
                  </div>
                </CSSTransition>
              </SwitchTransition>
              <div style={{margin: "10px 0px"}}>
                <input
                  type="text"
                  className={"application-input application-input-checkout"}
                  placeholder={i18n.name_on_card || "Name on card"}
                  autoCapitalize="words"
                  autoCorrect="false"
                  autoComplete="cc-name"
                  spellCheck={false}
                  value={nameOnCard}
                  disabled={isProcessingPayment}
                  onChange={(e) => this.handleUpdateNameOnCard(e.target.value)}
                  onPaste={(e) => this.handleUpdateNameOnCard(e.target.value)}
                />
              </div>
              <div style={{margin: "10px 0px"}}>
                <input
                  type="text"
                  className={"application-input application-input-checkout"}
                  placeholder={i18n.name_on_card || "Email address"}
                  autoCapitalize="words"
                  autoCorrect="false"
                  autoComplete="email"
                  spellCheck={false}
                  value={emailAddress}
                  disabled={isProcessingPayment}
                  onChange={(e) => this.handleUpdateEmailAddress(e.target.value)}
                  onPaste={(e) => this.handleUpdateEmailAddress(e.target.value)}
                />
              </div>
              <div className={"application-input"} style={{height: 22, padding: "12px 8px", textRendering: "geometricPrecision"}}>
                <CardElement
                  onReady={() => this.handleOnReady()}
                  onChange={e => {
                    this.handleUpdatedCompletionStatus(e.complete)
                  }}
                  options={{
                    style: {
                      base: {
                        fontSize: "18px",
                        fontFamily: 'Futura Book, sans-serif',
                        fontSmoothing: "antialiased",
                        color: '#000000',
                        '::placeholder': {
                          color: '#777',
                        },
                        backgroundColor: "#FFFFFF"
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    }
                  }}
                />
              </div>
              <button
                type="submit"
                style={{display: "flex", alignItems: "top", justifyContent: "center",  width: "100%", fontWeight: "normal", textAlign: "center", fontFamily: "Futura Book", fontSize: "18px", lineHeight: "18px", marginTop: 10, color: (isProcessingPayment || (paymentStatus === REQUEST_SUCCESS)) ? "rgba(255, 255, 255, 0.4)" : "#ffffff", backgroundColor: "#000000", transition: "color 250ms linear", padding: "11px 15px", border: "none"}}
                disabled={!stripe || !readyForCheckout || (paymentStatus === REQUEST_SUCCESS)}
              >
                {checkoutButtonText}
              </button>
              <SwitchTransition mode="out-in">
                <CSSTransition
                  timeout={500}
                  classNames={"fade-in"}
                  unmountOnExit
                  addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                  }}
                  key={paymentStatus}
                >
                  <div>
                    {(paymentStatus === REQUEST_UNSTARTED) && (
                      <div style={{fontSize: "min(4vw, 18px)", color: "#a5a8b0", marginTop: "2vh"}}>
                        <span style={{marginRight: "0.2em"}}>{`By purchasing our ${isBookPage ? "book" : (isCourse ? "course" : "certification")}, you agree to our platform`}</span>
                        <a style={{color: "#a5a8b0", fontFamily: "Futura Book"}} href={`${subdomainUrls.baseLegalUrl}/#terms`} target="_blank" rel="noopener noreferrer nofollow">{"Terms"}</a>
                        {!isBookPage && (
                          <span>
                            <span style={{marginRight: "0.2em"}}>{", "}</span>
                            <a style={{color: "#a5a8b0", fontFamily: "Futura Book"}} href={`${subdomainUrls.baseLegalUrl}/certification/`} target="_blank" rel="noopener noreferrer nofollow">{"Certification Terms"}</a>
                            <span style={{marginRight: "0.2em"}}>{","}</span>
                          </span>
                        )}
                        <span style={{marginRight: "0.2em"}}>{" & "}</span>
                        <a style={{color: "#a5a8b0", fontFamily: "Futura Book"}} href={`${subdomainUrls.baseLegalUrl}/#privacy`} target="_blank" rel="noopener noreferrer nofollow">{"Privacy Policy"}</a>
                        <span>{". Thank you!"}</span>
                      </div>
                    )}
                    {(paymentStatus === REQUEST_ERROR) && (
                      <ProgramResultsPanel
                        backgroundColor={"#ffb4b4"}
                        boxShadow={"0px 0px 1px 1px red"}
                        marginTop={"2vh"}
                        title={"PAYMENT ERROR"}
                        description={paymentFailureMessage}
                      />
                    )}
                    {(paymentStatus === REQUEST_SUCCESS) && (
                      <div style={{marginTop: "2vh", padding: "max(3vw, 16px)", backgroundColor: "#ffffff", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "left"}}>
                          <div style={{display: "inline-block", fontFamily: "Futura Book", fontSize: "min(5vw, 22px)"}}>
                            {"PURCHASE SUCCESSFUL"}
                          </div>
                        </div>
                        <div style={{paddingTop: "max(2vw, 10px)"}}>
                          <span style={{fontFamily: "Futura Light", fontSize: "min(4vw, 18px)"}}>
                            {successMessage}
                          </span>
                          {!isNative && !isBookPage && (
                            <div style={{paddingTop: "max(2vw, 10px)", fontSize: "min(4vw, 18px)"}}>
                              <span>
                                {" To maximize program results, we also recommend downloading the "}
                              </span>
                              <a href={NeuroFitUniversalAppStoreLink} style={{color: "#000000", fontFamily: "Futura Book"}} target="_blank" rel="noopener noreferrer nofollow">
                                {"NeuroFit app."}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {(paymentStatus === REQUEST_FETCHING) && (
                      <ProgramResultsPanel
                        backgroundColor={"#FFFFFF"}
                        marginTop={"2vh"}
                        title={"PROCESSING PAYMENT"}
                        description={"Your payment is being processed securely through Stripe..."}
                      />
                    )}
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

class StripeCheckoutForm extends React.Component {

  constructor(props) {
    super(props);
    const {loadStripe} = require('@stripe/stripe-js')
    const stripePromise = loadStripe(StripePublishableKey);
    this.state = {
      stripePromise
    }
    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.
  }

  render() {
    const {stripePromise} = this.state

    const {onClickSubmitAccessCode,  onShowSuccessMessage, onApplyPromotionCode, onShowErrorMessage, showFreeAccessCode, numberOfSeats, isNative, adminAccountData, billingInterval, intervalPrice, isCourse, isBookPage, isUpdateSubscription} = this.props
    return (
      <Elements options={{fonts: NEUROFIT_FONTS}} stripe={stripePromise}>
        <ElementsConsumer>
          {({elements, stripe}) => (
            <CheckoutForm
              elements={elements}
              stripe={stripe}
              showFreeAccessCode={showFreeAccessCode}
              isCourse={isCourse}
              isBookPage={isBookPage}
              onApplyPromotionCode={onApplyPromotionCode}
              onClickSubmitAccessCode={onClickSubmitAccessCode}
              onShowSuccessMessage={onShowSuccessMessage}
              onShowErrorMessage={onShowErrorMessage}
              isNative={isNative}
              numberOfSeats={numberOfSeats}
              adminAccountData={adminAccountData}
              billingInterval={billingInterval}
              intervalPrice={intervalPrice}
            />
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
};

export default StripeCheckoutForm;