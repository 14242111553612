import React from 'react'


const CompletionCheckIcon = ({
  isComplete,
  checkboxBaseClassName,
  checkColorIncomplete,
  checkColorComplete,
  marginTop
}) => {
  const baseClassName = checkboxBaseClassName || "checkbox-plan-button"
  const checkboxClassName = `${baseClassName} ${isComplete ? baseClassName + "--active" : ""}`
  return (
    <svg
      className={checkboxClassName}
      style={{marginTop: marginTop || 0}}
      disabled
      // This element is purely decorative so
      // we hide it for screen readers
      aria-hidden="true"
      viewBox="0 0 15 11"
      fill="none"
    >
      <path
        d="M1 4.5L5 9L14 1"
        strokeWidth="2"
        stroke={isComplete ? (checkColorComplete || "#fff") : (checkColorIncomplete || "#fff")}
      />
    </svg>
  )
}


export default CompletionCheckIcon;