export const DASHBOARD_SECTION_CLIENTS = "clients"
export const DASHBOARD_SECTION_INTAKE_FORM = "intake"
export const DASHBOARD_SECTION_BALANCE_TOOL = "balance"
export const DASHBOARD_SECTION_EDIT_PROFILE = "profile"
export const DASHBOARD_SECTION_AFFILIATE = "affiliate"
export const DASHBOARD_SECTION_SETTINGS = "settings"
export const DASHBOARD_SECTION_LOGOUT = "logout"
export const DASHBOARD_SECTION_FAQ = "faq"
export const DASHBOARD_SECTION_FEATURE_REQUESTS = "feature_requests"
export const DASHBOARD_SECTION_SUPPORT = "support"
export const DASHBOARD_SECTION_LEGAL_AND_DATA = "legal"