import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  resetTrainingRegistrationPage,
  trainingRegistrationPageUpdateRequestState,
  trainingRegistrationPageTriggerRequest,
} from '../actions/trainingRegistrationPage';

import {
  toggleBodyScroll,
  i18n,
  hideLoadingScreen,
  initializeGA,
  pageViewGA,
  recordSignupFunnelEventGA,
  getAdvertisementIdFromQueryString,
  setAdvertisementReferralUserProperty,
} from '../utils'

import TrainingRegistrationPage from '../components/TrainingRegistrationPage';

import {
  REQUEST_UNSTARTED,
} from '../constants/requestStates'

import {
  COMMON_INNER_HEIGHT,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_NATIVE,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
} from '../constants/commonState'

import {
  FORM_FIELD_HIDE_INTRO_VIDEO,
} from '../constants/formFields'

import {
  GA_LABEL_LOADED_REGISTER_LIVE_TRAINING_PAGE,
} from '../constants/gaEventLabels'

class TrainingRegistrationPageContainer extends Component {
  async componentWillMount() {
    await initializeGA(true/*disableCookies*/);
    pageViewGA();
    await recordSignupFunnelEventGA(GA_LABEL_LOADED_REGISTER_LIVE_TRAINING_PAGE)
    const advertisementId = getAdvertisementIdFromQueryString()
    if (!!advertisementId) {
      await setAdvertisementReferralUserProperty(advertisementId)
    }
  }

  componentDidMount() {
    hideLoadingScreen()
  }
  componentWillUnmount() {
    this.props.dispatch(resetTrainingRegistrationPage());
  }

  render() {
    return (
      <TrainingRegistrationPage
        // State
        innerHeight={this.props.innerHeight}
        safeAreaInsets={this.props.safeAreaInsets}
        isNative={this.props.isNative}
        isIOSDevice={this.props.isIOSDevice}
        isAndroidDevice={this.props.isAndroidDevice}
        iOSAppIsAvailable={this.props.iOSAppIsAvailable}
        androidAppIsAvailable={this.props.androidAppIsAvailable}
        i18n={i18n}
        requestResults={this.props.requestResults}
        onUpdateRequestState={(requestType, body) => this.props.dispatch(trainingRegistrationPageUpdateRequestState(requestType, REQUEST_UNSTARTED, body))}
        onResetRequestState={requestType => this.props.dispatch(trainingRegistrationPageUpdateRequestState(requestType, REQUEST_UNSTARTED))}
        onTriggerRequest={requestType => this.props.dispatch(trainingRegistrationPageTriggerRequest(requestType))}
        // Actions
      />
    );
  }
}

TrainingRegistrationPageContainer.propTypes = {
  requestResults: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  innerHeight: PropTypes.number.isRequired,
  isNative: PropTypes.bool.isRequired,
  isIOSDevice: PropTypes.bool.isRequired,
  isAndroidDevice: PropTypes.bool.isRequired,
  safeAreaInsets: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  ...state.trainingRegistrationPage,
  innerHeight: state.common[COMMON_INNER_HEIGHT],
  isNative: state.common[COMMON_IS_NATIVE],
  isIOSDevice: state.common[COMMON_IS_IOS_DEVICE],
  isAndroidDevice: state.common[COMMON_IS_ANDROID_DEVICE],
  safeAreaInsets: state.common[COMMON_SAFE_AREA_INSETS],
});

export default connect(mapStateToProps)(TrainingRegistrationPageContainer);
