import React from 'react'

import {
  ProductionBaseUrl,
  DefaultAspectRatio,
  recordFunnelEventResultGA,
  initializePixelIfAvailable,
  recordPixelEventIfAvailable,
  NeuroFitUniversalAppStoreLink,
  MinDesktopWidthPixels,
} from '../../utils'

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import ScientificSourceSection from './ScientificSourceSection'
import UniversitySourcesSection from './UniversitySourcesSection'

const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const headerTextStyle = {fontSize: "min(7vw, 32px)", textTransform: "uppercase", margin: "min(9vh, 45px) 0px 0px 0px", color: "#000000", textAlign: "left", fontFamily: "Futura Book"}


class BookDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showSettingsModal: false,
      learnMoreRef: React.createRef(),
      checkoutRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isHeroImageLoaded: false,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      bookClientSecret: "",
      bundleClientSecret: "",
      selectedBundle: false
    }
  }

  handleOnClickLearnMore = (event) => {
    //.current is verification that your element has rendered
    if (this.learnMoreRef.current){
        this.learnMoreRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  handleOnScrollToCheckout = () => {
    if (this.checkoutRef.current) {
        this.checkoutRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(${255 * (1 - topPageScrollProgress)}, ${255 * (1 - topPageScrollProgress)}, ${255 * (1 - topPageScrollProgress)}, 1.0)`
      const logoLineColor = `rgba(${255 * topPageScrollProgress + 237 * (1 - topPageScrollProgress)}, ${255 * topPageScrollProgress  + 238 * (1 - topPageScrollProgress)}, ${255 * topPageScrollProgress + 242 * (1 - topPageScrollProgress)}, 1.0)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`

      const updatedState = {headerBackgroundColor, heroOverlayColor, dropShadowColor, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, logoOutlineColor, logoLineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    } else {
      const updatedState = {headerBackgroundColor: "transparent", heroOverlayColor: "transparent", heroBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#FFFFFF", logoLineColor: "#EDEEF2", headerBackgroundBlur: "none", topPageScrollProgress: 0, headerBorderColor: "transparent", videoOpacity: 1}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    }
  }

  async componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isNative,
      isNasmPage,
    } = this.props

    const HabitSources = [
      {
        is_header: true,
        title: "BALANCE TRAINING"
      },
      {
        title: "Brief structured respiration practices enhance mood and reduce physiological arousal",
        url: "https://www.cell.com/cell-reports-medicine/fulltext/S2666-3791(22)00474-8"
      },
      {
        title: "The Effects of Acute Exercise on Mood, Cognition, Neurophysiology, and Neurochemical Pathways",
        url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5928534/"
      },
      {
        is_header: true,
        title: "EMBODY HABITS",
      },
      {
        title: "Regular exercise is associated with emotional resilience",
        url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4013452/"
      },
      {
        title: "The importance of having fun + Daily Play",
        url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8158911/"
      },
      {
        title: "Mind-body practices that elicit the relaxation response reduce blood pressure",
        url: "https://pubmed.ncbi.nlm.nih.gov/29616846/"
      },
      {
        title: "The Role of Sleep Hygiene in Promoting Public Health",
        url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4400203/"
      },
      {
        is_header: true,
        title: "PREVENT HABITS",
      },
      {
        title: "The Link Between Stress and Alcohol",
        url: "https://pubs.niaaa.nih.gov/publications/AA85/AA85.htm"
      },
      {
        title: "Physiological dynamics of stress contagion",
        url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5522461/"
      },
      {
        title: "Neuroendocrine profiles associated with energy intake, sleep, and stress in the night eating syndrome",
        url: "https://pubmed.ncbi.nlm.nih.gov/16131578/"
      },
      {
        title: "Taking a One-Week Break from Social Media Improves Well-Being, Depression, and Anxiety: A Randomized Controlled Trial",
        url: "https://www.liebertpub.com/doi/10.1089/cyber.2021.0324"
      },
      {
        is_header: true,
        title: "NOURISH HABITS",
      },
      {
        title: "The relationship between sleep quality, stress, and academic performance",
        url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6984036/"
      },
      {
        title: "Sleep-time physiological recovery is associated with eating habits",
        url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8237494/"
      },
      {
        title: "Hydration State Controls Stress Responsiveness and Social Behavior",
        url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3086063/"
      },
      {
        title: "More than a feeling: A unified view of stress measurement for population science",
        url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6345505/"
      },
      {
        is_header: true,
        title: "APP DESIGN",
      },
      {
        title: "Developing Interoception Improves Emotional Regulation",
        url: "https://www.sciencedirect.com/science/article/abs/pii/S0165032718323723"
      },
      {
        title: "Heart Rate Variability as an Index of Resilience",
        url: "https://pubmed.ncbi.nlm.nih.gov/31642481/"
      },
      {
        title: "Requiring members to take ownership of their well-being",
        url: "https://www.researchgate.net/publication/360551660_Impact_of_modifiable_healthy_lifestyle_adoption_on_lifetime_gain_from_middle_to_older_age"
      },
      {
        title: "Limiting information-induced dopamine release to facilitate habit building",
        url: "https://www.pnas.org/doi/10.1073/pnas.1820145116"
      },
      {
        title: "Using push notifications to optimize client results",
        url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7055755/"
      },
      {
        title: "Measuring Stress Levels Via Neuroceptive & Interoceptive Signals",
        url: "https://psycnet.apa.org/record/2022-82545-001"
      }
    ]


    const {show, bookClientSecret, bundleClientSecret, selectedBundle, learnMoreRef, checkoutRef, showSettingsModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, showIframe, isHeroImageLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")

    return (
      <div 
        id={"book-page-container"}
        className={"disable-scrollbars"}
        ref={elem => this.scrollingContainerRef = elem}
        style={containerStyle}
      >
        <div style={{paddingTop: "calc(60px + 5vh)", textAlign: "left", width: "100vw", borderTop: "2px #000000 solid", backgroundColor: "#FFFFFF"}} ref={elem => this.learnMoreRef = elem}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura Book", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                {"RESEARCH SOURCES"}
              </div>
              <div style={{marginTop: "4vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                {"NeuroFit stands on the shoulders of giants. The core components of our nervous system approach have been validated by neuroscience research from universities such as:"}
              </div>
            </div>
          </div>
          <UniversitySourcesSection
            isMobile={isMobile}
          />
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{marginTop: "calc(30px + 5vh)", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                <span>{"Sources for our recommended habits and practices are listed below."}</span>
              </div>
              {HabitSources.map(source => (
                (source.is_header ? (
                  <div style={headerTextStyle}>
                    {source.title}
                  </div>
                ) : (
                  <ScientificSourceSection
                    titleText={source.title}
                    externalSourceUrl={source.url}
                    marginTop={"2vh"}
                  />
                ))
              ))}
            </div>
          </div>
        </div>
        <div style={{textAlign: "left", width: "100vw", paddingTop: "10vh", backgroundColor: "#FFFFFF"}}>
          <div style={{padding: 15, paddingBottom: 15 + safeAreaInsets.bottom, backgroundColor: "#FFFFFFF", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura Book", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
            <div style={{display: "inline-block"}}>
              <span style={{color: "#c1c4d2"}}>
                {"© NEUROFIT 2023"}
              </span>
              <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                {"•"}
              </span>
              <span style={{color: "#c1c4d2"}}>
                {"ALL RIGHTS RESERVED"}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BookDetails