import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import loginPage from './reducers/loginPage';
import loggedInPage from './reducers/loggedInPage';
import quizPage from './reducers/quizPage'
import workshopsPage from './reducers/workshopsPage'
import certificationVideoPage from './reducers/certificationVideoPage'
import trainingRegistrationPage from './reducers/trainingRegistrationPage'
import common from './reducers/common';

import dashboardLoggedInPage from './reducers/dashboardLoggedInPage';

export default combineReducers({
  common,
  loginPage,
  loggedInPage,
  quizPage,
  workshopsPage,
  certificationVideoPage,
  trainingRegistrationPage,
  dashboardLoggedInPage,
  router: routerReducer
});
