import React, {Suspense} from 'react'

import PendingResultsPanel from './PendingResultsPanel'

import LoadingLogo from './LoadingLogo'


import loadable from '@loadable/component'
const ChartistGraph = loadable(() => import('react-chartist'))
// const chartistPluginAxisTitle = loadable(() => import('chartist-plugin-axistitle'))
// import ChartistGraph from 'react-chartist';
// import chartistPluginAxisTitle from 'chartist-plugin-axistitle'

const LineGraph = ({
  dataIsAvailable,
  title,
  data,
  labelInterpolationFnc,
  highYValue,
  pendingTitle,
  pendingText,
  xAxisTitle,
  hideLogo
}) => (
  <div>
    {dataIsAvailable ? (
      <div>
        <div style={{backgroundColor: "#FFFFFF", padding: "max(3vw, 16px)", marginTop: "max(3vw, 16px)", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div style={{display: "inline-block", fontFamily: "Futura Book", fontSize: "min(5vw, 22px)", lineHeight: "min(5vw, 22px)"}}>
              {title}
            </div>
          </div>
          <Suspense fallback={
            <div className={"article-loading-background-animation"} style={{height: 190, width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
              <LoadingLogo
                show={true}
                hideTitle={true}
                hideAnimation={true}
                responsiveSize={"min(8vw, 40px)"}
                marginTop={0}
                marginRight={"0px"}
                color={"#CFD2DD"}
                lineColor={"#EDEEF2"}
                fontFamily={"Futura Book"}
                showProgressionBar={false}
              />
            </div>
          }>
            <div>
              <ChartistGraph
                data={data}
                options={{
                  height: '190px',
                  fullWidth: true,
                  chartPadding: {
                    top: 20, 
                    bottom: 5,
                    left: 0,
                    right: 20
                  },
                  labelDirection: "explode",
                  axisY: {
                    labelInterpolationFnc,
                    low: 0,
                    high: highYValue,
                    referenceValue: 20,
                    onlyInteger: true,
                    offset: 50
                  },
                  axisX: {
                    // showGrid: false,
                    name: xAxisTitle,
                    showLabel: !!(xAxisTitle),
                    offset: (!!(xAxisTitle)) ? 10 : 0,
                  }
                }}
                type="Line"
              />
              {!hideLogo && (
                <div style={{display: "flex", justifyContent: "right", alignItems: "center", marginTop: -40, paddingRight:30}}>
                  <LoadingLogo
                    show={true}
                    hideAnimation={true}
                    responsiveSize={"min(4vw, 18px)"}
                    marginTop={0}
                    color={"#D5D6D9"}
                    fontFamily={"Futura Book"}
                    text={"NeuroFit"}
                    showProgressionBar={false}
                  />
                </div>
              )}
            </div>
          </Suspense>
          {!!(xAxisTitle) && (
            <div style={{textAlign: "center", fontFamily: "Futura Book", fontSize: "min(4vw, 18px)"}}>
              {xAxisTitle}
            </div>
          )}
        </div>
      </div>
    ) : (
      <PendingResultsPanel
        title={pendingTitle}
        pendingText={pendingText}
      />
    )}
  </div>
)

export default LineGraph;