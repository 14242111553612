import React from 'react'

const UniversitySourcesSection = ({
  isMobile,
  hideSecondRow,
  lazyLoadImages
}) => {
  const loadingStyle = lazyLoadImages ? "lazy" : "eager"
  return (
    isMobile ? (
      <div style={{textAlign: "center", opacity: 0.7}}>
        <div style={{display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center", marginTop: "6vh"}}>
          <img src="/images/stanford-school-of-medicine.webp?v=3" loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="Stanford School of Medicine Logo" />
          <img src="/images/harvard-medical-school.webp?v=3" loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.9}} alt="Harvard Medical School Logo" />
        </div>
        <div style={{display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center", marginTop: "4vh"}}>
          <img src="/images/upenn.webp?v=3" loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="University of Pennsylvania Logo" />
          <img src="/images/uchicago.webp?v=4" loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="University of Chicago Logo" />
        </div>
        {!hideSecondRow && (
          <div>
            <div style={{display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center", marginTop: "4vh"}}>
              <img src="/images/ucla.webp?v=4" loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="UCLA Logo" />
              <img src="/images/uc-berkeley.webp?v=3" loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="UC Berkeley Logo" />
            </div>
            <div style={{display: "flex", width: "100%", justifyContent: "space-around", alignItems: "center", marginTop: "4vh"}}>
              <img src="/images/northwestern-university.webp?v=3" loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="Northwestern University Logo" />
              <img src="/images/carnegie-mellon-university.webp?v=3" loading={loadingStyle} style={{width: 150, maxWidth: "40%", opacity: 0.6}} alt="Carnegie Mellon Logo" />
            </div>
          </div>
        )}
      </div>
    ) : (
      <div style={{opacity: 0.7}}>
        <div style={{display: "flex", alignItems: "center", marginTop: "6vh", justifyContent: "space-around"}}>
          <div />
          <img src="/images/stanford-school-of-medicine.webp?v=3" loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="Stanford School of Medicine Logo" />
          <img src="/images/harvard-medical-school.webp?v=3" loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.9}} alt="Harvard Medical School Logo" />
          <img src="/images/upenn.webp?v=3" loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="University of Pennsylvania Logo" />
          <img src="/images/uchicago.webp?v=4" loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="University of Chicago Logo" />
          <div />
        </div>
        {!hideSecondRow && (
          <div style={{display: "flex", alignItems: "center", marginTop: "4vh", justifyContent: "space-around"}}>
            <div />
            <img src="/images/ucla.webp?v=4" loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="UCLA Logo" />
            <img src="/images/uc-berkeley.webp?v=3" loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="UC Berkeley Logo" />
            <img src="/images/carnegie-mellon-university.webp?v=3" loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="Carnegie Mellon Logo" />
            <img src="/images/northwestern-university.webp?v=3" loading={loadingStyle} style={{width: 150, maxWidth: "15%", opacity: 0.6}} alt="Northwestern University Logo" />
            <div />
          </div>
        )}
      </div>
    )
  )
}

export default UniversitySourcesSection;