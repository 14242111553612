import React from 'react';

import InlineVideoContainer from './InlineVideoContainer'

import {
  ProductionBaseUrl,
} from '../../utils'

const VideoTestimonialPanel = ({
  reviewerName,
  occupation,
  countryFlag,
  dateString,
  videoAspectRatio,
  videoSourcePath,
  videoPosterSourcePath,
  innerWidth
}) => {

  const containerWidthPixels = Math.min(innerWidth * .8, 300)
  const containerHeightPixels = containerWidthPixels * videoAspectRatio

  return (
    <div key={reviewerName} style={{minWidth: "min(80vw, 300px)", display: "inline-block", padding: "0px min(4%, 30px)", scrollSnapAlign: "start"}}>
      <div style={{backgroundColor: "#EDEEF2", boxShadow: "0px 0px 1.5px 1px #555", fontSize: "min(6vw, 25px)", position: "relative", width: containerWidthPixels, height: containerHeightPixels}}>
        <div style={{maxWidth: 300, position: "absolute"}}>
          <InlineVideoContainer
            videoPath={videoSourcePath}
            videoPosterPath={videoPosterSourcePath}
            baseContentUrl={ProductionBaseUrl}
            widthPixels={containerWidthPixels}
            aspectRatio={videoAspectRatio}
            isVideoVisible
            disableAutoPlay
            canManuallyStartVideo
          />
        </div>
        <div style={{position: "absolute", bottom: 0, width: containerWidthPixels - 30, padding: 15, backgroundColor: "rgba(0, 0, 0, 0.4)", backdropFilter: "blur(10px)", WebkitBackdropFilter: "blur(10px)", zIndex: 5, transform: "translateZ(150px)"}}>
          <div style={{display: "flex", alignItems: "top", justifyContent: "space-between"}}>
            <div style={{display: "inline-block", color: "#FFFFFF", textTransform: "uppercase", fontFamily: "Futura Book", fontSize: "min(6vw, 26px)", lineHeight: "min(3.5vw, 18px)"}}>
              {reviewerName}
            </div>
          </div>
          <div style={{display: "flex", alignItems: "center", marginTop: 15}}>
            <div style={{display: "inline-block", color: "#FFFFFF", textTransform: "uppercase", fontFamily: "Futura Book", fontSize: "min(4vw, 16px)", lineHeight: "min(4vw, 16px)"}}>
              {occupation}
            </div>
            <div style={{display: "inline-block", color: "#FFFFFF", fontSize: "min(4vw, 16px)", fontSize: "min(1.5vw, 6px)", lineHeight: "min(4vw, 16px)", fontFamily: "Futura Light", margin: "0px 0.8em"}}>
              {"•"}
            </div>
            <div style={{display: "inline-block", fontFamily: "Futura Light", fontSize: "min(4.5vw, 20px)", lineHeight: "min(4.5vw, 20px)"}}>
              {countryFlag}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoTestimonialPanel
