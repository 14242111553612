import {
  DASHBOARD_LOGGED_IN_PAGE_UPDATE_FORM_FIELD,
  DASHBOARD_LOGGED_IN_PAGE_UPDATE_REQUEST_STATE,
  DASHBOARD_LOGGED_IN_PAGE_UPDATE_PAGE_STATE,
  DASHBOARD_LOGGED_IN_PAGE_RESET_STATE,
} from '../constants/actionTypes'

import {
  REQ_DASHBOARD_LOAD_AFFILIATE_PROFILE,
  REQ_DASHBOARD_CREATE_PAYOUTS_ONBOARDING_URL,
  REQ_DASHBOARD_CREATE_PAYOUTS_LOGIN_URL,
  REQ_DASHBOARD_UPDATE_AFFILIATE_CODE,
  REQ_DASHBOARD_LOGOUT_USER,
} from '../constants/requestTypes'

import {
  FORM_FIELD_AFFILIATE_CODE,
} from '../constants/formFields'

import {
  DASHBOARD_PAGE_STATE_SELECTED_MENU_SECTION
} from '../constants/pageState'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS
} from '../constants/requestStates'

import {
  DASHBOARD_SECTION_CLIENTS,
} from '../constants/dashboardSections'

import { combineReducers } from 'redux';

const defaultFormFields = {
  [FORM_FIELD_AFFILIATE_CODE]: "",
}
function formFields(
  state = defaultFormFields, action) {
  switch (action.type) {
    case DASHBOARD_LOGGED_IN_PAGE_UPDATE_FORM_FIELD:
      return {...state, ...action.payload};
    case DASHBOARD_LOGGED_IN_PAGE_RESET_STATE:
      return defaultFormFields;
    default:
      return state;
  }
}


const defaultPageState = {
  [DASHBOARD_PAGE_STATE_SELECTED_MENU_SECTION]: DASHBOARD_SECTION_CLIENTS
}
function pageState(
  state = defaultPageState, action) {
  switch (action.type) {
    case DASHBOARD_LOGGED_IN_PAGE_UPDATE_PAGE_STATE:
      return {...state, ...action.payload}
    case DASHBOARD_LOGGED_IN_PAGE_RESET_STATE:
      return defaultPageState;
    default:
      return state;
  }
}

const defaultRequestResults = {
  [REQ_DASHBOARD_LOAD_AFFILIATE_PROFILE]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_CREATE_PAYOUTS_ONBOARDING_URL]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_CREATE_PAYOUTS_LOGIN_URL]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_UPDATE_AFFILIATE_CODE]: {state: REQUEST_UNSTARTED},
  [REQ_DASHBOARD_LOGOUT_USER]: {state: REQUEST_UNSTARTED},
}
function requestResults(
  state = defaultRequestResults, action) {
  switch (action.type) {
    case DASHBOARD_LOGGED_IN_PAGE_UPDATE_REQUEST_STATE: {
      return {...state, [action.key]: {...action.payload}};
    }
    case DASHBOARD_LOGGED_IN_PAGE_RESET_STATE:
      return defaultRequestResults;
    default:
      return state;
  }
}

export default combineReducers({
  formFields,
  pageState,
  requestResults,
});
