import React from 'react';

import {
  SolidInfoSvg,
} from '../../constants/svgs'

const getFillColor = (value, baselineAvailable, valueBaseline, valueBaselineStdDev, isIncreasingStatistic) => {
  if (!baselineAvailable) {
    return "#000000"
  } else {
    return "#000000"
    // const baselineDeviation = (value - valueBaseline)
    // if (Math.abs(baselineDeviation) < (1.5 * valueBaselineStdDev)) {
    //   return "#000000"
    // } else if (baselineDeviation > (1.5 * valueBaselineStdDev)) {
    //   return isIncreasingStatistic ? "#93E29A" : "#FF7CBC"
    // } else {
    //   return isIncreasingStatistic ? "#FF7CBC" : "#93E29A"
    // }
  }
}


const ProgramResultsPanel = ({
  title,
  backgroundColor,
  boxShadow,
  marginTop,
  description
}) => {


  return (
    <div style={{marginTop: marginTop || "min(6vw, 25px)", padding: "max(3vw, 16px)", backgroundColor: backgroundColor || "#ffffff", boxShadow: boxShadow || "0px 1px 1.5px 0.5px #dddddd", fontSize: "min(6vw, 25px)"}}>
      <div style={{display: "flex", alignItems: "center", justifyContent: "left"}}>
        <div style={{display: "inline-block", fontFamily: "Futura Book", fontSize: "min(5vw, 22px)"}}>
          {title}
        </div>
      </div>
      {(!!description) && (
        <div style={{fontFamily: "Futura Light", fontSize: "min(4vw, 18px)", marginTop: "max(1vw, 6px)"}}>
          {description}
        </div>
      )}
    </div>
  )
}

export default ProgramResultsPanel