import React from 'react'

import CompletionCheckIcon from '../common/CompletionCheckIcon'

const MembershipFeatureDescription = ({
  text,
  marginTop,
  checkMarginTop,
  fontSize,
  fontFamily
}) => (
  <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: marginTop || 10}}>
    <CompletionCheckIcon
      isComplete
      marginTop={checkMarginTop || 0}
      checkboxBaseClassName={"membership-plan-button"}
    />
    <div style={{marginLeft: "0.5em", fontSize: fontSize || "min(4vw, 16px)", fontFamily: fontFamily || "Futura Book"}}>
      {text}
    </div>
  </div>
)

export default MembershipFeatureDescription