import React from 'react'

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import LoadingLogo from './LoadingLogo'
import NeuroFitProgressRing from './NeuroFitProgressRing'
import AutoPlayInlineVideoContainer from './AutoPlayInlineVideoContainer'

import {
  formatContentDuration,
  convertToArticleDate,
  getPublicArticleUrl,
} from '../../utils'

const tagsStyle = {display: "flex", justifyContent: "left", alignItems: "center", flexWrap: "wrap", flexDirection: "row"}
const tagStyle = {fontFamily: "Futura Book", fontSize: 12, padding: "1px 1px", color: "#eeeeee", opacity: 0.8, display: "inline-block", margin: "4px 4px 4px 0px"}
const tagSeparatorStyle = {color: "#cccccc", opacity: 1.0}
const tagCategoryNameStyle = {opacity: 0.8, height: 22, width: 22, color: "#cccccc", margin: 5}

const contentTitleTextStyle = {
  display: '-webkit-box',
  color: "#000000",
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontFamily: "Futura Medium",
  fontSize: "min(3.5vw, 16px)",
  textOverflow: "ellipsis"
};

const contentDescriptionTextStyle = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontFamily: "Futura Light",
  color: "#000000",
  fontSize: "min(4.5vw, 18px)",
  textAlign: "left"
}

class StaticArticleRecommendation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isThumbnailLoaded: false
    }
  }

  render() {
    const {
      articleData,
    } = this.props

    const {isThumbnailLoaded} = this.state

    return (
      <div
        style={{marginBottom: "3vh", color: "white", fontFamily: "Futura Book", border: "none", width: "min(100%, 500px)", borderRadius: 0, height: 90, backgroundColor: "#FFFFFF", boxShadow: "0px 0px 1.5px 1px #999", overflow: "visible", display: "block", padding: 0, border: "none"}}
      >
        <a href={getPublicArticleUrl(articleData.story_key)} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", padding: 0, backgroundColor: "transparent", fontFamily: "Futura Book", textAlign: "left", overflow: "hidden", width: "100%", height: 90, border: "none"}}>
          <div style={{display: "flex", alignItems: "top", justifyContent: "left"}}>
            <div className={(isThumbnailLoaded) ? "article-loaded-background" : "article-loading-background-animation"} style={{height: 90, minWidth: 90, width: 90, backgroundColor: (isThumbnailLoaded) ? "#000000" : "transparent", transition: "background-color 250ms linear"}}>
              <img onLoad={() => {this.setState({isThumbnailLoaded: true})}} style={{width: 90, height: 90, opacity: (isThumbnailLoaded ? 1.0 : 0.0), pointerEvents: "none", backdropFilter: isThumbnailLoaded ? "blur(10px)" : "none", WebkitBackdropFilter: isThumbnailLoaded ? "blur(10px)" : "none", transition: "all 250ms linear", transitionProperty: "opacity, -webkit-backdrop-filter, backdrop-filter"}} src={articleData.thumbnail_image_url} alt={articleData.title} />
            </div>
            <div style={{padding: "min(2.5vw, 10px)", width: "100%"}}>
              <div className={"article-loaded-background"} style={{backgroundColor: "transparent", lineHeight: "min(4vw, 17px)", width: "100%", minHeight: "min(8vw, 34px)", maxHeight: "min(12vw, 50px)", ...contentTitleTextStyle}}>
                {articleData.title.toUpperCase()}
              </div>
              <div className={"article-loaded-background"} style={{backgroundColor: "transparent", marginRight: "min(2vw, 8px)", display: "flex", alignItems: "center", justifyContent: "left", width: "100%", lineHeight: "min(3vw, 12px)", height: "min(3vw, 12px)",  marginTop: "min(2vw, 10px)"}}>
                <div style={{display: "inline-block", fontSize: "min(3vw, 12px)", color: "black"}}>
                  {`${articleData.read_duration_minutes} MIN. READ`}
                </div>
                <div style={{display: "inline-block", margin: "0px 0.5em", fontSize: "min(2vw, 8px)"}}>
                  {"•"}
                </div>
                <div style={{display: "inline-block", fontSize: "min(3vw, 12px)", color : "black"}}>
                  {`${convertToArticleDate(articleData.last_updated_at_unix).toUpperCase()}`}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    )
  }
}

export default StaticArticleRecommendation