import React from 'react'

import {
  ProductionBaseUrl,
  DefaultAspectRatio,
  recordFunnelEventGA,
  initializePixelIfAvailable,
  recordPixelEventIfAvailable,
  NeuroFitUniversalAppStoreLink,
  MinDesktopWidthPixels,
  subdomainUrls,
} from '../../utils'

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './LoadingLogo'

import agent from '../../agent'


import {
  GA_LABEL_CLICKED_REGISTER_FOR_LIVE_TRAINING,
} from '../../constants/gaEventLabels'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
} from '../../constants/requestStates'


const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class TrainingRegistrationDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showSettingsModal: false,
      hasScrolled: true,
      signUpRef: React.createRef(),
      learnMoreRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      bottomPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isHeroImageLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      clientSecret: "",
      firstName: "",
      emailAddress: ""
    }
  }

  handleOnClickLearnMore = (event) => {
    //.current is verification that your element has rendered
    if (this.learnMoreRef.current){
        this.learnMoreRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  handleOnScrollToCheckout = () => {
    if (this.checkoutRef.current) {
        this.checkoutRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  adjustBgPositionById = (id, scrollTop, innerHeight, xvalue="center", bgImage=undefined) => {
    let target = document.getElementById(id)

    // let factor = this.props.isIOSDevice ? 0.0 : 0.0 //0.2
    // let yvalue = (target.offsetTop - scrollTop) * factor
    // let yvalueSign = `${(yvalue > 0 ? "-" : "")}`

    if (!!(bgImage) && !!(target)) {
      target.style.backgroundImage = bgImage
    }

    // target.style.backgroundPosition = `${xvalue} ${yvalue}px`
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const scrollTop = e.target.scrollTop

    this.adjustBgPositionById("lp-bg-image-container-loren", scrollTop, innerHeight, "left", "url(/images/loren-coach.webp), linear-gradient(0deg, #616161 0%, #919191 33%, #C1C1C1 67%, #F1F1F1 100%)")

    const coachDiv = document.getElementById("meet-your-trainer")

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const bottomPageScrollProgress = coachDiv && Math.min(Math.max(1.5 * ((e.target.scrollTop - (e.target.scrollHeight - coachDiv.clientHeight)) / innerHeight), 0.0), 0.6)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(${237 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${238 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${242 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, 1.0)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`
      const hasScrolled = true

      const updatedState = {headerBackgroundColor, hasScrolled, heroOverlayColor, dropShadowColor, heroBackdropFilter, topPageScrollProgress, bottomPageScrollProgress, headerBackgroundBlur, logoOutlineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    } else {
      const updatedState = {headerBackgroundColor: "transparent", hasScrolled: true, heroOverlayColor: "transparent", heroBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#EDEEF2", headerBackgroundBlur: "none", topPageScrollProgress: 0, bottomPageScrollProgress: 0, headerBorderColor: "transparent", videoOpacity: 1}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    }
  }

  async componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)

    await initializePixelIfAvailable()
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isNative,
      isNasmPage,
      signUpForTrainingRequestResult,
      onSignUpForTraining,
    } = this.props


    const {show, clientSecret, firstName, emailAddress, hasScrolled, learnMoreRef, showSettingsModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, bottomPageScrollProgress, showIframe, isHeroImageLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")
    const coachTextShadow = range(4).map(() => (`0px 0px 5px rgba(0, 0, 0, 0.16)`)).join(",")

    const signUpInProgress = signUpForTrainingRequestResult.state === REQUEST_FETCHING

    const registerButtonDisabled = (!firstName) || (!emailAddress) || signUpInProgress

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const registerButtonText = signUpInProgress ? "REGISTERING..." : ((signUpForTrainingRequestResult.state === REQUEST_SUCCESS) ? "REGISTRATION COMPLETE." : "REGISTER FOR TRAINING")

    return (
      <div 
        id={"certification-page-container"}
        className={"disable-scrollbars"}
        ref={elem => this.scrollingContainerRef = elem}
        style={containerStyle}
      >
        {(topPageScrollProgress === 1) && (
          <div id="lp-bg-image-container-loren" style={{position: "fixed", top: 0, width: innerWidth, height: "100%", zIndex: -1}}>
            <div style={{width: "100%", height: "100%", backgroundColor: `rgba(0, 0, 0, ${bottomPageScrollProgress})`}}>
            </div>
          </div>
        )}
        <div style={{textAlign: "left", width: "100vw", minHeight: innerHeight, backgroundColor: "#EDEEF2"}}>
          <div style={{display: "flex", minHeight: innerHeight, height: "100%", width: "100%", flexDirection: "column", justifyContent: "space-between"}} ref={elem => this.signUpRef = elem}>
            <div>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: 60, pointerEvents: "none"}}>
                <div style={{width: "85%", maxWidth: 600}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textShadow, textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", marginTop: `calc(4vh + ${safeAreaInsets.top}px)`, textTransform: "uppercase"}}>
                    {`Become An Expert Nervous System Facilitator -- Fast.`}
                  </div>
                </div>
              </div>
            </div>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", pointerEvents: "none"}}>
              <div style={{width: "85%", maxWidth: 600}}>
                <div style={{display: "inline-block", fontSize: "min(6vw, 26px)", textShadow, textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", marginTop: `2vh`}}>
                  {`Hosted by Loren Hogue: NeuroFit CEO + Master Coach. Sunday, Jan 29, 4-5PM PST.`}
                </div>
              </div>
            </div>
            <div>
              <div style={{color: "black", width: "100%", display: "flex", marginTop: `2vh`, justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 600}}>
                  <div>
                    <input
                      type="text"
                      value={firstName}
                      disabled={signUpInProgress}
                      autoCapitalize="true"
                      autoCorrect="false"
                      autoComplete="given-name"
                      spellCheck={false}
                      onChange={(e) => {
                        this.setState({firstName: e.target.value})
                      }}
                      onPaste={(e) => {
                        this.setState({firstName: e.target.value})
                      }}
                      onKeyDown={(e) => {
                        if(e.key === 'Enter' && (!registerButtonDisabled)) {
                          recordPixelEventIfAvailable("Lead")
                          recordFunnelEventGA(GA_LABEL_CLICKED_REGISTER_FOR_LIVE_TRAINING)
                          onSignUpForTraining({first_name: firstName, email_address: emailAddress})
                        }
                      }}
                      placeholder={"First Name"}
                      className={"application-input"}
                    />
                  </div>
                  <div style={{marginTop: "min(2vh, 10px)"}}>
                    <input
                      type="text"
                      value={emailAddress}
                      disabled={signUpInProgress}
                      autoCapitalize="none"
                      autoCorrect="false"
                      autoComplete="email"
                      spellCheck={false}
                      onChange={(e) => {
                        this.setState({emailAddress: e.target.value.toLowerCase()})
                      }}
                      onPaste={(e) => {
                        this.setState({emailAddress: e.target.value.toLowerCase()})
                      }}
                      onKeyDown={(e) => {
                        if(e.key === 'Enter' && (!registerButtonDisabled)) {
                          recordPixelEventIfAvailable("Lead")
                          recordFunnelEventGA(GA_LABEL_CLICKED_REGISTER_FOR_LIVE_TRAINING)
                          onSignUpForTraining({first_name: firstName, email_address: emailAddress})
                        }
                      }}
                      placeholder={"Email Address"}
                      className={"application-input"}
                    />
                  </div>
                  <button
                    style={{display: "block", width: "100%", fontFamily: "Futura Book", fontSize: "min(6vw, 25px)", lineHeight: "min(5.65vw, 25px)", marginTop: "min(2vh, 10px)", color: "#ffffff", backgroundColor: "#000000", transition: "color 250ms linear", padding: "15px 18px", border: "none"}}
                    disabled={registerButtonDisabled}
                    onClick={() => {
                      recordPixelEventIfAvailable("Lead")
                      recordFunnelEventGA(GA_LABEL_CLICKED_REGISTER_FOR_LIVE_TRAINING)
                      onSignUpForTraining({first_name: firstName, email_address: emailAddress})
                    }}
                  >
                    {registerButtonText}
                  </button>
                  <div style={{fontSize: "min(4vw, 18px)", color: "#a5a8b0", marginTop: "min(2vh, 10px)"}}>
                    <span>{"By signing up for our live training, you agree to our "}</span>
                    <a style={{color: "#a5a8b0", fontFamily: "Futura Book"}} href={`${subdomainUrls.baseLegalUrl}/#terms`} target="_blank" rel="noopener noreferrer nofollow">{"Terms"}</a>
                    <span style={{marginRight: "0.2em"}}>{" and "}</span>
                    <a style={{color: "#a5a8b0", fontFamily: "Futura Book"}} href={`${subdomainUrls.baseLegalUrl}/#privacy`} target="_blank" rel="noopener noreferrer nofollow">{"Privacy Policy"}</a>
                    <span>{". Thank you!"}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginBottom: "3vh", pointerEvents: "none"}}>
              <div style={{width: "85%", maxWidth: 600, pointerEvents: "none"}}>
                <div style={{display: "flex", justifyContent: "center", marginTop: "3vh", pointerEvents: "none"}}>
                  <svg onClick={() => this.learnMoreRef.scrollIntoView({behavior: "smooth"})} style={{pointerEvents: "all", cursor: "pointer", height: "min(12vw, 70px)", width: "min(12vw, 70px)"}} className="loading-pulse-animation-down-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" version="1.1" x="0px" y="0px"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g fill="#000000"><path d="M20,27.4142136 L32.7071068,14.7071068 L31.2928932,13.2928932 L20,24.5799999 L8.70710678,13.2928932 L7.29289322,14.7071068 L20,27.4142136 Z"></path></g></g></svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{padding: "calc(60px + 5vh) 0px", textAlign: "left", width: "100vw", borderTop: "2px #000000 solid", backgroundColor: "#FFFFFF"}} ref={elem => this.learnMoreRef = elem}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 600}}>
              <div>
                <div style={{fontFamily: "Futura Book", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                  {"UPGRADE YOUR BUSINESS WITH NERVOUS SYSTEM FITNESS"}
                </div>
                <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh 0px"}}>
                  <img src="/images/certification-hero-dash.webp" style={{width: "100%", maxWidth: 600}} alt="A 3-Week Nervous System Certification for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth." />
                </div>
                <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                  {`Learn how to add nervous system fitness to your practice to accelerate both personal and professional growth.`}
                </div>
                <div style={{display: "flex", justifyContent: "space-around", margin: "3vh 0px", alignItems: "center", width: "100%"}}>
                  <div style={{width: "40%", maxWidth: 200}}>
                    <img src="/images/nasm-approved.webp" style={{width: "100%"}} alt="NASM Approved Nervous System Fitness Course For Fitness Trainers and Gym Owners" />
                  </div>
                  <div style={{width: "40%", maxWidth: 200}}>
                    <img src="/images/afaa-approved.webp" style={{width: "100%"}} alt="AFAA Approved Nervous System Fitness Course For Fitness Trainers and Gym Owners" />
                  </div>
                </div>
                <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                  {"WHAT YOU'LL LEARN:"}
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"The 4 Truths for superior results as a practitioner"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"The 6 nervous system states you need to know about"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"How nervous system dysregulation impacts client results"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"How to improve client results, relationships, and retention with neurological fitness, and"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"How to grow your business amid widespread chronic stress & burnout"}
                  </div>
                </div>
              </div>
              <div style={{display: "flex", justifyContent: "center", marginTop: "2vh"}}>
                <button
                  style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura Book", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                  onClick={() => {
                    this.signUpRef.scrollIntoView({behavior: "smooth"})
                  }}
                >
                  {"REGISTER FOR TRAINING"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{backgroundColor: "#000000", display: "flex", justifyContent: "center"}}>
          <img style={{width: "70%", maxWidth: 400, margin: "5vh 0px"}} src="/images/well-and-good-laurel-white.webp" />
        </div>
        <div style={{textAlign: "left", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 600}}>
              <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", textTransform: "uppercase"}}>
                {"AS FEATURED IN"}
              </div>
            </div>
          </div>
          {hasScrolled && (
            isMobile ? (
              <div style={{textAlign: "center", paddingTop: "calc(2vh)", opacity: 0.4}}>
                <div style={{display: "flex", justifyContent: "center"}}>
                  <img src="/images/daily-mail-logo.webp" style={{marginTop: "8vh", width: 150, maxWidth: "40%"}} alt="NeuroFit has been featured in the Daily Mail." />
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                  <img src="/images/well-and-good-logo-square.webp" style={{marginTop: "6vh", width: 150, maxWidth: "40%"}} alt="NeuroFit has been featured in Well and Good." />
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                  <img src="/images/mamamia-logo.webp" style={{marginTop: "6vh", width: 150, maxWidth: "40%"}} alt="NeuroFit has been featured in Mamamia." />
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                  <img src="/images/msn-logo.webp" style={{marginTop: "8vh", width: 150, maxWidth: "40%"}} alt="NeuroFit has been featured in Mamamia." />
                </div>
              </div>
            ) : (
              <div style={{display: "flex", alignItems: "center", marginTop: "calc(30px + 5vh)", justifyContent: "space-around", opacity: 0.4}}>
                <div />
                <img src="/images/daily-mail-logo.webp" style={{width: 150, maxWidth: "22%"}} alt="NeuroFit has been featured in the Daily Mail." />
                <img src="/images/well-and-good-logo-square.webp" style={{width: 150, maxWidth: "22%"}} alt="NeuroFit has been featured in Well and Good." />
                <img src="/images/mamamia-logo.webp" style={{width: 150, maxWidth: "22%"}} alt="NeuroFit has been featured in Mamamia." />
                <img src="/images/msn-logo.webp" style={{width: 150, maxWidth: "22%"}} alt="NeuroFit has been featured in Mamamia." />
                <div />
              </div>
            )
          )}
        </div>
        <div id="meet-your-trainer" style={{textAlign: "left", borderTop: "2px #000000 solid", width: "100vw"}}>
          <div>
            <div style={{color: "white", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: "calc(60px + 5vh)"}}>
              <div style={{width: "85%", maxWidth: 700}}>
                <div style={{textShadow: coachTextShadow}}>
                  <div style={{fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(8.5vw, 40px)", textAlign: "left", marginTop: innerHeight}}>  
                    {"MEET YOUR TRAINER: LOREN HOGUE"}
                  </div>
                  <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                    {"Loren Hogue is the Co-CEO of NeuroFit with her husband Andrew."}
                  </div>
                  <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                    {"She’s a Master Coach and Trainer with 3000+ hours of experience, and has helped thousands of students transform their lives and businesses with her challenges, courses and coaching."}
                  </div>
                  <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                    {"With a background as an attorney, life coach and business coach, Loren takes a very practical and hands-on approach to Neurological Fitness that translates effortlessly in your life, your business and your work with clients."}
                  </div>
                  <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                    {"Loren & Andrew developed NeuroFit off the back of a combined 20+ years of experience. After many years looking for a solution to their childhood trauma and chronic nervous system dysregulation, they found their way to a highly effective, data-driven, and affordable approach."}
                  </div>
                  <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                    {"This led them to develop NeuroFit, the world’s leading nervous system regulation app, and to share this knowledge within the NeuroFit Trainer Certification."}
                  </div>
                  <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                    {"If this simple, practical, and effective approach speaks to you, then take the leap today:"}
                  </div>
                  <div style={{display: "flex", justifyContent: "center", marginTop: "min(20px, 5vh)"}}>
                    <button
                      style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura Book", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#000000", backgroundColor: "#FFFFFF", padding: "18px 15px", border: "2px #000000 solid"}}
                      onClick={() => {
                        this.signUpRef.scrollIntoView({behavior: "smooth"})
                      }}
                    >
                      <LoadingLogo
                        show={true}
                        hideAnimation={true}
                        responsiveSize={"min(6vw, 25px)"}
                        marginTop={"0px"}
                        padding={"0px"}
                        marginRight={"0.4em"}
                        color={"#000000"}
                        lineColor={"#FFFFFF"}
                        fontFamily={"Futura Book"}
                        text={"REGISTER NOW"}
                        showProgressionBar={false}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "left", width: "100vw", paddingTop: "calc(60px + 5vh)", paddingBottom: safeAreaInsets.bottom}}>
              <div style={{padding: 15, paddingBottom: 15 + safeAreaInsets.bottom, backgroundColor: "transparent", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura Book", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                <div style={{display: "inline-block"}}>
                  <span style={{color: "#c1c4d2"}}>
                    {"© NEUROFIT 2023"}
                  </span>
                  <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                    {"•"}
                  </span>
                  <span style={{color: "#c1c4d2"}}>
                    {"ALL RIGHTS RESERVED"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TrainingRegistrationDetails