import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './common/LoadingLogo'

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
} from '../constants/svgs'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionLinksBaseUrl,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
} from '../utils'

import ResearchDetails from './common/ResearchDetails'

const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class ResearchPage extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        learnMoreRef: React.createRef(),
        headerColor: "transparent",
        dropShadowColor: "transparent",
        show: true,
        showIframe: false,
     }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateHeaderColorOnContainerScroll = e => {
    if (e.target.scrollTop > 0) {
      const alpha = Math.min(0.5, e.target.scrollTop / 500)
      const headerColor = `rgba(255, 255, 255, ${alpha/1.5})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/3.0})`
      this.setState({headerColor, dropShadowColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", dropShadowColor: "transparent"})
    }

    if (e.target.scrollTop > 400) {
      this.setState({showIframe: true})
    }
  }

  componentDidMount() {
    this.setState({show: true})
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      i18n,
      isIOSDevice,
      isAndroidDevice,
    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor, showIframe} = this.state

    return (
      <div style={{height: innerHeight}}>
        <CSSTransition
          in={show}
          timeout={400}
          classNames={"fade-in-400"}
          onExited={() => {}}
        >
          <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
            <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
            <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 5 + safeAreaInsets.top, paddingLeft: "min(40px, 7.5%)", paddingRight: "min(20px, 4%)", paddingBottom: 5, backgroundColor: "white", zIndex: 1000}}>
              <LoadingLogo
                show={true}
                hideAnimation={true}
                responsiveSize={"min(7vw, 35px)"}
                marginTop={0}
                color={"black"}
                fontFamily={"Futura Book"}
                text={"NeuroFit"}
                showProgressionBar={false}
                onClick={() => history.push("/")}
              />
              <div style={{display: "flex", alignItems: "center"}}>
                <a
                  href={"/"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{fontFamily: "Futura Medium", textDecoration: "none", fontSize: "min(4vw, 15px)", textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
                >
                  {"Home"}
                </a>
              </div>
            </div>
            <ResearchDetails
              innerHeight={innerHeight}
              safeAreaInsets={safeAreaInsets}
              isNative={isNative}
              onContainerScroll={updatedState => this.setState({...updatedState})}
            />
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default ResearchPage;