import React, {Suspense} from 'react';


import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './common/LoadingLogo'
import DownloadButtonsSection from './common/DownloadButtonsSection'
import AutoPlayInlineVideoContainer from './common/AutoPlayInlineVideoContainer'
import PieChartInsightSection from './common/PieChartInsightSection'
import LineGraph from './common/LineGraph'
import NeuroFitProgressRing from './common/NeuroFitProgressRing'
import MembershipFeatureDescription from './common/MembershipFeatureDescription'
import AppReviewPanel from './common/AppReviewPanel'
import UniversitySourcesSection from './common/UniversitySourcesSection'
import FaqSection from './common/FaqSection'
import LandingPageLinksModal from './modals/LandingPageLinksModal'
import QrCodeDownloadModal from './modals/QrCodeDownloadModal'


import { history } from '../history'

import {
  subdomainUrls,
  recordSignupFunnelEventGA,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  ProductionBaseUrl,
  ProductionWorkshopsUrl,
  ProductionCertificationUrl,
  delay,
  DefaultAspectRatio,
  recordLinkClickAndRedirect,
  recordPixelEventIfAvailable,
  getCurrentYear,
  MinDesktopWidthPixels,
  initializePixelIfAvailable,
  isProduction,
} from '../utils'

import {
  AppleSvg,
  AndroidSvg,
  HamburgerSvg,
} from '../constants/svgs'

import {
  GA_LABEL_CLICKED_APP_DOWNLOAD_LINK,
  GA_LABEL_CLICKED_WORKSHOP_BUTTON,
  GA_LABEL_CLICKED_CERTIFICATION_BUTTON,
  GA_LABEL_CLICKED_BOOK_BUTTON,
  GA_LABEL_CLICKED_QUIZ_BUTTON,
} from '../constants/gaEventLabels'

import TextLoop from "react-text-loop";
import StarRatings from 'react-star-ratings';

const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const LandingPageFAQItems = [
  {
    key: "background_science_header",
    is_header: true,
    text: "Logistics & Science"
  },
  {
    key: "daily_commitment",
    question: "What is the daily time commitment for success?",
    answer: "Most members spend about 5 minutes a day inside the app. Beyond this, the recommended embodiment routine takes about 30 minutes each day (10 minutes each of social play, exercise, and stillness)."
  },
  {
    key: "science",
    question: "What is the science behind NeuroFit?",
    answer: (
      <span>
        <span>{"NeuroFit stands on the shoulders of giants. The core components included in our nervous system approach have been validated by neuroscience research from top universities, and the data from tens of thousands of sessions on our app. Our list of sources is updated regularly and can be viewed "}</span>
        <span onClick={() => {window.open("/research", "_blank", "noopener noreferrer")}} style={{fontFamily: "Futura Book", textDecoration: "underline", textUnderlineOffset: 2, cursor: "pointer"}}>{"here"}</span>
        <span>{"."}</span>
      </span>
    )
  },
  {
    key: "hrv_methodology",
    question: "How does your HRV measurement tech work?",
    answer: "To measure HRV, NeuroFit reads changes in finger color via your phone's camera, to detect naturally occurring changes in heartbeat rhythm. This clinically validated technique is known as \"photoplethysmography\", and achieves a real-world accuracy of 96% compared to gold-standard (ECG-comparable) sensors in the appropriate conditions (eg, lighting, exposure, and finger pressure)."
  },
  {
    key: "app_design_header",
    is_header: true,
    text: "App Design"
  },
  {
    key: "simple_app",
    question: "The app's design seems simple and minimalist. Why is this?",
    answer: "This is intentional: the nervous system is balanced through action, not accumulating knowledge. Two common roadblocks when resolving nervous system dysegulation are overthinking, and the dopamine release that comes from consuming excessive information. The app experience is designed to prevent both of these, increasing the likelihood that our members take action."
  },
  {
    key: "balance_score",
    question: "How should I interpret my daily BALANCE score?",
    answer: "The NeuroFit Balance Score is a percentage measuring the nervous system's overall flexibility and readiness to be challenged. It's a short-term indicator of nervous system strength, resilience and recovery on a given day, and is measured relative to other nervous system readings you've taken lately."
  },
  {
    key: "membership_header",
    is_header: true,
    text: "Membership"
  },
  {
    key: "membership_logistics",
    question: "What are the logistics of membership?",
    answer: "After a 3-day free trial, membership can be accessed with either a 3 month or yearly subscription. Membership is auto-renewing and can be managed through the App Store or Play Store at any time."
  },
  {
    key: "membership_price_duration",
    question: "What is the investment for membership?",
    answer: "After a 3-day free trial, membership is priced at either $35.99 USD per 3 months of membership, or $124.99 USD for yearly membership."
  },
  {
    key: "membership_time",
    question: "Why the 3 month membership plan?",
    answer: "Creating long-lasting changes in the nervous system takes about 6 weeks (the length of our program). To maximize results, we want you to commit to these changes and to be able to complete the program twice in one membership cycle. We have also found that this price point self-selects for members who are prepared to take ownership of their well-being."
  }
]

let defaultShowAnswer = {}

LandingPageFAQItems.map(key => defaultShowAnswer[key] = false)

class LandingPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showAnswer: defaultShowAnswer,
      showSettingsModal: false,
      showQrCodeModal: false,
      learnMoreRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isVideoLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      hasScrolled: false
    }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  startHeaderTextCycle = async () => {

    while (true) {
      const delay1 = 3500
      this.setState({currentHeaderIndex: 0, storyLoadingLength: delay1})
      await delay(delay1)

      const delay2 = 7950 - delay1
      this.setState({currentHeaderIndex: 1, storyLoadingLength: delay2})
      await delay(delay2)

      const delay3 = 14900 - 7950
      this.setState({currentHeaderIndex: 2, storyLoadingLength: delay3})
      await delay(delay3)
    }
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(${237 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${238 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${242 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, 1.0)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`
      const hasScrolled = true
      this.setState({headerBackgroundColor, heroOverlayColor, dropShadowColor, hasScrolled, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, logoOutlineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity})
      if (!this.state.hasScrolled) {
        initializePixelIfAvailable()
      }
    } else {
      this.setState({headerBackgroundColor: "transparent", heroOverlayColor: "transparent", heroBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#EDEEF2", headerBackgroundBlur: "none", topPageScrollProgress: 0, hasScrolled: true, headerBorderColor: "transparent", videoOpacity: 1})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      i18n,
      isIOSDevice,
      isAndroidDevice
    } = this.props

    const {show, showAnswer, learnMoreRef, showSettingsModal, showQrCodeModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, showIframe, isVideoLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity, hasScrolled} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const phoneAspectRatio = 1841 / 907

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")

    const ReviewImageSrcPaths = [
      "/images/review_coach.webp",
      "/images/review_fibro.webp",
      "/images/review_jessica.webp",
      "/images/review_nicole.webp",
      "/images/review_rhiannon_haart.webp",
      "/images/review_workout_motivation.webp"
    ]

    const MemberAppReviews = [
      {
        title: "I LOVE how informative this app is",
        reviewStars: 5,
        dateString: "Feb 1",
        username: "Kristine Crouch",
        countryFlag: "🇦🇺",
        platform: "android",
        description: "I'm a holistic health and wellness coach, and I LOVE how informative this app is. It's a great way to stay on track with my daily habits and keeps me informed and accountable when it comes to rest and recovery. The app is simple to use and super minimalist, which makes it easy to navigate. I feel more rested and balanced, and it only takes a minimum of 5 minutes in the morning!"
      },
      {
        title: "Immensely helpful tool",
        reviewStars: 5,
        dateString: "Feb 3",
        username: "Jen Redford",
        countryFlag: "",
        platform: "apple",
        description: "I appreciate both the accountability and tracking to see what’s truly going on and where I need support. I have noticed a significant shift since incorporating what that app suggests for more nervous system balance. The exercises take only 2-3 minutes and are simple and effective!"
      },
      {
        title: "Exactly what I've been needing",
        reviewStars: 5,
        dateString: "Oct 15",
        username: "Adriegm",
        countryFlag: "🇩🇴",
        platform: "apple",
        description: "After 15 years of mystery symptoms, I finally put the dots together that my nervous system is super dysregulated. In just a couple uses, the NeuroFit app has helped me lower my seizures, feel more grounded and actually follow through with my self care and regulation practices. It’s gold! Simple, to the point and so effective."
      },
      {
        title: "I AM WOW’D!!",
        reviewStars: 5,
        dateString: "Feb 7",
        username: "Candice West",
        countryFlag: "🇺🇸",
        platform: "apple",
        description: "As a recently retired Navy Nurse, Life Coach and special needs momma … I AM WOW’D!! I have only been using this app for a week and have noticed such a difference in my awareness surrounding my nervous system balance. I am so excited to continue using this to be more mindful of what pulls me out of alignment and what practices restores it. I have to add that these are so quick and easy my son could even benefit!! Genius practical app ⭐️⭐️⭐️⭐️⭐️!"
      },
      {
        title: "Neurofit is really helping",
        reviewStars: 5,
        dateString: "Nov 20",
        username: "addfjfjs",
        countryFlag: "🇦🇺",
        platform: "apple",
        description: "Keeping me mindful, checking in daily, and especially having the mental shifting exercise options is really great. I feel empowered to monitor and better manage my shifting mental states."
      },
      {
        title: "Love it",
        reviewStars: 5,
        dateString: "Dec 28",
        username: "lucky learner",
        countryFlag: "🇦🇺",
        platform: "apple",
        description: "Easy and enjoyable, and it’s doing me good"
      },
      {
        title: "Love the concept of this app.",
        reviewStars: 5,
        dateString: "Jan 25",
        username: "Brianna Boulanger",
        countryFlag: "🇺🇸",
        platform: "android",
        description: "Nervous system regulation is a game changer for true healing and growth and I love having the ability to track where I am at daily and give my body what it needs via the recommended exercises. Definitely try this app if you feel like there has been a missing piece to your growth and healing."
      },
      {
        title: "Love this app!",
        reviewStars: 5,
        dateString: "Sep 9",
        username: "jcaitlinbrown",
        countryFlag: "🇺🇸",
        platform: "apple",
        description: "As a coach who works with women on their nervous system, this is such an incredible tool that I love and cannot wait to share with my clients!"
      },
      {
        title: "My real experience with NeuroFit",
        reviewStars: 5,
        dateString: "Sep 8",
        username: "Blisseleven65",
        countryFlag: "🇨🇭",
        platform: "apple",
        description: "Amazing app. I have been using it and following each step of the process. So far after 4 days, I already feel more balanced and concentrated. Definitely a good tool to improve every aspect of your life⭐️"
      },
      {
        title: "Great app for balancing the nervous system!",
        reviewStars: 5,
        dateString: "Nov 22",
        username: "lilredkali",
        countryFlag: "🇺🇸",
        platform: "apple",
        description: "I’m using this app to balance my nervous system. I have fibromyalgia and use this to address my fatigue and to check in with myself. It helps teach you through balancing exercises how to change your different states. I’ve only used it for a week but am more aware of how I’m feeling and my energy levels."
      },
      {
        title: "Awesome App",
        reviewStars: 5,
        dateString: "Aug 31",
        username: "Jessica V McKay",
        countryFlag: "🇺🇸",
        platform: "apple",
        description: "I never felt in control of my nervous system before this. I felt at the mercy of my emotions and cycle. I love everything about this app— it actually helps me change my state if I’m overwhelmed and frustrated. It’s so simple! That’s the beauty of it."
      }
    ]

    const headerTextItems = [
      "Eliminate Stress",
      "Resolve Burnout",
      "Optimize Performance"
    ]

    const currentYear = getCurrentYear()

    const appDownloadUrl = isIOSDevice ? NeuroFitAppleAppStoreLink : (isAndroidDevice ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)

    return (
      <div style={{height: innerHeight}}>
        <CSSTransition
          in={show}
          timeout={400}
          classNames={"fade-in-400"}
          onExited={() => {}}
        >
          <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
            <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
            <div style={{position: "fixed", top: 0, left: 0, right: 0, borderBottom: `2px ${headerBorderColor} solid`, display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 5 + safeAreaInsets.top, paddingLeft: "min(40px, 7.5%)", paddingRight: "min(20px, 4%)", paddingBottom: 5, backgroundColor: headerBackgroundColor, zIndex: 1000, backdropFilter: headerBackgroundBlur, WebkitBackdropFilter: headerBackgroundBlur}}>
              <LoadingLogo
                show={true}
                hideAnimation={true}
                responsiveSize={"min(7vw, 35px)"}
                marginTop={0}
                color={"#000000"}
                lineColor={logoOutlineColor}
                fontFamily={"Futura Book"}
                text={"NeuroFit"}
                dropShadowColor={logoOutlineColor}
                dropShadowMultiplier={5}
                showProgressionBar={false}
                onClick={() => history.push("/")}
              />
              {!isMobile ? (
                <div style={{display: "flex", alignItems: "center"}}>
                  <span
                    onClick={() => {
                      recordPixelEventIfAvailable("Lead")
                      recordLinkClickAndRedirect(GA_LABEL_CLICKED_BOOK_BUTTON, "/book")
                    }}
                    style={{fontFamily: "Futura Medium", fontSize: "min(4vw, 15px)", textShadow, textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
                  >
                    {"Book"}
                  </span>
                  <span style={{display: "inline-block", margin: "0px 0.5em", textShadow, fontSize: "min(2vw, 10px)"}}>
                    {"•"}
                  </span>
                  <span
                    onClick={() => {
                      recordPixelEventIfAvailable("Lead")
                      if (isIOSDevice || isAndroidDevice) {
                        recordPixelEventIfAvailable("Lead")
                        recordLinkClickAndRedirect(GA_LABEL_CLICKED_APP_DOWNLOAD_LINK, appDownloadUrl)
                      } else {
                        this.setState({showQrCodeModal: true})
                      }
                    }}
                    style={{cursor: "pointer", fontFamily: "Futura Medium", textShadow, fontSize: "min(4vw, 15px)", textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
                  >
                    {"App"}
                  </span>
                  <span style={{display: "inline-block", margin: "0px 0.5em", textShadow, fontSize: "min(2vw, 10px)"}}>
                    {"•"}
                  </span>
                  <span
                    onClick={() => {
                      recordPixelEventIfAvailable("Lead")
                      recordLinkClickAndRedirect(GA_LABEL_CLICKED_CERTIFICATION_BUTTON, "/certification")
                    }}
                    style={{fontFamily: "Futura Medium", fontSize: "min(4vw, 15px)", textShadow, textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
                  >
                    {"Certification"}
                  </span>
                </div>
              ) : (
                <button
                  onClick={() => {
                    this.setState({showSettingsModal: true})
                  }}
                  style={{padding: 0, margin: 7, backgroundColor: "transparent", border: "none"}}
                >
                  <HamburgerSvg size="min(6vw, 30px)" dropShadow={svgDropShadow} />
                </button>
              )}
            </div>
            <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, right: 0, backgroundColor: showSettingsModal ? "rgba(0, 0, 0, 0.5)" : "transparent", pointerEvents: "none", transition: "all 300ms ease-out", transitionProperty: "background-color", zIndex: 2000}}>
            </div>
            <div 
              id={"landing-page-container"}
              className={"disable-scrollbars"}
              ref={elem => this.scrollingContainerRef = elem}
              style={containerStyle}
              onClick={() => {
                this.manualStartRef.playVideo()
              }}
            >
              <div style={{textAlign: "left", width: "100vw", height: innerHeight, pointerEvents: "none"}}>
                <div style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", justifyContent: "center", zIndex: -2}}>
                  <div style={{width: videoWidthPixels, opacity: videoOpacity}}>
                    <AutoPlayInlineVideoContainer
                      videoPath={"/assets/videos/landing-page-exercises.mp4"}
                      baseContentUrl={ProductionBaseUrl}
                      widthPixels={videoWidthPixels}
                      isVideoVisible
                      canManuallyStartVideo
                      ref={elem => this.manualStartRef = elem}
                      onVideoLoad={() => {
                        this.setState({videoDownloadProgress: 1})
                      }}
                    />
                  </div>
                </div>
                <CSSTransition
                  in={videoDownloadProgress < 1}
                  timeout={400}
                  classNames={"fade-in"}
                  onExited={() => {}}
                  unmountOnExit
                >
                  <div style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", alignItems: "center", justifyContent: "center", zIndex: -2}}>
                    <LoadingLogo
                      show={true}
                      hideAnimation={true}
                      responsiveSize={"100px"}
                      marginTop={0}
                      marginRight={"0px"}
                      color={"#CFD2DD"}
                      lineColor={"#EDEEF2"}
                      fontFamily={"Futura Book"}
                      showProgressionBar={false}
                    />
                  </div>
                </CSSTransition>
                <div style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", justifyContent: "center", zIndex: (topPageScrollProgress > 0) ? -1 : 0}}>
                  <div style={{display: "flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "space-between"}}>
                    <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: 60, pointerEvents: "none"}}>
                      <div style={{width: "85%", maxWidth: 700}}>
                        <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textShadow, textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", marginTop: "3vh", textTransform: "uppercase"}}>
                          {`A Stress Relief App Built For ${currentYear}`}
                        </div>
                      </div>
                    </div>
                    <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginBottom: "4vh", pointerEvents: "none"}}>
                      <div style={{width: "85%", maxWidth: 700, pointerEvents: "none"}}>
                        <DownloadButtonsSection
                          isIOSDevice={isIOSDevice}
                          isAndroidDevice={isAndroidDevice}
                          useWhiteButtons
                          onShowQrCodeModal={() => this.setState({showQrCodeModal: true})}
                          onDownloadButtonClick={() => this.setState({hasScrolled: true})}
                        />
                        <div style={{display: "flex", justifyContent: "center", marginTop: "2vh", pointerEvents: "none"}}>
                          <svg onClick={() => this.learnMoreRef.scrollIntoView({behavior: "smooth"})} style={{pointerEvents: "all", cursor: "pointer", height: "min(12vw, 70px)", width: "min(12vw, 70px)"}} className="loading-pulse-animation-down-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" version="1.1" x="0px" y="0px"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g fill="#FFFFFF"><path d="M20,27.4142136 L32.7071068,14.7071068 L31.2928932,13.2928932 L20,24.5799999 L8.70710678,13.2928932 L7.29289322,14.7071068 L20,27.4142136 Z"></path></g></g></svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{position: "fixed", top: 0, width: innerWidth, backdropFilter: heroBackdropFilter, WebkitBackdropFilter: heroBackdropFilter, height: "100%", display: "flex", backgroundColor: heroOverlayColor, justifyContent: "center", zIndex: -1, pointerEvents: "none"}} onClick={() => this.manualStartRef.playVideo()}>
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw", padding: "calc(60px + 5vh) 0px", backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}}  ref={elem => this.learnMoreRef = elem}>
                <div style={{height: "100%"}}>
                  <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                    <div style={{width: "85%", maxWidth: 700}}>
                      <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", textTransform: "uppercase"}}>
                        {"Simple. Smart."}
                      </div>
                      <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura Book", whiteSpace: "nowrap", textTransform: "uppercase"}}>
                        {"Stress Relief."}
                      </div>
                      <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                        {"Feel free to ditch the mindset work. NeuroFit's nervous system based approach resolves stress and improves well-being at the source."}
                      </div>
                      <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                        <span>{"Our average member reports 54% less stress & 35% more peace after 1 week."}</span>
                      </div>
                    </div>
                  </div>
                  <div style={{display: "flex", marginTop: "5vh", justifyContent: "center"}}>
                    <img style={{width: "70%", maxWidth: 400}} src="/images/well-and-good-laurel-black.webp" alt="NeuroFit eased my stress in ways meditation never has - Well and Good" />
                  </div>
                  <div>
                    <div style={{textAlign: "center", margin: "3vh auto 0px auto"}}>
                      <div>
                        <StarRatings
                          disabled
                          rating={4.8}
                          starRatedColor={"#000000"}
                          starEmptyColor={"#CFD2DD"}
                          starDimension={"min(7vw, 40px)"}
                          starSpacing={"min(2vw, 10px)"}
                          numberOfStars={5}
                        />
                      </div>
                      <div style={{display: "inline-block", fontSize: "min(4.5vw, 26px)", textAlign: "left", fontFamily: "Futura Medium", marginRight: "0.3em", textTransform: "uppercase"}}>
                        {"4.8/5 Global App Rating"}
                      </div>
                    </div>
                    <div style={{color: "black", width: "100%",display: "flex", justifyContent: "center", alignItems: "top"}}>
                      <div style={{width: "85%", maxWidth: 700}}>
                        <DownloadButtonsSection
                          isIOSDevice={isIOSDevice}
                          isAndroidDevice={isAndroidDevice}
                          onShowQrCodeModal={() => this.setState({showQrCodeModal: true})}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw", minHeight: `calc(${innerHeight}px - 120px - 10vh)`, backgroundColor: "#EDEEF2", borderTop: "2px #000000 solid", padding: `calc(60px + 5vh) 0px`}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", textTransform: "uppercase"}}>
                      {"Nervous System Is The Key To Modern Wellness"}
                    </div>
                    <div style={{marginTop: "4vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                      <div style={{marginTop: "6vh"}}>
                        {"Chronic stress leads to an imbalanced nervous system. Mindset isn't designed for this:"}
                      </div>
                      <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                        <div style={{width: "100%", maxWidth: 450}}>
                          <PieChartInsightSection
                            title={`NEUROFIT VS. MINDSET`}
                            marginTop={"max(3vw, 16px)"}
                            disableDropDown
                            dataIsAvailable
                            data={{"neurofit": 80, "mindset": 20}}
                            insightText={`NeuroFit speaks to 80% of the nervous system (afferent nerves). Mindset only speaks to the other 20%.`}
                            dataCategories={[{title_name: "NeuroFit", name: "neurofit", color: "#DFFF00"}, {title_name: "Mindset", name: "mindset", color: "#8084CE"}]}
                          />
                        </div>
                      </div>
                      <div style={{marginTop: "6vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                        <span>{"The good news? NeuroFit is designed specifically for the nervous system:"}</span>
                      </div>
                      <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                        <div style={{width: "100%", maxWidth: 450}}>
                          <LineGraph
                            dataIsAvailable
                            title={`DAILY STRESS RELIEF`}
                            data={{
                              labels: ["0", "1", "2", "3", "4", "5", "6", "7"],
                              series: [[54.9, 42.16, 33.89, 36.91, 40.2, 34.63, 34.91, 25.23].map(v => (v * 100 / 54.9))]
                            }}
                            labelInterpolationFnc={(val) => `${val}%`}
                            highYValue={100}
                            xAxisTitle={"Days On NeuroFit Protocol"}
                            hideLogo
                          />
                        </div>
                      </div>
                      <div style={{marginTop: "6vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                        <span>{"And within 5 minutes of NeuroFit training, 95% of members relieve real-time stress and dysregulation:"}</span>
                      </div>
                      <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                        <div style={{width: "100%", maxWidth: 450}}>
                          <LineGraph
                            dataIsAvailable
                            title={`REAL-TIME STRESS RELIEF`}
                            data={{
                              labels: ["0", "1", "2", "3"],
                              series: [[0, 88.8, 95, 98.6]],
                            }}
                            labelInterpolationFnc={(val) => `${val}%`}
                            highYValue={100}
                            hideLogo
                            xAxisTitle={"Exercises Completed (2-3 mins. each)"}
                          />
                        </div>
                      </div>
                      <div style={{marginTop: "6vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                        <span>{"The core components of our approach are simple, direct, and validated by "}</span>
                        <span onClick={() => {window.open("/research", "_blank", "noopener noreferrer")}} style={{fontFamily: "Futura Book", textDecoration: "underline", textUnderlineOffset: 2, cursor: "pointer"}}>{" neuroscience research "}</span>
                        <span>{" from universities such as:"}</span>
                      </div>
                      <UniversitySourcesSection
                        isMobile={isMobile}
                        hideSecondRow
                        lazyLoadImages
                      />
                      <div style={{margin: "6vh 0px 2vh 0px", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                        <span>{"And beyond stress relief, balancing the nervous system improves overall well-being:"}</span>
                      </div>
                      <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                        <div style={{marginBottom: "6vh", backgroundColor: "#FFFFFF", width: "100%", maxWidth: 450, boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
                          <div style={{padding: "min(24px, max(3vw, 16px))"}}>
                            <div style={{fontSize: "min(5vw, 22px)", textAlign: "left", fontFamily: "Futura Book"}}>
                              {"BENEFITS OF A REGULATED NERVOUS SYSTEM"}
                            </div>
                            <MembershipFeatureDescription
                              fontSize={"min(4vw, 18px)"}
                              fontFamily={"Futura Book"}
                              checkMarginTop={"0.2em"}
                              text={"Enhanced Mental Clarity and Focus"}
                            />
                            <MembershipFeatureDescription
                              fontSize={"min(4vw, 18px)"}
                              fontFamily={"Futura Book"}
                              checkMarginTop={"0.2em"}
                              text={"Increased Access to Creativity"}
                            />
                            <MembershipFeatureDescription
                              fontSize={"min(4vw, 18px)"}
                              fontFamily={"Futura Book"}
                              checkMarginTop={"0.2em"}
                              text={"Improved Emotional Balance"}
                            />
                            <MembershipFeatureDescription
                              fontSize={"min(4vw, 18px)"}
                              fontFamily={"Futura Book"}
                              checkMarginTop={"0.2em"}
                              text={"Better Sleep and Faster Recovery"}
                            />
                          </div>
                        </div>
                      </div>
                      <DownloadButtonsSection
                        isIOSDevice={isIOSDevice}
                        isAndroidDevice={isAndroidDevice}
                        onShowQrCodeModal={() => this.setState({showQrCodeModal: true})}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", textTransform: "uppercase"}}>
                      {"Love From Our Members"}
                    </div>
                  </div>
                </div>
                <div className="disable-scrollbars" style={{scrollSnapType: "x mandatory", alignItems: "center", marginTop: `3vh`, padding: "5px 0px", overflowX: "scroll", overflowY: "hidden", display: "flex", WebkitOverflowScrolling: "touch"}}>
                  {MemberAppReviews.map(review => (
                    <AppReviewPanel
                      key={review.username}
                      title={review.title}
                      reviewStars={review.reviewStars}
                      dateString={review.dateString}
                      username={review.username}
                      countryFlag={review.countryFlag}
                      description={review.description}
                    />
                  ))}
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#EDEEF2", borderTop: "2px #000000 solid"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", textTransform: "uppercase"}}>
                      {"AS FEATURED IN"}
                    </div>
                  </div>
                </div>
                {hasScrolled && (
                  isMobile ? (
                    <div style={{textAlign: "center", paddingTop: "calc(2vh)", opacity: 0.4}}>
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/daily-mail-logo.webp" loading="lazy" style={{marginTop: "8vh", width: 150, maxWidth: "40%"}} alt="NeuroFit has been featured in the Daily Mail." />
                      </div>
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/well-and-good-logo-square.webp" loading="lazy" style={{marginTop: "6vh", width: 150, maxWidth: "40%"}} alt="NeuroFit has been featured in Well and Good." />
                      </div>
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/mamamia-logo.webp" loading="lazy" style={{marginTop: "6vh", width: 150, maxWidth: "40%"}} alt="NeuroFit has been featured in Mamamia." />
                      </div>
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <img src="/images/msn-logo.webp" loading="lazy" style={{marginTop: "8vh", width: 150, maxWidth: "40%"}} alt="NeuroFit has been featured in Mamamia." />
                      </div>
                    </div>
                  ) : (
                    <div style={{display: "flex", alignItems: "center", marginTop: "calc(30px + 5vh)", justifyContent: "space-around", opacity: 0.4}}>
                      <div />
                      <img src="/images/daily-mail-logo.webp" loading="lazy" style={{width: 150, maxWidth: "22%"}} alt="NeuroFit has been featured in the Daily Mail." />
                      <img src="/images/well-and-good-logo-square.webp" loading="lazy" style={{width: 150, maxWidth: "22%"}} alt="NeuroFit has been featured in Well and Good." />
                      <img src="/images/mamamia-logo.webp" loading="lazy" style={{width: 150, maxWidth: "22%"}} alt="NeuroFit has been featured in Mamamia." />
                      <img src="/images/msn-logo.webp" loading="lazy" style={{width: 150, maxWidth: "22%"}} alt="NeuroFit has been featured in Mamamia." />
                      <div />
                    </div>
                  )
                )}
              </div>
              <div style={{textAlign: "left", width: "100vw", backgroundColor: "#FFFFFF", padding: `calc(60px + 5vh) 0px`, borderTop: "2px #000000 solid"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{fontFamily: "Futura Book", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                      {"BECOME AN EXPERT NERVOUS SYSTEM FITNESS TRAINER"}
                    </div>
                    <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh 0px"}}>
                      {hasScrolled && (
                        <img src="/images/certification-hero-dash.webp" loading="lazy" style={{width: "100%", maxWidth: 600}} alt="A 3-Week Nervous System Certification for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth." />
                      )}
                    </div>
                    <div style={{marginTop: "4vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                      <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(5vw, 25px)", textAlign: "left"}}>
                        {`Master nervous system regulation, somatic release exercises, and trauma-informed witnessing, and become a Certified Trainer with our:`}
                      </div>
                      <div style={{display: "flex", alignItems: "top", justifyContent: "left", padding: "0vh 2vh 1vh 2vh"}}>
                        <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                          {"•"}
                        </div>
                        <div style={{fontFamily: "Futura Book",  fontSize: "min(5vw, 25px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                          {"Nervous System Certification Program,"}
                        </div>
                      </div>
                      <div style={{display: "flex", alignItems: "top", justifyContent: "left", padding: "0vh 2vh 1vh 2vh"}}>
                        <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                          {"•"}
                        </div>
                        <div style={{fontFamily: "Futura Book", fontSize: "min(5vw, 25px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                          {"Nervous System Trainer Tech + Toolkit, and"}
                        </div>
                      </div>
                      <div style={{display: "flex", alignItems: "top", justifyContent: "left", padding: "0vh 2vh 1vh 2vh"}}>
                        <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                          {"•"}
                        </div>
                        <div style={{fontFamily: "Futura Book", fontSize: "min(5vw, 25px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                          {"Live Coaching and Trainer Community"}
                        </div>
                      </div>
                      <div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                          <button
                            style={{display: "flex", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura Book", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "4vh", color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                            onClick={() => {
                              recordPixelEventIfAvailable("Lead")
                              recordLinkClickAndRedirect(GA_LABEL_CLICKED_CERTIFICATION_BUTTON, "/certification")
                            }}
                          >
                            <LoadingLogo
                              show={true}
                              hideAnimation={true}
                              responsiveSize={"min(6vw, 25px)"}
                              marginTop={"0px"}
                              padding={"0px"}
                              marginRight={"0.4em"}
                              color={"#FFFFFF"}
                              lineColor={"#000000"}
                              fontFamily={"Futura Book"}
                              text={"VIEW CERTIFICATION"}
                              showProgressionBar={false}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw", backgroundColor: "#EDEEF2", padding: `calc(60px + 5vh) 0px`, borderTop: "2px #000000 solid"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", textTransform: "uppercase"}}>
                      {"Nervous System, Simplified."}
                    </div>
                    <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh 0px"}}>
                      <img src="/images/neurofit-book-cover.webp" loading="lazy" style={{width: 300, maxWidth: "50%"}} alt="NeuroFit: An interactive guide to nervous system regulation. How to reduce stress, feel better in your body, and perform at your best." />
                    </div>
                    <div style={{marginTop: "4vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Book"}}>
                      <div style={{marginTop: "4vh"}}>
                        {"Master the basics of nervous system regulation with six chapters of practical knowledge and interactive exercises."}
                      </div>
                        <div style={{backgroundColor: "transparent", display: "flex", justifyContent: "center"}}>
                          <img style={{width: `${100 * 70 / 85}%`, maxWidth: 400, margin: "4vh 0px"}} loading="lazy" src="/images/amazon-new-release.webp" alt="NeuroFit is the Amazon Top New Release in Neuropsychology and Physiological Aspects of Psychology" />
                        </div>
                      <div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                          <button
                            style={{display: "flex", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura Book", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                            onClick={() => {
                              recordPixelEventIfAvailable("Lead")
                              recordLinkClickAndRedirect(GA_LABEL_CLICKED_BOOK_BUTTON, "/book")
                            }}
                          >
                            <LoadingLogo
                              show={true}
                              hideAnimation={true}
                              responsiveSize={"min(6vw, 25px)"}
                              marginTop={"0px"}
                              padding={"0px"}
                              marginRight={"0.4em"}
                              color={"#FFFFFF"}
                              lineColor={"#000000"}
                              fontFamily={"Futura Book"}
                              text={"GET THE BOOK"}
                              showProgressionBar={false}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw", backgroundColor: "#FFFFFF", paddingTop: `calc(60px + 5vh)`, borderTop: "2px #000000 solid"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingBottom: "calc(60px + 5vh)"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", textTransform: "uppercase"}}>
                      {"GET IN TOUCH"}
                    </div>
                    <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                      <div style={{marginTop: "min(6vh, 30px)", marginBottom: "min(6vh, 30px)", backgroundColor: "#EDEEF2", width: "100%", maxWidth: 500, boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)"}}>
                        <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura Book"}}>
                          {`PRESS & MEDIA`}
                        </div>
                        <div style={{marginTop: 20, fontSize: "min(5vw, 20px)", textAlign: "left", fontFamily: "Futura Book"}}>
                          <a href="mailto:press@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura Book", textDecoration: "underline", textUnderlineOffset: 2}}>
                            {"press@neurofit.app"}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                      <div style={{backgroundColor: "#EDEEF2", marginBottom: "min(6vh, 30px)", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)", width: "100%", maxWidth: 500}}>
                        <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura Book"}}>
                          {`TEAM APP ACCESS`}
                        </div>
                        <div style={{marginTop: 20, fontSize: "min(5vw, 20px)", textAlign: "left", fontFamily: "Futura Book"}}>
                          <a href="mailto:teams@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura Book", textDecoration: "underline", textUnderlineOffset: 2}}>
                            {"teams@neurofit.app"}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                      <div style={{backgroundColor: "#EDEEF2", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)", width: "100%", maxWidth: 500}}>
                        <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura Book"}}>
                          {`CONTACT SUPPORT`}
                        </div>
                        <div style={{marginTop: 20, fontSize: "min(5vw, 20px)", textAlign: "left", fontFamily: "Futura Book"}}>
                          <a href="mailto:support@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura Book", textDecoration: "underline", textUnderlineOffset: 2}}>
                            {"support@neurofit.app"}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{textAlign: "left", width: "100vw", backgroundColor: "#EDEEF2", paddingTop: `calc(60px + 5vh)`, borderTop: "2px #000000 solid"}}>
                  <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingBottom: "calc(60px + 5vh)"}}>
                    <div style={{width: "85%", maxWidth: 700}}>
                      <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura Book", marginRight: "0.3em", textTransform: "uppercase"}}>
                        {"APP FAQ"}
                      </div>
                      {LandingPageFAQItems.map((f) => (
                        (f.is_header ? (
                          <div key={f.key} style={{fontSize: "min(7vw, 32px)", textTransform: "uppercase", margin: "min(9vh, 45px) 0px 0px 0px", color: "#000000", textAlign: "left", fontFamily: "Futura Book"}}>
                            {f.text}
                          </div>
                        ) : (
                          <FaqSection
                            key={f.key}
                            question={f.question}
                            answer={f.answer}
                            showAnswer={showAnswer[f.key]}
                            onToggleShowAnswer={() => this.setState({showAnswer: {...showAnswer, [f.key]: (!showAnswer[f.key])}})}
                            marginTop={"2vh"}
                          />
                        ))
                      ))}
                    </div>
                  </div>
                </div>
                <div style={{padding: 15, paddingTop: 30, backgroundColor: "#EDEEF2", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura Book", color: "#c1c4d2", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                  <div style={{display: "inline-block"}}>
                    <span style={{color: "#c1c4d2"}}>
                      {`© NEUROFIT ${currentYear}`}
                    </span>
                    <span style={{display: "inline-block", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                      {"•"}
                    </span>
                    <span style={{color: "#c1c4d2"}}>
                      {"ALL RIGHTS RESERVED"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
        <QrCodeDownloadModal
          show={showQrCodeModal}
          onHide={() => this.setState({showQrCodeModal: false})}
          safeAreaInsets={safeAreaInsets}
        />
        <LandingPageLinksModal
          show={showSettingsModal}
          onHide={() => this.setState({showSettingsModal: false})}
          onClickBookButton={() => {
            recordPixelEventIfAvailable("Lead")
            recordLinkClickAndRedirect(GA_LABEL_CLICKED_BOOK_BUTTON, "/book")
          }}
          onClickDownloadAppButton={() => {
            recordPixelEventIfAvailable("Lead")
            recordLinkClickAndRedirect(GA_LABEL_CLICKED_APP_DOWNLOAD_LINK, appDownloadUrl)
          }}
          onClickTrainerCertificationButton={() => {
            recordPixelEventIfAvailable("Lead")
            recordLinkClickAndRedirect(GA_LABEL_CLICKED_CERTIFICATION_BUTTON, "/certification")
          }}
          onClickQuizButton={() => {
            recordPixelEventIfAvailable("Lead")
            recordLinkClickAndRedirect(GA_LABEL_CLICKED_QUIZ_BUTTON, "/quiz")
          }}
          safeAreaInsets={safeAreaInsets}
        />
        {hasScrolled && (
          <iframe src={`${subdomainUrls.baseNativeAppUrl}/app?w=1`}  width="0" height="0" frameborder="0" style={{display: "none", width: 0, height: 0, border: 0, border: "none", position: "absolute"}} />
        )}
      </div>
    )
  }
}

export default LandingPage;