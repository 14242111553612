import React from 'react'

import {
  baseDomain,
  DefaultCdnBaseUrl,
  DefaultAspectRatio
} from '../../utils'

import {
  PlayButtonSvg,
  OvalSpinnerSvg,
} from '../../constants/svgs'

import { CSSTransition, SwitchTransition } from 'react-transition-group';

class InlineVideoContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVideoLoaded: false,
      isVideoPlaying: false,
      posterIsLoaded: false,
      hasVideoStarted: false,
      hasVideoBeenPaused: false,
      hasBeenTapped: false
    }
    this.videoElementRef = React.createRef();


    this.playVideo = this.playVideo.bind(this);

    this.pauseVideo = this.pauseVideo.bind(this)

    this.getVideoIsPlaying = this.getVideoIsPlaying.bind(this)
  }

  componentDidMount() {
    if (!!(this.videoElementRef) && !!(this.videoElementRef.current)) {

      if (this.props.registerManualStartEvent) {
        this.props.registerManualStartEvent(() => {
          this.playVideo()
        })
      }

      if (!!(this.props.onDownloadProgress)) {
        this.videoElementRef.current.addEventListener("progress", () => {
          if (this.videoElementRef.current.buffered.length > 0) {
            var loadedPercentage = this.videoElementRef.current.buffered.end(0) / this.videoElementRef.current.duration
            this.props.onDownloadProgress(loadedPercentage)
          }
        })

        this.videoElementRef.current.addEventListener("canplaythrough", () => {
          this.props.onDownloadProgress(1)
        })
      }

      this.videoElementRef.current.addEventListener("play", () => {
        this.setState({isVideoPlaying: true, hasVideoStarted: true})
      })

      this.videoElementRef.current.addEventListener("pause", () => {
        this.setState({isVideoPlaying: false, hasVideoBeenPaused: true})
      })
    }
  }

  playVideo() {
    if (!!(this.videoElementRef) && !!(this.videoElementRef.current)) {
      this.videoElementRef.current.play()
    }
  }

  pauseVideo() {
    if (!!(this.videoElementRef) && !!(this.videoElementRef.current)) {
      this.videoElementRef.current.pause()
    }
  }

  getVideoIsPlaying() {
    if (!!(this.videoElementRef) && !!(this.videoElementRef.current)) {
      const video = this.videoElementRef.current
      return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
    }
    return false
  }

  render() {
    const {
      videoPath,
      videoPosterPath,
      widthPixels,
      aspectRatio,
      isVideoVisible,
      baseContentUrl,
      playbackRate,
      disable,
      onVideoLoad,
      onVideoPlay,
      canManuallyStartVideo,
      hideLoadingAnimation,
      hideLoadedAnimation,
      registerManualStartEvent,
      onDownloadProgress
    } = this.props

    const {
      isVideoLoaded,
      isVideoPlaying,
      posterIsLoaded,
      hasBeenTapped,
      hasVideoStarted,
      hasVideoBeenPaused
    } = this.state

    const isVideoOnScreen = isVideoLoaded && isVideoVisible

    const showVideo = (hasBeenTapped && isVideoOnScreen && isVideoLoaded && (isVideoPlaying || hasVideoStarted))

    return (
      <div 
        onClick={() => {
          this.setState({hasBeenTapped: true})
          const videoIsPlaying = this.getVideoIsPlaying()
          if (!videoIsPlaying) {
            this.playVideo()
          } else {
            this.pauseVideo()
          }
        }} 
        style={{position: "relative", width: widthPixels, height: widthPixels * (aspectRatio || DefaultAspectRatio)}}
      >
        {(!!(videoPath)) && (
          <div style={{position: "relative", height: "100%", width: "100%"}}>
            <img loading="lazy" style={{position: "absolute", display: showVideo ? "none" : "block", height: "100%", width: "100%", zIndex: 1, transform: "translateZ(0px)", opacity: posterIsLoaded ? 1.0 : 0.0, transition: "opacity 150ms linear"}} onLoad={() => this.setState({posterIsLoaded: true})} src={(baseContentUrl || DefaultCdnBaseUrl) + videoPosterPath} />
            <video
              width={"100%"}
              height={"100%"}
              alt="workout"
              title="workout"
              style={{pointerEvents: "none", position: "absolute", width: "100%", height: "100%", transform: "translateZ(50px)", zIndex: 2}}
              loop
              playsInline
              preload="none"
              poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" /* Transparent Image -- removes an ugly poster image on Android Native. */
              ref={this.videoElementRef}
              onPlay={() => {
                if (!!onVideoPlay) {
                  onVideoPlay()
                }
              }}
              onCanPlay={() => {
                this.setState({isVideoLoaded: true})
                if (!!onVideoLoad) {
                  onVideoLoad()
                }
              }}
            >
              <source src={(baseContentUrl || DefaultCdnBaseUrl) + videoPath} type="video/mp4">
              </source>
            </video>
            <div style={{position: "absolute", height: 60, width: 60, zIndex: 3, transform: "translateZ(100px)", opacity: hasBeenTapped && !isVideoLoaded ? 1.0 : 0.0, transition: "opacity 150ms linear", top: "calc(50% - 30px)", right: "calc(50% - 30px)"}}>
              <OvalSpinnerSvg
                size={60}
                color={"rgba(0, 0, 0, 0.8)"}
              />
            </div>
            <div style={{position: "absolute", height: 60, width: 60, zIndex: 3, transform: "translateZ(100px)", opacity: !isVideoPlaying ? 1.0 : 0.0, transition: "opacity 150ms linear", top: "calc(50% - 30px)", right: "calc(50% - 30px)"}}>
              <PlayButtonSvg
                size={60}
                color={"rgba(0, 0, 0, 0.8)"}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default InlineVideoContainer;