import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './common/LoadingLogo'

import { history } from '../history'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionSupportBaseUrl,
} from '../utils'

import Helmet from './common/Helmet'

const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class SupportPage extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        learnMoreRef: React.createRef(),
        headerColor: "transparent",
        dropShadowColor: "transparent",
        show: true,
        showIframe: false,
     }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateHeaderColorOnContainerScroll = e => {
    if (e.target.scrollTop > 0) {
      const alpha = Math.min(0.5, e.target.scrollTop / 500)
      const headerColor = `rgba(255, 255, 255, ${alpha/1.5})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/3.0})`
      this.setState({headerColor, dropShadowColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", dropShadowColor: "transparent"})
    }

    if (e.target.scrollTop > 400) {
      this.setState({showIframe: true})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateHeaderColorOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      i18n,
    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor, showIframe} = this.state

    return (
      <div style={{height: innerHeight}}>

        <Helmet
          title={"NeuroFit Support - Nervous System Dysregulation App"}
          description={"Contact NeuroFit Support for help with the app that balances nervous system dysregulation, available for iOS and Android."}
          href={ProductionSupportBaseUrl}
        />
        <CSSTransition
          in={show}
          timeout={400}
          classNames={"fade-in-400"}
          onExited={() => {}}
        >
          <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
            <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
            <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 5 + safeAreaInsets.top, paddingLeft: "min(40px, 7.5%)", paddingRight: 5, paddingBottom: 5, backgroundColor: "white", zIndex: 1000}}>
              <LoadingLogo
                show={true}
                hideAnimation={true}
                responsiveSize={"min(7vw, 35px)"}
                marginTop={0}
                color={"black"}
                fontFamily={"Futura Book"}
                text={"NeuroFit"}
                showProgressionBar={false}
                onClick={() => history.push("/")}
              />
              {!isNative && (
                <div style={{display: "flex", alignItems: "center"}}>
                  <a
                    href={"/"}
                    target="_blank"
                    style={{fontFamily: "Futura Medium", textDecoration: "none", fontSize: "min(4vw, 15px)", textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
                  >
                    {"Home"}
                  </a>
                  <span style={{display: "inline-block", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                    {"•"}
                  </span>
                  <span
                    onClick={() => window.location = "/learn"}
                    target="_blank"
                    style={{fontFamily: "Futura Medium", fontSize: "min(4vw, 15px)", textTransform: "uppercase", color: "#000000", cursor: "pointer", marginRight: "min(3vw, 15px)"}}
                  >
                    {"Blog"}
                  </span>
                </div>
              )}
            </div>
            <div 
              id={"landing-page-container"}
              className={"disable-scrollbars"}
              ref={elem => this.scrollingContainerRef = elem}
              style={containerStyle}
            >
              <div style={{textAlign: "left", width: "100vw"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginTop: "20vh"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <h2 style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "left", fontFamily: "Futura Light"}}>
                      <div style={{display: "inline-block"}}>
                        {"For inquiries, questions, and other support, kindly contact our team at:"}
                      </div>
                    </h2>
                    <h3 style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "left", fontFamily: "Futura Book"}}>
                      <div style={{display: "inline-block", fontFamily: "Futura Book"}}>
                        {"support (at) neurofit (dot) app"}
                      </div>
                    </h3>
                    <h3 style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "left", fontFamily: "Futura Light"}}>
                      <div style={{display: "inline-block"}}>
                        {"Thank you for using NeuroFit. May your future be bright and your nervous system be balanced."}
                      </div>
                    </h3>
                    <div style={{marginTop: "max(6vw, 32px)", marginBottom: `calc(max(6vw, 32px) + ${safeAreaInsets.bottom}px)`, display: "flex", justifyContent: "center"}}>
                      <LoadingLogo
                        show={true}
                        hideAnimation={true}
                        responsiveSize={"min(6vw, 26px)"}
                        marginTop={0}
                        color={"#CFD2DD"}
                        lineColor={"#EDEEF2"}
                        fontFamily={"Futura Book"}
                        text={"NeuroFit"}
                        showProgressionBar={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{position: "fixed", bottom: 0, left: 0, right: 0, textAlign: "left", width: "100vw"}}>
                <div style={{marginTop: 15, padding: 15, fontSize: "min(3.5vw, 16px)", fontFamily: "Futura Book", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                  <div style={{display: "inline-block"}}>
                    <span style={{color: "#c1c4d2"}}>
                      {"© NEUROFIT 2022"}
                    </span>
                    <span style={{display: "inline-block", margin: "0px 0.5em", color: "#c1c4d2", fontSize: "min(2vw, 10px)"}}>
                      {"•"}
                    </span>
                    <span style={{color: "#c1c4d2"}}>
                      {"ALL RIGHTS RESERVED"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default SupportPage;