export const nervousSystemStates = [
  {
    min: 0,
    max_exclusive: 60,
    name: "stillness",
    display_name: "Stillness",
    title_name: "stillness",
    color: "#64C16C",
    color_pastel: "#CFF2D2"
  },
  {
    min: 60,
    max_exclusive: 120,
    name: "parasympathetic",
    display_name: "Ventral Vagal",
    title_name: "ventral",
    color: "#DFFF00",
    color_pastel: "#F4FFAA"
  },
  {
    min: 120,
    max_exclusive: 180,
    name: "play",
    title_name: "play",
    display_name: "Play",
    color: "#EB9627",
    color_pastel: "#FAE4C7"
  },
  {
    min: 180,
    max_exclusive: 240,
    name: "sympathetic",
    title_name: "sympathetic",
    display_name: "Sympathetic",
    color: "#FF5AAC",
    color_pastel: "#FFC8E3"
  },
  {
    min: 240,
    max_exclusive: 300,
    name: "overwhelm",
    title_name: "overwhelm",
    display_name: "Overwhelm",
    color: "#8084CE",
    color_pastel: "#D5D6EF"
  },
  {
    min: 300,
    max_exclusive: 360,
    name: "dorsal_vagal",
    title_name: "dorsal",
    display_name: "Dorsal Vagal",
    color: "#00AEEF",
    color_pastel: "#AAE4FA"
  }
]

const regulatedStateCTAs = {
  "parasympathetic": {
    "play": "movement to shift energy",
    "parasympathetic": "anchoring into safety",
    "stillness": "slowing down body & mind",
  },
  "play": {
    "play": "releasing pent-up energy",
    "parasympathetic": "coming into balance",
    "stillness": "calming the body",
  },
  "stillness": {
    "play": "movement to shift energy",
    "parasympathetic": "coming into balance",
    "stillness": "anchoring into calm",
  },
  "sympathetic": {
    "play": "releasing pent-up energy",
    "parasympathetic": "coming into balance",
    "stillness": "calming the body",
  },
  "dorsal_vagal": {
    "play": "movement to increase energy",
    "parasympathetic": "coming into balance",
    "stillness": "slowing down into calm",
  },
  "overwhelm": {
    "play": "releasing & shifting energy",
    "parasympathetic": "balancing body & mind",
    "stillness": "slowing down body & mind",
  },
}

const dysregulatedStateSuggestions = {
  "dorsal_vagal": {
    "text": "Suggested For You",
    "state": "balance",
    "color": "#DFFF00",
    "recommended_state_name": "parasympathetic"
  },
  "sympathetic": {
    "text": "Suggested For You",
    "state": "stillness",
    "color": "#64C16C",
    "recommended_state_name": "stillness"
  },
  "overwhelm": {
    "text": "Suggested For You",
    "state": "activity",
    "color": "#EB9627",
    "recommended_state_name": "play"
  },
}

const liveRegulationSuggestions = {
  "play": {
    "text": "Suggested For You",
    "state": "activity",
    "color": "#EB9627",
    "hideInfoButton": true,
    "recommended_state_name": "play"
  },
  "stillness": {
    "text": "Suggested For You",
    "state": "stillness",
    "color": "#64C16C",
    "hideInfoButton": true,
    "recommended_state_name": "stillness"
  },
  "parasympathetic": {
    "text": "Suggested For You",
    "state": "balance",
    "color": "#DFFF00",
    "hideInfoButton": true,
    "recommended_state_name": "parasympathetic"
  },
}

const dysregulatedStateExplanations = {
  "dorsal_vagal": "The Dorsal Vagal state, also known as Shutdown or Freeze, is best regulated by increasing bodily awareness. In Dorsal Vagal, the Enteric Nervous System sends shutdown signals to the body which often feel like dissociation, so bringing awareness to the body serves to counteract it.",
  "sympathetic": "The Sympathetic state, also known as Fight or Flight, is best regulated by calming the body through stillness -- and if needed, discharging any excess energy before doing so through activity.",
  "overwhelm": "The Overwhelm state is best regulated by expressing, activating, and discharging excess energy through active movement (play). In this state, both the Dorsal Vagal and Sympathetic responses are active. Play offsets the shutdown response of Dorsal Vagal, and also discharges excess energy produced by the fight or flight response of Sympathetic."
}

const ProgramWeekTitleMap = {
  1: "Foundation",
  2: "Clearing",
  3: "Precision",
  4: "Momentum",
  5: "Balance",
  6: "Renewal"
}

export const getTitleForProgramWeek = programWeek => {
  return ProgramWeekTitleMap[programWeek] || ""
}

export const isDysregulatedState = stateName => {
  return (stateName === "dorsal_vagal") || (stateName === "sympathetic") || (stateName === "overwhelm")
}

export const isRecommendedForState = (stateName, suggestedStateName) => {
  if (stateName === "dorsal_vagal") {
    return suggestedStateName === "play"
  } else if (stateName === "overwhelm") {
    return suggestedStateName === "play"
  } else if (stateName === "sympathetic") {
    return (suggestedStateName === "stillness") || (suggestedStateName === "play")
  }
}

export const getCurrentStateMeta = stateName => {
  for (let i = 0; i < nervousSystemStates.length; i++) {
    if (nervousSystemStates[i].name === stateName) {
      return nervousSystemStates[i]
    }
  }
}

export const getDisplayNameForState = stateName => {
  const stateMeta = getCurrentStateMeta(stateName)
  return (!!(stateMeta)) ? stateMeta.display_name : stateName
}

export const getRegulationExplanationForDysregulatedState = stateName => {
  return dysregulatedStateExplanations[stateName]
}

export const getNameForState = deg => {
  for (let i = 0; i < nervousSystemStates.length; i++) {
    if (deg >= nervousSystemStates[i].min && deg < nervousSystemStates[i].max_exclusive) {
      return nervousSystemStates[i].name
    }
  }
}

export const getRegulatedStateCta = stateName => {
  return regulatedStateCTAs[stateName]
}

export const getRecommendationForDysregulatedState = (stateName, liveStateRecommendations) => {
  if (!!(liveStateRecommendations) && liveStateRecommendations.length > 0) {
    return liveRegulationSuggestions[Object.keys(liveStateRecommendations[0])[0]]
  }
  return dysregulatedStateSuggestions[stateName]
}