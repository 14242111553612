import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './common/LoadingLogo'

import Helmet from './common/Helmet'

import { history } from '../history'

import {
  baseDomain,
  recordLinkClickGA,
  ProductionBlogBaseUrl
} from '../utils'

import StaticArticleRecommendation from './common/StaticArticleRecommendation'

const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const staticArticleRecommendations = [
  {"title": "10 Common Signs of Nervous System Dysregulation", "story_key": "common_signs_dysregulation", "read_duration_minutes": 3, "thumbnail_image_url": "https://cdn.neurofit.app/learn/images/common_signs_dysregulation/thumbnail.jpg", "created_at_unix": 1660358400, "last_updated_at_unix": 1660564632},
  {"title": "Chronic Stress, Dysregulation, & Burnout", "story_key": "connection_stress_dysregulation_burnout", "read_duration_minutes": 2, "thumbnail_image_url": "https://cdn.neurofit.app/learn/images/connection_stress_dysregulation_burnout/thumbnail.jpg", "created_at_unix": 1660365600, "last_updated_at_unix": 1660564632},
  {"title": "How to Re-Balance a Dysregulated Nervous System", "story_key": "rebalance_dysregulated_nervous_system", "read_duration_minutes": 2, "thumbnail_image_url": "https://cdn.neurofit.app/learn/images/rebalance_dysregulated_nervous_system/thumbnail.jpg", "created_at_unix": 1660372800, "last_updated_at_unix": 1660564632},
  {"title": "Allostatic Load & the Nervous System", "story_key": "allostatic_load", "read_duration_minutes": 2, "thumbnail_image_url": "https://cdn.neurofit.app/learn/images/allostatic_load/thumbnail.jpg", "created_at_unix": 1659062232, "last_updated_at_unix": 1659989593},
  {"title": "Ventral Vagal, Sympathetic, & Dorsal Vagal States", "story_key": "major_nervous_system_states", "read_duration_minutes": 2, "thumbnail_image_url": "https://cdn.neurofit.app/learn/images/major_nervous_system_states/thumbnail.jpg", "created_at_unix": 1660268400, "last_updated_at_unix": 1660564632},
  {"title": "Measuring Nervous System Balance with HRV",  "story_key": "measuring_balance_hrv", "read_duration_minutes": 1, "thumbnail_image_url": "https://cdn.neurofit.app/learn/images/measuring_balance_hrv/thumbnail.jpg", "created_at_unix": 1660174800, "last_updated_at_unix": 1660564632}
]



class BlogHomepage extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        learnMoreRef: React.createRef(),
        headerColor: "transparent",
        dropShadowColor: "transparent",
        show: true,
        showIframe: false,
     }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateHeaderColorOnContainerScroll = e => {
    if (e.target.scrollTop > 0) {
      const alpha = Math.min(0.5, e.target.scrollTop / 500)
      const headerColor = `rgba(255, 255, 255, ${alpha/1.5})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/3.0})`
      this.setState({headerColor, dropShadowColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", dropShadowColor: "transparent"})
    }

    if (e.target.scrollTop > 400) {
      this.setState({showIframe: true})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateHeaderColorOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      i18n,
    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor, showIframe} = this.state

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={"NeuroFit Blog | How to Balance A Dysregulated Nervous System"}
          description={"Learn how the nervous system applies to practical daily life, with recent articles from NeuroFit co-founders Loren & Andrew Hogue."}
          href={ProductionBlogBaseUrl}
        />
        <CSSTransition
          in={show}
          timeout={400}
          classNames={"fade-in-400"}
          onExited={() => {}}
        >
          <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
            <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
            <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 5 + safeAreaInsets.top, paddingLeft: "min(40px, 7.5%)", paddingRight: "min(20px, 4%)", paddingBottom: 5, backgroundColor: "white", zIndex: 1000}}>
              <LoadingLogo
                show={true}
                hideAnimation={true}
                responsiveSize={"min(7vw, 35px)"}
                marginTop={0}
                color={"black"}
                fontFamily={"Futura Book"}
                text={"NeuroFit"}
                showProgressionBar={false}
                onClick={() => history.push("/")}
              />
              {!isNative && (
                <div style={{display: "flex", alignItems: "center"}}>
                  <a
                    href={"/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{fontFamily: "Futura Medium", textDecoration: "none", fontSize: "min(4vw, 15px)", textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
                  >
                    {"Home"}
                  </a>
                </div>
              )}
            </div>
            <div 
              id={"landing-page-container"}
              className={"disable-scrollbars"}
              ref={elem => this.scrollingContainerRef = elem}
              style={containerStyle}
            >
              <div style={{textAlign: "left", width: "100vw"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginTop: "20vh"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <h2 style={{marginTop: 20, fontSize: "min(7vw, 32px)", textAlign: "left", fontFamily: "Futura Book", textTransform: "uppercase"}}>
                      <div style={{display: "inline-block"}}>
                        {"NeuroFit Blog: Balancing A Dysregulated Nervous System"}
                      </div>
                    </h2>
                    <h2 style={{marginTop: 20, marginBottom: "8vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                      <div>
                        {"Learn more about the nervous system with these quick articles from our co-founders, Loren & Andrew Hogue."}
                      </div>
                    </h2>
                    <div style={{marginBottom: "8vh"}}>
                      {staticArticleRecommendations.map(rec => (
                        <StaticArticleRecommendation
                          articleData={rec}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{position: "fixed", bottom: 0, left: 0, right: 0, textAlign: "left", width: "100vw"}}>
                <div style={{marginTop: 15, padding: 15, fontSize: "min(3.5vw, 16px)", fontFamily: "Futura Book", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                  <div style={{display: "inline-block"}}>
                    <span style={{color: "#c1c4d2"}}>
                      {"© NEUROFIT 2022"}
                    </span>
                    <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                      {"•"}
                    </span>
                    <span style={{color: "#c1c4d2"}}>
                      {"ALL RIGHTS RESERVED"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default BlogHomepage;