import React from 'react';

import { AppUpdate } from '@capawesome/capacitor-app-update';

import LoadingLogo from './common/LoadingLogo'
import MembershipFeatureDescription from './common/MembershipFeatureDescription'

const safeAreaInsets = require("safe-area-insets")

class ForceUpgradeAppPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div style={{position: "absolute", top: 0, left: 0, bottom: 0, width: "100vw", zIndex: 1000000, backgroundColor: "#EDEEF2"}}>
        <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
        <div style={{position: "fixed", top: 0, left: 0, right: 0, borderBottom: `2px #000000 solid`, display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 5 + safeAreaInsets.top, paddingLeft: "min(40px, 7.5%)", paddingRight: "min(20px, 4%)", paddingBottom: 5, backgroundColor: "#FFFFFF", zIndex: 1000}}>
          <LoadingLogo
            show={true}
            hideAnimation={true}
            responsiveSize={"min(7vw, 35px)"}
            marginTop={0}
            color={"#000000"}
            lineColor={"#FFFFFF"}
            fontFamily={"Futura Book"}
            text={"NeuroFit"}
            showProgressionBar={false}
          />
        </div>
        <div style={{position: "absolute", top: 0, left: 0, width: "100vw", bottom: 0, justifyContent: "center", display: "flex", alignItems: "center"}}>
          <div className="lp-subheader" style={{maxWidth: 500, width: "85%"}}>
            <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
              <div style={{backgroundColor: "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)", width: "100%", maxWidth: 500}}>
                <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura Book"}}>
                  {`APP UPDATE REQUIRED`}
                </div>
                <div style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "left", fontFamily: "Futura Light"}}>
                  <div style={{display: "inline-block"}}>
                    <span>{"To access the latest app features and performance improvements, please update NeuroFit to the latest version."}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style={{display: "flex", width: "100%", justifyContent: "left", alignItems: "center", margin: "2vh 0px 4vh 0vh"}}>
              <button
                style={{display: "block", width: "100%", fontFamily: "Futura Book", fontSize: "min(6vw, 26px)", lineHeight: "min(6vw, 26px)", color: "#ffffff", backgroundColor: "#000000", padding: "12px 15px", border: "none"}}
                onClick={async () => {
                  try {
                    await AppUpdate.openAppStore()
                  } catch (e) {
                    console.log(e)
                  }
                }}
              >
                {"UPDATE NEUROFIT APP"}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForceUpgradeAppPage;