import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './common/LoadingLogo'

import BookDetails from './common/BookDetails'

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
} from '../constants/svgs'

import {
  GA_LABEL_CLICKED_APP_DOWNLOAD_LINK
} from '../constants/gaEventLabels'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionBookUrl,
  ProductionCourseUrl,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  CertificationCheckoutLink,
  recordPixelEventIfAvailable,
  recordLinkClickAndRedirect,
} from '../utils'

import Helmet from './common/Helmet'

class BookPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      logoOutlineColor: "#000000",
      logoLineColor: "#EDEEF2"
    }
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      i18n,
      isIOSDevice,
      isAndroidDevice,
      isNasmPage
    } = this.props

    const {headerBorderColor, headerBackgroundColor, headerBackgroundBlur, logoOutlineColor, logoLineColor, textShadow} = this.state
    
    const appDownloadUrl = isIOSDevice ? NeuroFitAppleAppStoreLink : (isAndroidDevice ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={"Nervous System Regulation Book + Interactive Guide | NeuroFit"}
          description={"Our interactive NeuroFit Nervous System Book helps you master the basics of nervous system regulation, reduce stress, and feel + perform your best."}
          href={ProductionBookUrl}
        />
        <CSSTransition
          in
          timeout={400}
          classNames={"fade-in-400"}
          onExited={() => {}}
        >
          <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#FFFFFF"}}>
            <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
            <div style={{position: "fixed", top: 0, left: 0, right: 0, borderBottom: `2px ${headerBorderColor} solid`, display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 5 + safeAreaInsets.top, paddingLeft: "min(40px, 7.5%)", paddingRight: "min(20px, 4%)", paddingBottom: 5, backgroundColor: headerBackgroundColor, zIndex: 1000, backdropFilter: headerBackgroundBlur, WebkitBackdropFilter: headerBackgroundBlur}}>
              <LoadingLogo
                show={true}
                hideAnimation={true}
                responsiveSize={"min(7vw, 35px)"}
                marginTop={"0px"}
                color={"#000000"}
                lineColor={logoLineColor}
                fontFamily={"Futura Book"}
                text={"NeuroFit"}
                showProgressionBar={false}
                onClick={() => history.push("/")}
              />
              <div style={{display: "flex", alignItems: "center"}}>
                <span
                  onClick={() => {
                    if (isNasmPage) {
                      recordPixelEventIfAvailable("Lead")
                      recordLinkClickAndRedirect(GA_LABEL_CLICKED_APP_DOWNLOAD_LINK, appDownloadUrl)
                    } else {
                      window.location = "/"
                    }
                  }}
                  style={{cursor: "pointer", fontFamily: "Futura Medium", color: "#000000", fontSize: "min(4vw, 15px)", textTransform: "uppercase", cursor: "pointer"}}
                >
                  {"Home"}
                </span>
              </div>
            </div>
            <BookDetails
              innerHeight={innerHeight}
              safeAreaInsets={safeAreaInsets}
              isNative={isNative}
              onContainerScroll={updatedState => this.setState({...updatedState})}
            />
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default BookPage;