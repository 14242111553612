import { call, put, all, select, takeLatest, takeLeading, take, debounce, cancel } from 'redux-saga/effects'
import agent from './agent'

import {
  recordFunnelEventGA,
  delay,
} from './utils';

import {
  COMMON_TRIGGER_REQUEST,
  QUIZ_PAGE_TRIGGER_REQUEST,
  CERTIFICATION_VIDEO_PAGE_TRIGGER_REQUEST,
  TRAINING_REGISTRATION_PAGE_TRIGGER_REQUEST,
} from './constants/actionTypes';

import {
  REQ__ACTION_OPEN_APP_STORE_URL,
  REQ_SAVE_QUIZ_RESULTS,
  REQ_FETCH_QUIZ_RESULTS,
  REQ_SUBMIT_CREATOR_APPLICATION,
  REQ_SUBMIT_CERTIFICATION_VIDEO_PAGE_DETAILS,
  REQ_SUBMIT_TRAINING_REGISTRATION_DETAILS,
} from './constants/requestTypes'

import {
  commonTriggerRequest,
  commonUpdateRequestState,
} from './actions/common'

import {
  quizPageUpdateRequestState,
} from './actions/quizPage'

import {
  certificationVideoPageUpdateRequestState,
} from './actions/certificationVideoPage'

import {
  trainingRegistrationPageUpdateRequestState,
} from './actions/trainingRegistrationPage'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from './constants/requestStates'

import {
  COMMON_QUIZ_RESULT_KEY,
} from './constants/commonState'

import {
  GA_LABEL_QUIZ_COMPLETED_SAVE_RESULTS,
  GA_LABEL_REGISTER_LIVE_TRAINING_OPT_IN_COMPLETED,
} from './constants/gaEventLabels'

const QUITE_SHORT_DELAY_MS = 1000

const getQuizResultKeyFromCommonState = state => state.common[COMMON_QUIZ_RESULT_KEY]

// Quiz
const getQuizPageRequestResultFromQuizPageState = state => state.quizPage.requestResults[REQ_SAVE_QUIZ_RESULTS]

// Certification Training
const getCertificationVideoRegistrationRequestResultFromCertificationVideoPageState = state => state.certificationVideoPage.requestResults[REQ_SUBMIT_CERTIFICATION_VIDEO_PAGE_DETAILS]
// Live Training Registration
const getTrainingRegistrationRequestResultFromTrainingRegistrationPageState = state => state.trainingRegistrationPage.requestResults[REQ_SUBMIT_TRAINING_REGISTRATION_DETAILS]

//////////
// Util //
//////////
function* baseRequestWorker(action, reqFunc, reqParams, gaSuccessEventLabel, updateRequestState, gaTriggeredEventLabel) {
  const reqType = action.payload.key;
  try {
    if (gaTriggeredEventLabel) {
      recordFunnelEventGA(gaTriggeredEventLabel)
    }
    yield put(updateRequestState(reqType, REQUEST_FETCHING))
    const result = yield call(reqFunc, reqParams)
    yield put(updateRequestState(reqType, REQUEST_SUCCESS, result))
    if (gaSuccessEventLabel) {
      recordFunnelEventGA(gaSuccessEventLabel)
    }
  } catch (err) {
    yield put(updateRequestState(reqType, REQUEST_ERROR, err))
  }
}

function* createQuizResultsWorker(action) {
  try {
    const quizRequestResult = yield select(getQuizPageRequestResultFromQuizPageState)
    const requestBody = quizRequestResult.result

    yield baseRequestWorker(
      action,
      agent.NeuroFitBackend.createInitialDiscoveryQuizResult,
      requestBody,
      GA_LABEL_QUIZ_COMPLETED_SAVE_RESULTS,
      quizPageUpdateRequestState,
    )

  } catch (err) {
    console.error(err)
  }
}
function* createQuizResultsSaga() {
  yield takeLatest(QUIZ_PAGE_TRIGGER_REQUEST + REQ_SAVE_QUIZ_RESULTS, createQuizResultsWorker)
}


function* fetchQuizResultsWorker(action) {
  try {
    const filename = yield select(getQuizResultKeyFromCommonState)

    yield baseRequestWorker(
      action,
      agent.NeuroFitBackend.fetchQuizResult,
      {filename},
      undefined,
      commonUpdateRequestState,
    )

  } catch (err) {
    console.error(err)
  }
}
function* fetchQuizResultsSaga() {
  yield takeLatest(COMMON_TRIGGER_REQUEST + REQ_FETCH_QUIZ_RESULTS, fetchQuizResultsWorker)
}

function* submitCertificationVideoPageRegistrationRequestWorker(action) {
  try {
    const submitWorkshopResult = yield select(getCertificationVideoRegistrationRequestResultFromCertificationVideoPageState)
    const requestBody = submitWorkshopResult.result

    yield baseRequestWorker(
      action,
      agent.NeuroFitBackend.submitCertificationVideoRegistration,
      requestBody,
      undefined,
      certificationVideoPageUpdateRequestState,
    )

    const requestResult = yield select(getCertificationVideoRegistrationRequestResultFromCertificationVideoPageState)
    if (requestResult.state === REQUEST_SUCCESS) {
      yield delay(QUITE_SHORT_DELAY_MS)
      window.location = "/vsl"
    }

  } catch (err) {
    console.error(err)
  }
}
function* submitCertificationVideoPageRegistrationRequestSaga() {
  yield takeLatest(CERTIFICATION_VIDEO_PAGE_TRIGGER_REQUEST + REQ_SUBMIT_CERTIFICATION_VIDEO_PAGE_DETAILS, submitCertificationVideoPageRegistrationRequestWorker)
}


function* submitTrainingRegistrationRequestWorker(action) {
  try {
    const submitWorkshopResult = yield select(getTrainingRegistrationRequestResultFromTrainingRegistrationPageState)
    const requestBody = submitWorkshopResult.result

    yield baseRequestWorker(
      action,
      agent.NeuroFitBackend.submitTrainingRegistrationDetails,
      requestBody,
      GA_LABEL_REGISTER_LIVE_TRAINING_OPT_IN_COMPLETED,
      trainingRegistrationPageUpdateRequestState,
    )

    const requestResult = yield select(getTrainingRegistrationRequestResultFromTrainingRegistrationPageState)
    if (requestResult.state === REQUEST_SUCCESS) {
      yield delay(QUITE_SHORT_DELAY_MS)
      window.location = "/certification"
    }

  } catch (err) {
    console.error(err)
  }
}
function* submitTrainingRegistrationRequestSaga() {
  yield takeLatest(TRAINING_REGISTRATION_PAGE_TRIGGER_REQUEST + REQ_SUBMIT_TRAINING_REGISTRATION_DETAILS, submitTrainingRegistrationRequestWorker)
}

export default function* rootSaga() {
  yield all([
    // Common
    createQuizResultsSaga(),
    fetchQuizResultsSaga(),

    // Certification Video Page
    submitCertificationVideoPageRegistrationRequestSaga(),
    // Training Registration Page
    submitTrainingRegistrationRequestSaga(),
  ])
}
